///*------------------------------------*\
//    # TOOLS - BREAKPOINTS
//\*------------------------------------*/

// Tablet MS
@mixin sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Tablet M
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Desktop
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Desktop XL
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// Desktop 2XL
@mixin twoXl {
  @media (min-width: #{$screen-2xl}) {
    @content;
  }
}
