/*------------------------------------*\
    # COMPONENTS - ACCORDION
\*------------------------------------*/

.accordion {
  margin-top: $space-4;
  
  @include md {
    margin-top: $space-5;
  }

  @include lg {
    margin-top: $space-6;
  }
}

.variant_dark {
  .accordion__item {
    color: var(--text-white);
    border-bottom: var(--main-separator-dark) solid $space-px;
  }
  .accordion__item-header {
    color: var(--text-white);
    @include font-weight("bold");

    &:hover {
      color: var(--text-white-hover);
    }
  }
}

.accordion__item {
  border-bottom: var(--separator-light) solid $space-px;

  .btn-container {
    margin-top: $space-5;

    @include md {
      margin-top: $space-6;
    }
  }
}

.accordion__item-header {
  @extend %button-navigation;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  @include py($space-5);
  color: var(--accent-text-color-dark);
  cursor: pointer;
  @include transition;

  @include md {
    @include py($space-6);
  }

  @include lg {
    @include py($space-8);
  }

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.accordion__item-title {
  @include font-weight("bold");
}

.accordion__item-body {
  @include text-base;
  @include font-weight("regular");
  font-family: var(--font-sans);
  color: inherit;
  padding-bottom: $space-5;

  @include md {
    padding-bottom: $space-6;
  }

  @include lg {
    padding-bottom: $space-8;
  }
}
