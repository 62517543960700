/*------------------------------------*\
    # COMPONENTS - PAGE BLOCK WRAPPER
\*------------------------------------*/

.page-block-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 0;
  @include py($space-12);
  @include sm {
    @include py($space-14);
  }
  @include xl {
    @include py($space-18);
  }
  .decoration-first {
    border: $space-px solid var(--main-500);

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
  }
  .decoration-second {
    border: $space-1 solid var(--main-400);

    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }
  }
}

.page-block-wrapper_white {
  @extend .variant_white;
  .decoration-first {
    display: none;
  }
  .decoration-second {
    display: none;
  }
}

.page-block-wrapper_light {
  @extend .variant_light;

  .decoration-first {
    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
  }

  .decoration-second {
    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }
  }
}

.page-block-wrapper_dark {
  @extend .variant_dark;

  .decoration-first {
    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }
  }

  .decoration-second {
    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
  }
}
