/*------------------------------------------*\
    # COMPONENTS - ACCORDION WITH IMAGES
\*------------------------------------------*/

.accordion-with-images {
  margin-top: $space-4;
  
  @include md {
    margin-top: $space-5;
  }

  @include lg {
    margin-top: $space-6;
  }
}

.accordion-with-images__image-wrapper {
  height: $space-12;

  @include sm {
    height: $space-16;
  }

  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-lg);
  overflow: hidden;
}

.accordion-with-images__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.variant_dark {
  .accordion-with-images__item-header {
    color: var(--text-white);

    &:hover {
      color: var(--text-white-hover);
    }
  }

  .accordion-with-images__item {
    color: var(--text-white);
    border-bottom: var(--main-separator-dark) solid 1px;
  }
  .accordion-with-images__item-subtitle {
    color: var(--main-text-color-light);
  }

  .accordion-with-images__item-media-object {
    &:hover {
      & + div > .accordion-with-images__item-header {
        color: var(--text-white-hover);
      }
    }
  }
}

.accordion-with-images__item {
  display: flex;
  flex-direction: row;
  column-gap: $space-4;
  border-bottom: var(--separator-light) solid $space-px;
  @include py($space-5);

  @include md {
    @include py($space-6);
  }

  @include lg {
    @include py($space-8);
  }
}
.accordion-with-images__item-media-object {
  cursor: pointer;

  &:hover {
    & + div > .accordion-with-images__item-header {
      color: var(--accent-text-color-dark-hover);
    }
  }
}

.accordion-with-images__item-media-object_open {
  .accordion-with-images__image-wrapper {
    @include sm {
      height: $space-26;
    }

    border-radius: var(--rounded-xl);
  }
}

.accordion-with-images__item-header {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $space-4;
  color: var(--accent-text-color-dark);
  cursor: pointer;
  @include transition;

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.accordion-with-images__item-title {
  @include text-xl;
  line-height: $space-6;

  @include sm {
    @include text-2xl;
    line-height: $space-6;
  }

  @include font-weight("bold");
  font-family: var(--font-sans-heading);
  flex: 1;
}

.accordion-with-images__item-subtitle {
  @include text-md;
  padding-top: $space-2;
  color: var(--text-light);
}

.accordion-with-images__item-body {
  @include text-base;
  color: inherit;
  padding-top: $space-3;
  margin-right: $space-10;
}

.accordion-with-images__item-icon {
  color: currentColor;
}
