/*-----------------------------------------------------*\
    # COMPONENTS - SCROLL APPEAR POP IN COMPOSITION
\*-----------------------------------------------------*/

.scroll-appear-pop-in-composition {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
