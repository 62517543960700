/*------------------------------------*\
    # COMPONENTS - MEDIA BLOCK WRAPPER
\*------------------------------------*/

.media-block-wrapper {
  overflow: hidden;
  position: relative;
  z-index: 0;

  @include py($space-10);

  @include sm {
    @include py($space-14);
  }

  @include xl {
    @include py($space-20);
  }
}

.media-block-wrapper_white {
  @extend .variant_white;
  .decoration-first {
    display: none;
  }
  .decoration-second {
    display: none;
  }
}

.media-block-wrapper_light {
  @extend .variant_light;

  .decoration-first {
    border: $space-px solid var(--main-800);
  }

  .decoration-second {
    border: $space-1 solid var(--main-200);
  }
}

.media-block-wrapper_dark {
  @extend .variant_dark;

  .decoration-first {
    border: $space-px solid var(--main-200);
  }

  .decoration-second {
    border: $space-1 solid var(--main-800);
  }
}
