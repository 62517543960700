/*------------------------------------*\
    # GENERICS - FONTS
\*------------------------------------*/

@font-face {
  font-family: BlenderPro;
  src: url("../../assets/webfonts/blenderpro-thin-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BlenderPro;
  src: url("../../assets/webfonts/blenderpro-book-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BlenderPro;
  src: url("../../assets/webfonts/blenderpro-medium-webfont.woff")
    format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: BlenderPro;
  src: url("../../assets/webfonts/blenderpro-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* CAMPTON - HEADINGS */
// Can you replace Blender Bold with the Campton Medium and Blender Medium with the Campton Book.
@font-face {
  font-family: "CamptonHeading";
  src: url("../../assets/webfonts/campton/campton-book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CamptonHeading";
  src: url("../../assets/webfonts/campton/campton-book.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CamptonHeading";
  src: url("../../assets/webfonts/campton/campton-medium.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* CAMPTON - BODY */
// And can replace Roboto SemiBold, Bold and Medium (headings 4 to 6) for Campton Medium?
// Bodytext will be Campton Book.
@font-face {
  font-family: "CamptonBody";
  src: url("../../assets/webfonts/campton/campton-book.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CamptonBody";
  src: url("../../assets/webfonts/campton/campton-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CamptonBody";
  src: url("../../assets/webfonts/campton/campton-medium.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "CamptonBody";
  src: url("../../assets/webfonts/campton/campton-medium.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* roboto-100 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 100;
  src: url("../../assets/webfonts/roboto-v30-latin-100.woff") format("woff");
  font-display: swap;
}

/* roboto-100italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 100;
  src: url("../../assets/webfonts/roboto-v30-latin-100italic.woff")
    format("woff");
  font-display: swap;
}

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../../assets/webfonts/roboto-v30-latin-300.woff") format("woff");
  font-display: swap;
}

/* roboto-300italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: url("../../assets/webfonts/roboto-v30-latin-300italic.woff")
    format("woff");
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/webfonts/roboto-v30-latin-regular.woff") format("woff");
  font-display: swap;
}

/* roboto-italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("../../assets/webfonts/roboto-v30-latin-italic.woff") format("woff");
  font-display: swap;
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/webfonts/roboto-v30-latin-500.woff") format("woff");
  font-display: swap;
}

/* roboto-500italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("../../assets/webfonts/roboto-v30-latin-500italic.woff")
    format("woff");
  font-display: swap;
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/webfonts/roboto-v30-latin-700.woff") format("woff");
  font-display: swap;
}

/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("../../assets/webfonts/roboto-v30-latin-700italic.woff")
    format("woff");
  font-display: swap;
}

/* roboto-900 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/webfonts/roboto-v30-latin-900.woff") format("woff");
  font-display: swap;
}

/* roboto-900italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: url("../../assets/webfonts/roboto-v30-latin-900italic.woff")
    format("woff");
  font-display: swap;
}
