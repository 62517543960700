/*--------------------------------------------*\
    # COMPONENTS - INSURANCE SELECTOR BLOCK
\*--------------------------------------------*/

.insurance-selector-block {
  .slide-group {
    position: static;
  }

  .slide__transition {
    position: relative;
    display: flex;
    flex-direction: row;
  }

  .decoration-first {
    width: 1311px;
    top: $space-3;
    @include calculateOffsetCenter(-616px, 1311px, 320px);

    @include sm {
      width: 1470px;
      top: -191px;
      @include calculateOffsetCenter(-726px, 1470px, $screen-sm);
    }

    @include md {
      top: -171px;
      @include calculateOffsetCenter(-598px, 1470px, $screen-md);
    }

    @include xl {
      width: 1470px;
      top: -81px;
      @include calculateOffsetCenter(-68px, 1470px, $screen-xl);
    }

    @include twoXl {
      width: 1603px;
      top: -147px;
      @include calculateOffsetCenter(44px, 1603px, $screen-2xl);
    }
  }

  .decoration-second {
    width: 2556px;
    top: -211px;
    @include calculateOffsetCenter(-1682px, 2556px, 320px);

    @include sm {
      width: 2865px;
      top: -1338px;
      @include calculateOffsetCenter(-1921px, 2865px, $screen-sm);
    }

    @include md {
      top: -1338px;
      @include calculateOffsetCenter(-1921px, 2865px, $screen-md);
    }

    @include xl {
      top: -1228px;
      @include calculateOffsetCenter(-269px, 2865px, $screen-xl);
    }

    @include twoXl {
      width: 3125px;
      top: -1398px;
      @include calculateOffsetCenter(-175px, 3125px, $screen-2xl);
    }
  }
}

.variant_dark {
  .insurance-selector-block__item,
  .insurance-selector-block__item-link {
    color: var(--text-white);
    border-bottom-color: var(--main-separator-dark);

    &:hover {
      color: var(--text-white-hover);
    }
  }

  .insurance-selector-block__item-subtitle {
    color: var(--main-text-color-light);
  }

  .insurance-selector-block__back-button {
    color: var(--text-white);

    &:hover {
      color: var(--text-white-hover);
    }
  }
}

.insurance-selector-block__container {
  @extend .container_tightest;

  @include sm {
    @include px($space-8);
  }

  @include md {
    @include px($space-11);
  }
}

.insurance-selector-block__slide {
  align-items: flex-start;
  justify-content: flex-start;
}

.insurance-selector-block__slide-title {
  color: var(--main-text-color-dark);
}

.insurance-selector-block__back-button {
  display: flex;
  align-items: center;
  margin-bottom: $space-3;
  color: var(--accent-text-color-dark);
  @include text-md;
  cursor: pointer;
  @include transition;

  &:hover {
    color: var(--accent-text-color-dark-hover);

    .insurance-selector-block__back-button-icon {
      transform: translateX(-$space-1);
    }
  }
}

.insurance-selector-block__back-button-icon {
  color: currentColor;
  @include transition;
}

.insurance-selector-block__item {
  display: block;
  @include py($space-5);
  width: 100%;
  color: var(--accent-text-color-dark);
  text-decoration: none;
  border-bottom: $space-px solid #e6e6e6;
  cursor: pointer;

  &:first-of-type {
    margin-top: $space-3;

    @include sm {
      margin-top: $space-5;
    }
  }

  &:hover {
    color: var(--accent-text-color-dark-hover);

    .insurance-selector-block__item-icon {
      transform: translateX($space-1);
    }
  }
}

.insurance-selector-block__item-link {
  color: currentColor;
  text-decoration: none;
}

.insurance-selector-block__item-media-object {
  gap: $space-6;
}

.insurance-selector-block__item-media-wrapper {
  aspect-ratio: 1/1;
  width: $space-16;
  border-radius: var(--rounded-md);
  overflow: hidden;
}

.insurance-selector-block__item-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.insurance-selector-block__item-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @include sm {
    padding-top: 6px;
  }

  @include xl {
    padding-top: $space-3;
  }
}

.insurance-selector-block__item-title {
  @extend %button-navigation;
  color: currentColor;
  font-weight: 700;
  @include transition;

  @include sm {
    @include text-2xl;
    line-height: $space-6;
  }
}

.insurance-selector-block__item-subtitle {
  color: var(--text-light);
  margin-top: $space-2;
  @include text-md;
  @include font-weight("regular");
}

.insurance-selector-block__item-icon {
  flex: none;
  align-self: center;
  color: currentColor;
  width: $space-6;
  height: $space-6;
  @include transition;
}
