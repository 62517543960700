/*------------------------------------*\
    # GENERICS - RESETS
\*------------------------------------*/

// FIXME this doesn't work
// @use "modern-normalize/modern-normalize.css";
@use "modern-normalize.css";

html,
body {
  min-width: $max-width-xs;
}

img {
  display: block;
}

blockquote,
p {
  margin: 0;
}
