/*------------------------------------*\
    # OBJECTS - DIMENSIONS
\*------------------------------------*/

.w-full {
  width: 100%;
}

.w-0 {
  width: 0;
}
.w-px {
  width: $space-px;
}
.w-0-5 {
  width: $space-0-5;
}
.w-1 {
  width: $space-1;
}
.w-2 {
  width: $space-2;
}
.w-3 {
  width: $space-3;
}
.w-4 {
  width: $space-4;
}
.w-5 {
  width: $space-5;
}
.w-6 {
  width: $space-6;
}
.w-7 {
  width: $space-7;
}
.w-8 {
  width: $space-8;
}
.w-9 {
  width: $space-9;
}
.w-10 {
  width: $space-10;
}
.w-12 {
  width: $space-12;
}
.w-14 {
  width: $space-14;
}
.w-16 {
  width: $space-16;
}
.w-18 {
  width: $space-18;
}
.w-20 {
  width: $space-20;
}
.w-22 {
  width: $space-22;
}
.w-24 {
  width: $space-24;
}
.w-26 {
  width: $space-26;
}
.w-28 {
  width: $space-28;
}
.w-30 {
  width: $space-30;
}
.w-32 {
  width: $space-32;
}
.w-36 {
  width: $space-36;
}

.sm\: {
  @include sm {
    &w-1 {
      width: $space-1;
    }
    &w-2 {
      width: $space-2;
    }
    &w-3 {
      width: $space-3;
    }
    &w-4 {
      width: $space-4;
    }
    &w-5 {
      width: $space-5;
    }
    &w-6 {
      width: $space-6;
    }
    &w-7 {
      width: $space-7;
    }
    &w-8 {
      width: $space-8;
    }
    &w-9 {
      width: $space-9;
    }
    &w-10 {
      width: $space-10;
    }
    &w-12 {
      width: $space-12;
    }
    &w-14 {
      width: $space-14;
    }
    &w-16 {
      width: $space-16;
    }
    &w-18 {
      width: $space-18;
    }
    &w-20 {
      width: $space-20;
    }
    &w-22 {
      width: $space-22;
    }
    &w-24 {
      width: $space-24;
    }
    &w-26 {
      width: $space-26;
    }
    &w-28 {
      width: $space-28;
    }
    &w-30 {
      width: $space-30;
    }
    &w-32 {
      width: $space-32;
    }
  }
}
.xl\: {
  @include xl {
    &w-1 {
      width: $space-1;
    }
    &w-2 {
      width: $space-2;
    }
    &w-3 {
      width: $space-3;
    }
    &w-4 {
      width: $space-4;
    }
    &w-5 {
      width: $space-5;
    }
    &w-6 {
      width: $space-6;
    }
    &w-7 {
      width: $space-7;
    }
    &w-8 {
      width: $space-8;
    }
    &w-9 {
      width: $space-9;
    }
    &w-10 {
      width: $space-10;
    }
    &w-12 {
      width: $space-12;
    }
    &w-14 {
      width: $space-14;
    }
    &w-16 {
      width: $space-16;
    }
    &w-18 {
      width: $space-18;
    }
    &w-20 {
      width: $space-20;
    }
    &w-22 {
      width: $space-22;
    }
    &w-24 {
      width: $space-24;
    }
    &w-26 {
      width: $space-26;
    }
    &w-28 {
      width: $space-28;
    }
    &w-30 {
      width: $space-30;
    }
    &w-32 {
      width: $space-32;
    }
  }
}

.h-full {
  height: 100%;
}

.h-0 {
  height: 0;
}
.h-px {
  height: $space-px;
}
.h-0-5 {
  height: $space-0-5;
}
.h-1 {
  height: $space-1;
}
.h-2 {
  height: $space-2;
}
.h-3 {
  height: $space-3;
}
.h-4 {
  height: $space-4;
}
.h-5 {
  height: $space-5;
}
.h-6 {
  height: $space-6;
}
.h-7 {
  height: $space-7;
}
.h-8 {
  height: $space-8;
}
.h-9 {
  height: $space-9;
}
.h-10 {
  height: $space-10;
}
.h-12 {
  height: $space-12;
}
.h-14 {
  height: $space-14;
}
.h-16 {
  height: $space-16;
}
.h-18 {
  height: $space-18;
}
.h-20 {
  height: $space-20;
}
.h-22 {
  height: $space-22;
}
.h-24 {
  height: $space-24;
}
.h-26 {
  height: $space-26;
}
.h-28 {
  height: $space-28;
}
.h-30 {
  height: $space-30;
}
.h-32 {
  height: $space-32;
}
.h-36 {
  height: $space-36;
}

.sm\: {
  @include sm {
    &h-1 {
      height: $space-1;
    }
    &h-2 {
      height: $space-2;
    }
    &h-3 {
      height: $space-3;
    }
    &h-4 {
      height: $space-4;
    }
    &h-5 {
      height: $space-5;
    }
    &h-6 {
      height: $space-6;
    }
    &h-7 {
      height: $space-7;
    }
    &h-8 {
      height: $space-8;
    }
    &h-9 {
      height: $space-9;
    }
    &h-10 {
      height: $space-10;
    }
    &h-12 {
      height: $space-12;
    }
    &h-14 {
      height: $space-14;
    }
    &h-16 {
      height: $space-16;
    }
    &h-18 {
      height: $space-18;
    }
    &h-20 {
      height: $space-20;
    }
    &h-22 {
      height: $space-22;
    }
    &h-24 {
      height: $space-24;
    }
    &h-26 {
      height: $space-26;
    }
    &h-28 {
      height: $space-28;
    }
    &h-30 {
      height: $space-30;
    }
    &h-32 {
      height: $space-32;
    }
  }
}
.xl\: {
  @include xl {
    &h-1 {
      height: $space-1;
    }
    &h-2 {
      height: $space-2;
    }
    &h-3 {
      height: $space-3;
    }
    &h-4 {
      height: $space-4;
    }
    &h-5 {
      height: $space-5;
    }
    &h-6 {
      height: $space-6;
    }
    &h-7 {
      height: $space-7;
    }
    &h-8 {
      height: $space-8;
    }
    &h-9 {
      height: $space-9;
    }
    &h-10 {
      height: $space-10;
    }
    &h-12 {
      height: $space-12;
    }
    &h-14 {
      height: $space-14;
    }
    &h-16 {
      height: $space-16;
    }
    &h-18 {
      height: $space-18;
    }
    &h-20 {
      height: $space-20;
    }
    &h-22 {
      height: $space-22;
    }
    &h-24 {
      height: $space-24;
    }
    &h-26 {
      height: $space-26;
    }
    &h-28 {
      height: $space-28;
    }
    &h-30 {
      height: $space-30;
    }
    &h-32 {
      height: $space-32;
    }
  }
}
