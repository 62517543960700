///*------------------------------------*\
//    # TOOLS - CALCULATE OFFSET CENTER
//\*------------------------------------*/

/**
    We recommend keeping the design open when working with this mixin

    This mixin provides the following:

      * Positions the element in it's parent center
      * Calculates the offset from the center with the next three values (all taken from design)
        - circleLeftPosition: The left position of the element
        - circleWidth: The width of the entire element
        - screenWidth: The minimal screen width that this decoration will opperate from 
                       (tip: use $screen-sm, -md, -lg, -xl and -2xl)

    The next things need to be added separately in the same class with this mixin:

      * Top or bottom value
      * Position
      * Z-index
**/

@mixin calculateOffsetCenter($circleLeftPosition, $circleWidth, $screenWidth) {
  left: 50%;
  $translateFromCenter: calc(
    -1 * #{$circleLeftPosition} - (#{$circleWidth} / 2) + (#{$screenWidth} / 2)
  );
  transform: translateX(calc(-50% - $translateFromCenter));
}
