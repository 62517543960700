/*--------------------------------------------*\
    # COMPONENTS - INTERACTIVE MAP BLOCK
\*--------------------------------------------*/

.interactive-map-block {
  position: relative;
}

.interactive-map-block__container {
  @extend .container_normal;
  display: flex;
  flex-direction: column;

  @include lg {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: $space-10;
  }
}

.variant_dark {
  .interactive-map-block__text-container,
  .heading-2,
  .introtext,
  .rich-text {
    color: var(--white) !important;
  }
}

.interactive-map-block__text-container {
  @include py($space-10);
  margin-bottom: $space-6;
  color: var(--white);
  background-color: var(--main-bg-dark);

  @include md {
    margin-bottom: $space-10;
  }

  @include lg {
    margin-bottom: 0;
    color: var(--text);
    background-color: transparent;
    @include px($space-7);
  }

  .container_tightest {
    @include lg {
      flex-shrink: 1;
      @include px(0);
    }
  }
}

.interactive-map-block__text {
  .heading-2,
  .introtext {
    @include lg {
      color: var(--main-text-color-dark);
    }
  }
}

.interactive-map-block__markers-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: $space-72;
  @include mx(auto);

  @include sm {
    @include px(0);
    max-width: $space-68;
  }

  @include md {
    max-width: ($max-width-md - $space-4);
    @include mx(auto);
  }

  @include lg {
    width: ($max-width-2xl - $space-2);
    max-width: none;
    flex-grow: 1;
    @include mx(0);
  }

  .interactive-map-block__marker {
    position: absolute;
    z-index: 20;
  }
}

.interactive-map-block__image-wrapper {
  height: inherit;
  width: inherit;
  overflow: hidden;
}

.interactive-map-block__image {
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.interactive-map-block__modal {
  position: relative;
  max-width: $max-width-6xl;
  overflow: visible;

  .heading-3 {
    color: var(--main-text-color-dark);
  }

  .btn {
    width: 100%;
  }
}

.interactive-map-block__modal-close-button {
  position: absolute;
  right: 0;
  top: -$space-12;
  color: var(--white);
  background-color: transparent;
  border: none;
  cursor: pointer;
}
