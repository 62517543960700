/*------------------------------------*\
    # BASE - TYPOGRAPHY
\*------------------------------------*/

body {
  @extend .bodytext;
}

b {
  @include font-weight("bold");
}
