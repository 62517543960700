///*------------------------------------*\
//    # TOOLS - MARGIN
//\*------------------------------------*/

// Assigns left and right margin
@mixin mx($margin) {
  margin-left: $margin;
  margin-right: $margin;
}
// Assigns left and right margin to auto
@mixin mx-auto() {
  margin-left: auto;
  margin-right: auto;
}
// Assigns top and bottom margin
@mixin my($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}
