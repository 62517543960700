/*------------------------------------*\
    # OBJECTS - DISPLAY
\*------------------------------------*/

.block {
  display: block;
}

.hidden {
  display: none;
}

.contents {
  display: contents;
}
