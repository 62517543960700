/*---------------------------------------------*\
    # COMPONENTS - MOVE CONTENT COMPOSITION
\*---------------------------------------------*/

.move-content-composition {
}

.move-content-composition__layer {
  display: inline-flex;
  pointer-events: auto;
}
