/*---------------------------------------------*\
    # COMPONENTS - HERO WITH QUICK LINKS BLOCK
\*---------------------------------------------*/
@use "sass:math";

.hero-with-quicklinks-block {
  overflow: hidden;
  position: relative;
  padding-bottom: $space-10;

  @include md {
    padding-bottom: $space-14;
  }
  @include lg {
    padding-bottom: $space-28;
    min-height: 796px;
  }

  .decoration-first {
    border: $space-0-5 solid var(--main-200);
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);
    top: calc(-22px - 55vw);
    margin-bottom: -22px;
    width: 110vw;
    left: -15vw;

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
    
    @include md {
      top: -522px;
      margin-bottom: -94px;
      width: 856px;
      @include calculateOffsetCenter(-127px, 856px, $screen-md);
    }
    @include lg {
      position: absolute;
      z-index: -2;
      top: -1384px;
      left: 0;
      width: 2110px;
      @include calculateOffsetCenter(-282px, 2110px, $screen-xl);
    }
  }

  .decoration-second {
    display: hidden;
  }

  &__subtitle {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: $space-5;
    }
  }
}

.hero-with-quicklinks-block_light {
  background: none;
  color: var(--main-text-color-dark);
}

.hero-with-quicklinks-block_dark {
  background: none;
  color: var(--text-white);
  .decoration-first {
    border: $space-0-5 solid var(--main-600);
  }

  .breadcrumbs__link,
  .breadcrumbs__back-button {
    color: var(--white);
  }
}

.hero-with-quicklinks-block_dark {
  .hero-with-quicklinks-block__text:before {
    background-color: var(--main-bg-dark);
  }
}

.hero-with-quicklinks-block_circles-none {
  .hero-with-quicklinks-block__text:before {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
    background-color: var(--main-bg-light);
    height: calc(100% - $space-56);
    width: 100vw;

    @include sm {
      height: calc(100% - $space-48);
    }

    @include lg {
      height: calc(100% - $space-56);
    }
  }

  &.hero-with-quicklinks-block_dark {
    &:before {
      background-color: var(--main-bg-dark);
    }
  }

  .hero-with-quicklinks-block__image-wrapper {
    border-radius: var(--rounded-none);
    mask: none;
    width: auto;
    position: static;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(0);
    margin: 0;

    @include lg {
      position: absolute;
      left: 50%;
      width: 50vw;
      height: calc(100% - $space-56);
    }
  }
}

.hero-with-quicklinks-block__text {
  position: relative;
  margin-bottom: $space-8;

  @include md {
    margin-bottom: $space-10;
  }

  @include lg {
    margin-bottom: $space-15;
    max-width: $space-98;
  }
}

.hero-with-quicklinks-block__text:before {
  content: "";
  position: absolute;
  z-index: -3;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  background-color: var(--main-bg-light);

  width: calc(
    100vw + 210px + 261px
  ); // Screen width + padding-left + padding-right
  aspect-ratio: 1/1;
  // Distance left circle to text => 207
  // Distance right circle to text => 235
  // Diff => 235 - 207 => 16px / 2 => 8px
  // From the middle it moved with 8px to the right
  // This is only specific to HeroWithQuickLinks, since background originates from other point then entire block
  left: calc(50% + ((235px - 207px) / 2));
  bottom: -201px;
  transform: translateX(-50%);

  @include sm {
    left: calc(50% - ((234px - 109px) / 2));
    bottom: -263px;
    width: calc(100vw + 212px + 103px);
  }

  @include md {
    left: calc(50% - ((244px - 139px) / 2));
    width: calc(100vw + 212px + 107px);
  }

  @include lg {
    bottom: -363px;
  }

  @include xl {
    left: calc(50% + ((246px - 5px) / 2));
    width: calc(100vw + 246px + 5px);
    bottom: -222px;
  }
}

.hero-with-quicklinks-block__image-wrapper {
  overflow: hidden;
  -webkit-mask-image: url("../../assets/image-masks/half-circle.svg");
  mask-image: url("../../assets/image-masks/half-circle.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  aspect-ratio: math.div(2, 1);

  top: -22px;
  margin-bottom: -22px;
  width: 110vw;
  left: -3vw;
  position: relative;

  @include md {
    top: -94px;
    margin-bottom: -94px;
    width: 855px;
    @include calculateOffsetCenter(-47px, 855px, $screen-md);
  }

  @include lg {
    position: absolute;
    z-index: -2;
    top: -217px;
    width: 973px;
    left: 50%;
    transform: translateX(5%);
    mask-image: none;
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);
  }
}

.hero-with-quicklinks-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-with-quicklinks-block__breadcrumbs {
  padding-top: $space-12;
}
