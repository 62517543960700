/*------------------------------------*\
    # COMPONENTS - TILES
\*------------------------------------*/

.tiles-block {
  .decoration-first {
    top: -154px;
    @include calculateOffsetCenter(-340px, 1603px, 320px);

    @include sm {
      top: -400px;
      @include calculateOffsetCenter(-54px, 1603px, $screen-sm);
    }

    @include md {
      top: -606px;
      @include calculateOffsetCenter(-40px, 1603px, $screen-md);
    }

    @include xl {
      top: -512px;
      @include calculateOffsetCenter(-50px, 1092px, $screen-xl);
    }
  }

  .decoration-second {
    @include sm {
      top: -1250px;
      @include calculateOffsetCenter(-208px, 3125px, $screen-sm);
    }

    @include md {
      top: -1450px;
      @include calculateOffsetCenter(-182px, 3125px, $screen-md);
    }

    @include xl {
      top: -1100px;
      @include calculateOffsetCenter(100px, 2126px, $screen-xl);
    }
  }

  &__subtitle {
    margin-top: $space-3;

    @include sm {
      margin-top: $space-4;
    }
    
    @include md {
      margin-top: $space-5;
    }
  }
}

.variant_white {
  .tiles-block {
    .decoration-first,
    .decoration-second {
      display: none !important;
    }
  }
}

.variant_white,
.variant_light {
  .tiles-block__text {
    .heading-2 {
      color: var(--main-text-color-dark);
    }
  }
}

.variant_dark {
  .tiles-block__text {
    .heading-2 {
      color: var(--text-white);
    }
  }
}

.tiles-block__grid {
  @include lg {
    display: flex;
    flex-direction: row;
    @include space-x($space-10);
  }
}

.tiles-block__text {
  @include px($space-2);
  width: 100%;

  @include lg {
    @include mx(auto);
    @include my(auto);
    @include px(0);
    max-width: $max-width-sm;
  }
}
