/*------------------------------------*\
    # COMPONENTS - CONTACT FORM BLOCK
\*------------------------------------*/

.contact-form-block__container {
  @extend .container_tightest;

  .heading-2 {
    color: var(--main-text-color-dark);
  }

  .btn {
    width: 100%;

    @include md {
      width: auto;
    }
  }
}
