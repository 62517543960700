/*------------------------------------*\
    # COMPONENTS - ACCORDION BLOCK
\*------------------------------------*/

.accordion-block {
  &__title {
    color: var(--main-text-color-dark);
  }

  &__subtitle {
    margin-top: $space-3;
    
    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: $space-5;
    }
  }
}
