/*------------------------------------*\
    # COMPONENTS - NON DRAGGABLE ELEMENT
\*------------------------------------*/

.non-draggable-element {
  cursor: pointer;

  &:active {
    cursor: grabbing;
  }
}
