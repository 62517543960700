/*------------------------------------*\
    # COMPONENTS - SHARE BUTTONS
\*------------------------------------*/

.share-buttons {
  display: flex;
  gap: $space-2;
}

.share-buttons__button {
  all: unset;
  cursor: pointer;
  display: flex;
  color: var(--accent-text-color-dark);
  @include transition;

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}
