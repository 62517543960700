/*----------------------------------------*\
    # COMPONENTS - CONTENT CARDS BLOCK
\*----------------------------------------*/

.contact-cards-block {
  .decoration-first {
    border: $space-0-5 solid var(--main-500);
  }

  .decoration-second {
    border: $space-1 solid var(--main-400);
  }

  &__container {
    @extend .container_tightest;
    @include px($space-4);
  
    @include sm {
      @include px($space-6);
    }
  
    @include lg {
      @include px($space-3);
    }
  }

  &__title,
  &__subtitle {
    @include px($space-4);

    @include md {
      @include px($space-5);
    }

    @include lg {
      @include px($space-8);
    }
  }

  &__subtitle {
    margin-top: $space-3;
    
    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: $space-3;
    }
  }
}

.contact-cards-block.variant_dark {
  color: var(--text);

  .contact-cards-block__title,
  .contact-cards-block__subtitle {
    color: var(--white);
  }
}

.contact-cards-block.variant_white,
.contact-cards-block.variant_light {
  .contact-cards-block__title {
    color: var(--main-text-color-dark);
  }
}

.contact-cards-block.variant_light,
.contact-cards-block.variant_dark {
  .contact-cards__item {
    background-color: var(--white);
  }
}
