/*--------------------------------------------------*\
    # COMPONENTS - TWO COLUMN ARTICLE OVERVIEW BLOCK
\*--------------------------------------------------*/

.two-column-article-overview-block {
  &__title {
    color: var(--main-text-color-dark);
    margin-bottom: $space-4;

    @include sm {
      margin-bottom: $space-5;
    }

    @include lg {
      margin-bottom: $space-6;
    }
  }

  .container_tightest {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: $space-6;
    column-gap: $space-6;

    @include md {
      grid-template-columns: 1fr 1fr;
    }
  }

  .decoration-first {
    width: 1311px;
    top: -$space-4;
    @include calculateOffsetCenter(-666px, 1311px, 320px);

    @include sm {
      top: -$space-52;
      width: 1603px;
      @include calculateOffsetCenter(-852px, 1603px, $screen-sm);
    }

    @include md {
      top: -$space-64;
      @include calculateOffsetCenter(-764px, 1603px, $screen-md);
    }

    @include xl {
      top: -$space-40;
      @include calculateOffsetCenter(-$space-12, 1603px, $screen-xl);
    }

    @include twoXl {
      top: -$space-28;
      @include calculateOffsetCenter($space-16, 1603px, $screen-2xl);
    }
  }

  .decoration-second {
    width: 2556px;
    top: -$space-60;
    @include calculateOffsetCenter(-1732px, 2556px, 320px);

    @include sm {
      top: -480px;
      width: 3125px;
      @include calculateOffsetCenter(-2156px, 3125px, $screen-sm);
    }

    @include md {
      top: -520px;
      @include calculateOffsetCenter(-2067px, 3125px, $screen-md);
    }

    @include xl {
      top: -1402px;
      @include calculateOffsetCenter(-$space-64, 3125px, $screen-xl);
    }

    @include twoXl {
      top: -1362px;
      @include calculateOffsetCenter(-$space-40, 3125px, $screen-2xl);
    }
  }
}
