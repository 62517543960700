/*--------------------------------------------*\
    # COMPONENTS - SCROLL HERO COMPOSITION
\*--------------------------------------------*/

.scroll-hero-composition {
  z-index: 50;
}

.scroll-hero-composition__text {
  position: relative;
  z-index: 50;
  height: 100vh;
  @include px($space-6);
  color: var(--white);
}

.scroll-hero-composition__title-container {
  display: block;

  @include md {
    display: none;
  }
}

.scroll-hero-composition__title-container_md {
  display: none;

  @include md {
    display: block;
  }
}

.scroll-hero-composition .scroll-hero-composition__title {
  font-size: 12.8vw;
  margin: 0 auto;
  text-align: center;
  @include font-weight("bold");
  line-height: 10.7vw;
  color: var(--white) !important;

  @include lg {
    font-size: 7.143vw;
    @include px(0);
    max-width: 80%;
    line-height: 6.2vw;
  }
}

.scroll-hero-composition__scroll-down-button {
  position: absolute;
  left: 50%;
  bottom: $space-10;
  color: inherit;
  background-color: transparent;
  border: none;
  padding: 0;
  height: $space-6;
  aspect-ratio: square;
  transform: translateX(-50%);
  cursor: pointer;
  pointer-events: auto;

  @include md {
    bottom: $space-20;
  }
}
