/*------------------------------------*\
    # COMPONENTS - HERO
\*------------------------------------*/

.hero-block {
  position: relative;
  overflow: hidden;

  padding-bottom: $space-18;

  @include md {
    padding-bottom: $space-24;
  }
  @include xl {
    padding-bottom: $space-28;
  }

  .decoration-first {
    border: $space-0-5 solid var(--main-100);
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);

    position: absolute;
    z-index: -1;

    top: auto;
    bottom: 0;

    $width: 5533px;
    width: $width;

    @include calculateOffsetCenter(-2804px, $width, 320px);

    @include sm {
      @include calculateOffsetCenter(-2595px, $width, $screen-sm);
    }

    @include md {
      bottom: $space-px;
      @include calculateOffsetCenter(-2540px, $width, $screen-md);
    }

    @include xl {
      bottom: 0;
      @include calculateOffsetCenter(-2259px, $width, $screen-xl);
    }

    @include twoXl {
      @include calculateOffsetCenter(-2023px, $width, $screen-2xl);
    }
  }

  .decoration-second {
    border: $space-0-5 solid var(--main-200);
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);

    position: absolute;
    z-index: -1;

    top: auto;
    bottom: $space-5;

    $width: 1960px;
    width: $width;
    @include calculateOffsetCenter(-967px, $width, 320px);

    @include sm {
      @include calculateOffsetCenter(-945px, $width, $screen-sm);
    }
    @include md {
      @include calculateOffsetCenter(-758px, $width, $screen-md);
    }
    @include lg {
      @include calculateOffsetCenter(-703px, $width, $screen-lg);
    }
    @include xl {
      @include calculateOffsetCenter(-422px, $width, $screen-xl);
    }
    @include twoXl {
      @include calculateOffsetCenter(-167px, $width, $screen-2xl);
    }
  }
}

.hero-block_light {
  color: var(--main-text-color-dark);
}

.hero-block_dark {
  color: var(--text-white);

  .breadcrumbs__link,
  .breadcrumbs__back-button {
    color: var(--white);
  }
}
// Background color
.hero-block:before {
  content: "";
  position: absolute;
  z-index: -1;

  bottom: 0;

  $width: 2985px;
  width: $width;

  @include calculateOffsetCenter(-1530px, $width, 320px);

  @include sm {
    bottom: $space-1;
    @include calculateOffsetCenter(-1321px, $width, $screen-sm);
  }

  @include md {
    @include calculateOffsetCenter(-1266px, $width, $screen-md);
  }

  @include xl {
    @include calculateOffsetCenter(-985px, $width, $screen-xl);
  }

  @include twoXl {
    @include calculateOffsetCenter(-749px, $width, $screen-2xl);
  }

  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
}

.hero-block_light:before {
  background-color: var(--main-bg-light);
}
.hero-block_dark:before {
  background-color: var(--main-bg-dark);
}

.hero-block_circles-none {
  &:before {
    border-radius: var(--rounded-none);
  }
}

.hero-block__breadcrumbs {
  padding-top: $space-12;
}
