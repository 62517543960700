/*------------------------------------*\
    # COMPONENTS - CAROUSEL IMAGE ITEM
\*------------------------------------*/

.carousel__image-item {
}

.carousel__image-item-image-wrapper {
  aspect-ratio: $aspect-video;
  border-radius: var(--rounded-2xl);
  width: 100%;
  overflow: hidden;
}

.carousel__image-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
