/*------------------------------------*\
    # COMPONENTS - TABS
\*------------------------------------*/

.tabs__pagination {
  display: flex;
  gap: $space-2;
  margin-bottom: $space-6;

  @include md {
    margin-bottom: $space-8;
  }

  @include lg {
    margin-bottom: $space-15;
  }

  &-pages {
    overflow-x: auto;
    position: relative;
    flex-wrap: nowrap;
    flex-direction: row;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .btn {
    white-space: nowrap;

    &.btn_prev,
    &.btn_next {
      @include px($space-5);

      &:disabled,
      &:disabled:hover {
        background-color: var(--disabled);
      }
    }

    &:focus {
      outline: none;
    }
  }
}
