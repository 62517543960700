/*------------------------------------------------------*\
    # COMPONENTS - SCROLL HEADER CONTAINER COMPOSITION  
\*------------------------------------------------------*/

.scroll-header-container-composition {
  .header {
    position: relative;
  }

  .header__button {
    color: currentColor;
  }
}

.scroll-header-container-composition__header {
  height: max-content;
  width: 100%;
  background-blend-mode: multiply;
}

.scroll-header-container-composition__logo-link {
  position: relative;
  height: $space-9;

  @include md {
    height: $space-11;
  }

  @include lg {
    height: calc($space-14 + $space-0-5);
  }
}

.scroll-header-container-composition__logo {
  @extend .header__logo;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
