/*------------------------------------*\
    # COMPONENTS - QUOTE
\*------------------------------------*/

.quote__text {
  @extend .heading-2_serif;
}

.quote__author {
  @extend .introtext;
  margin-top: $space-3;

  @include md {
    margin-top: $space-4;
  }

  @include lg {
    margin-top: $space-5;
  }
}

.quote__author::before {
  content: "— ";
}
