/*------------------------------------*\
    # COMPONENTS - ARTICLE OVERVIEW
\*------------------------------------*/

.article-overview {
  display: flex;
  flex-direction: column;
}

.variant_dark {
  .article-overview__item-title {
    color: var(--text-white);
  }
  .article-overview__item-subtitle {
    color: var(--main-text-color-light);
  }

  .article-overview__item-icon {
    color: var(--text-white);
  }

  .article-overview__item {
    &:hover {
      .article-overview__item-title,
      .article-overview__item-icon {
        color: var(--text-white-hover);
      }
    }
  }
}

.article-overview__item {
  @include py($space-5);
  color: var(--text);
  text-decoration: none;
  border-bottom: $space-px solid #e6e6e6;

  @include lg {
    @include py($space-8);
  }

  &:hover {
    .article-overview__item-title,
    .article-overview__item-icon {
      color: var(--accent-text-color-dark-hover);
    }

    .article-overview__item-icon {
      transform: translateX($space-1);
    }
  }
}

.article-overview__item-media-object {
  gap: $space-4;
}

.article-overview__item-media-wrapper {
  aspect-ratio: 1/1;
  width: $space-16;
  border-radius: var(--rounded-lg);
  overflow: hidden;
}
.article-overview__item-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-overview__item-body {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100%;
  gap: $space-4;
}

.article-overview__text {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: $space-1;
  height: 100%;
}

.article-overview__item-title {
  @extend %button-navigation;
  color: var(--accent-text-color-dark);
  @include font-weight("bold");

  @include sm {
    @include text-2xl;
    line-height: $space-6;
  }
}

.article-overview__item-subtitle {
  color: var(--text-light);
  @include text-md;
  @include font-weight("regular");
  letter-spacing: 0.28px;
}

.article-overview__item-icon {
  flex: none;
  align-self: center;
  color: var(--accent-text-color-dark);
  width: $space-6;
  height: $space-6;
  @include transition;
}

.article-overview__item-date {
  @include text-md;
  @include font-weight("semibold");
  color: var(--text-light);
}
