/*------------------------------------*\
    # COMPONENTS - TILES
\*------------------------------------*/

.tiles {
  display: grid;
  gap: $space-4;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  width: 100%;
  padding-top: $space-8;
  @include my(auto);

  @include md {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: $space-10;
    gap: $space-5;
  }

  @include lg {
    gap: $space-6;
  }

  @include xl {
    padding-top: 0;
  }
}

.variant_white {
  .flip-content {
    background-color: var(--bgLight) !important;
  }
}

.variant_light {
  .tile {
    background-color: var(--bg-white);
  }
}

.variant_dark {
  .tile {
    background-color: var(--bg-white);
  }
}

.tile {
  display: flex;
  flex-direction: column;
  gap: $space-2-5;
  color: var(--text) !important;
  padding: $space-8;
  border-radius: var(--rounded-xl);
  background-color: var(--main-bg-light);
  width: 100%;
}

.tile__image-icon {
  max-width: $space-16;
  max-height: $space-16;
  margin-bottom: $space-4;
}

.tile__title {
  color: var(--main-text-color-dark);
  @include my(0);
}

.tile__text {
  @include text-base;
}

.tile__icon {
  display: flex;
  justify-content: flex-end;
  color: var(--accent-text-color-dark);
  position: relative;
  @include transition;
}

.tile-flip-content {
  height: 100%;
  overflow: hidden;
  border-radius: var(--rounded-xl);

  .flip-content__front-area {
    height: inherit;
    border-radius: inherit;

    .tile {
      height: 100%;
    }

    .tile__title {
      color: var(--accent-1000);
    }
  }

  .flip-content__back-area {
    height: inherit;
    border-radius: inherit;

    .tile {
      height: 100%;
      color: var(--white);
      background-color: var(--accent-bg-dark);
      @include transition;
    }

    .tile__title,
    .tile__text,
    .tile__icon {
      color: var(--white);
    }

    .tile__icon {
      justify-content: flex-start;
    }

    &:hover {
      .tile {
        background-color: var(--accent-bg-dark-hover);
      }
    }
  }

  &:hover {
    .flip-content__front-area {
      background-color: var(--white);

      .tile__title,
      .tile__icon {
        color: var(--accent-800);
      }

      .tile__icon {
        transform: translateX($space-1);
      }
    }

    .flip-content__back-area {
      background-color: var(--accent-bg-dark);

      .tile__icon {
        transform: translateX(-$space-1);
        opacity: 0.8;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
      }
    }
  }
}
