/*------------------------------------*\
    # COMPONENTS - HEADER
\*------------------------------------*/

.header {
  position: relative;
  min-height: $space-15;
  @include px($space-4);
  @include py($space-3);

  @include md {
    min-height: $space-17;
    @include px($space-8);
  }

  @include lg {
    min-height: $space-22;
    @include px($space-16);
    @include py(calc($space-4 - $space-px));
  }

  .container_normal {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }
}

.header__start {
  flex: 1;
  display: flex;
  align-items: center;
}

.header__center {
  flex: none;
  display: flex;
  align-items: center;
}

.header__end {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__logo-link {
  display: inline-flex;
  align-items: center;
}

.header__logo {
  color: var(--red-1000);
  height: $space-9;

  @include md {
    height: $space-11;
  }

  @include lg {
    height: calc($space-14 + $space-0-5);
  }
}

.header__button {
  all: unset;
  display: inline-flex;
  align-items: center;
  color: var(--accent-text-color-dark);
  cursor: pointer;
  gap: $space-2;
  @include transition;

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.header__button-text {
  font-family: var(--font-sans-heading);
  display: none;
  @include font-weight("semibold");
  @include text-xl;
  line-height: 18px;

  @include sm {
    display: inline;
  }
}

.header__underline {
  display: hidden;
  position: absolute;
  left: 50%;
  bottom: -$space-3;
  height: $space-px;
  width: calc(100% - $space-14);
  background-color: var(--white);
  transform: translateX(-50%);

  @include lg {
    bottom: calc(-1 * ($space-4 - ($space-0-5 / 2)));
  }
}

.header_white {
  background-color: var(--white);
}

.header_transparent {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  background-color: transparent;

  .header__logo,
  .header__button {
    color: var(--white);
  }

  .header__underline {
    display: block;
  }
}
