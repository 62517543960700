/*------------------------------------*\
    # COMPONENTS - PANEL
\*------------------------------------*/

.panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);
  overflow: hidden;
}

.panel__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  min-height: $space-14;
  @include py($space-2);
  @include px($space-4);
  color: var(--white);
  @include text-lg;
  text-align: center;
  font-weight: 700;
  border-bottom: 1px;
  border-bottom-color: hotpink;
  background-color: rgba(255, 105, 180, 0.5);
}

.panel__body {
  padding: $space-4;
}

.panel__footer {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  min-height: $space-14;
  @include py($space-2);
  @include px($space-4);
  border-top: 1px;
  border-top: hotpink;
  background-color: rgba(255, 105, 180, 0.5);
}
