/*------------------------------------*\
    # COMPONENTS - INPUT
\*------------------------------------*/

.input {
  @include py($space-5);
  @include px($space-6);
  border: $space-px solid var(--gray-500);
  border-radius: var(--rounded);
  width: 100%;
  color: var(--gray-800);
  line-height: $space-6;
  background-color: var(--bg-white);
  @include transition;

  &:hover,
  &:focus {
    border-color: var(--gray-800);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--gray-300);
    background-color: var(--gray-100);
    border: $space-px solid var(--gray-100);
  }

  &:invalid {
    border-color: var(--red-1000);
  }

  &::placeholder {
    color: var(--gray-600);
  }
}
