/*------------------------------------*\
    # COMPONENTS - MODAL DRAWER
\*------------------------------------*/

.modal-drawer {
}

.modal-drawer__overlay {
  position: fixed;
  z-index: 40;
  @include inset(0);
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-drawer__panel {
  position: fixed;
  z-index: 50;
  display: flex;
  flex-direction: column;
}

.modal-drawer__panel-vertical-size {
  width: 100%;
  max-width: 92.5%;
  height: 100%;
  max-height: 100vh;
  pointer-events: none;

  @include sm {
    max-width: $max-width-xs;
  }

  @include md {
    max-width: $max-width-sm;
  }
}

.modal-drawer__panel-horizontal-size {
  width: 100%;
  max-height: 100vh;
  pointer-events: none;
}

.modal-drawer__panel_top {
  top: 0;
  @include inset-x(0);

  @include xl {
    .modal-drawer__slide {
      &:nth-of-type(2) {
        top: 100%;
      }
      &:nth-of-type(3) {
        top: 200%;
      }
    }
  }
}

.modal-drawer__panel_right {
  right: 0;
  @include inset-y(0);

  @include xl {
    .modal-drawer__slide {
      &:nth-of-type(2) {
        right: 100%;
      }
      &:nth-of-type(3) {
        right: 200%;
      }
    }
  }

  .modal-drawer__close {
    right: $space-6;
    left: auto;
  }
}

.modal-drawer__panel_bottom {
  bottom: 0;
  @include inset-x(0);
  width: 100%;
  max-height: 100vh;

  @include xl {
    .modal-drawer__slide {
      &:nth-of-type(2) {
        bottom: 100%;
      }
      &:nth-of-type(3) {
        bottom: 200%;
      }
    }
  }
}

.modal-drawer__panel_left {
  left: 0;
  @include inset-y(0);

  @include xl {
    .modal-drawer__slide {
      &:nth-of-type(2) {
        left: 100%;
      }
      &:nth-of-type(3) {
        left: 200%;
      }
    }
  }
}

.modal-drawer__slide {
  pointer-events: auto;
  position: absolute;
  z-index: 10;
  flex: 1 1 0%;
  width: 100%;
  overflow: hidden;

  @-moz-document url-prefix() {
    // Firefox
    scrollbar-color: #545500 var(--accent-bg-dark);
    scrollbar-width: auto;
  }

  // Chrome, edge, safari
  &::-webkit-scrollbar {
    width: $space-4;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--accent-bg-dark);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: var(--rounded-full);
    border: $space-1 solid transparent;
    background-clip: content-box;
  }

  @include xl {
    position: relative;
    z-index: 30;
  }

  &:nth-of-type(2) {
    position: absolute;
    z-index: 20;
    height: 100vh;
  }

  &:nth-of-type(3) {
    position: absolute;
    z-index: 30;
    height: 100vh;

    @include xl {
      z-index: 10;
    }
  }
}

.modal-drawer__slide_default-style {
  padding: $space-10;
  background-color: var(--white);
}

.modal-drawer__slide_open {
  overflow: auto;
}

.modal-drawer__close {
  pointer-events: auto;
  position: absolute;
  z-index: 40;
  top: $space-5;
  left: $space-6;
}

.modal-drawer__close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-drawer__close-icon {
  width: $space-8;
  height: $space-8;
  border-radius: var(--rounded-full);
}
