/*------------------------------------*\
    # COMPONENTS - CARD
\*------------------------------------*/

.card {
  overflow: hidden;
  border-radius: var(--rounded-2xl);

  &:hover {
    .card__body {
      background-color: var(--accent-bg-dark-hover);
    }
  }
}

.card__header {
}

.card__body {
  background-color: var(--accent-bg-dark);
  color: var(--text-white);
  @include transition;
}

.variant_dark {
  .card {
    &:hover {
      .card__body {
        background-color: var(--bg-white);
      }
    }
  }

  .card__body {
    background-color: var(--bg-white);
    color: var(--accent-text-color-dark);
  }
}

.card__footer {
}
