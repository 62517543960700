/*--------------------------------------------*\
    # COMPONENTS - INTERACTIVE MAP MARKER
\*--------------------------------------------*/

.interactive-map-marker {
  position: relative;
  display: inline-flex;
  aspect-ratio: $aspect-square;
  width: $space-6;
  background-color: var(--white);
  border-radius: var(--rounded-full);
  border: none;
  box-shadow: 0px 0.75px 3px 0px #0000002b;
  cursor: pointer;
  transform: translateX(-50%) translateY(-50%);

  @include xl {
    width: $space-6;
  }
}

.interactive-map-marker__pulse {
  position: absolute;
  @include inset(0);
  aspect-ratio: $aspect-square;
  width: 100%;
  border: $space-px solid var(--white);
  border-radius: inherit;
  box-shadow: inherit;
}
