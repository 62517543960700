/*------------------------------------*\
    # COMPONENTS - CARD SLIDER BLOCK
\*------------------------------------*/
@use "sass:math";

.card-slider-block {
  &__title {
    color: var(--main-text-color-dark);
    
    @include lg {
      display: flex;
      align-items: flex-end;
    }
  }

  &__description {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: 0px;
    }
  }
}

.card-slider-block__text {
  margin-bottom: $space-6;

  @include md {
    margin-bottom: $space-8;
  }

  @include lg {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-5;
    margin-bottom: $space-10;
  }
}

.card-slider-block__reel {
  @include mx(-$space-6);
  @include px($space-6);

  @include md {
    @include mx(-$space-8);
    @include px($space-8);
  }

  @include lg {
    @include mx(calc(-1 * (100vw - $max-width-7xl + $space-16) / 2));
    @include px(calc((100vw - $max-width-7xl + $space-16) / 2));
  }
}

.card-slider-block__card {
  width: 85vw;
  max-width: 327px;
  aspect-ratio: math.div(272, 317);

  @include sm {
    aspect-ratio: auto;
    max-width: none;
    width: 346px;
    height: 360px;
  }

  @include lg {
    width: 400px;
    height: 400px;
  }
}

.card-slider-block__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $space-4;
}
