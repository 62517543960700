/*------------------------------------*\
    # COMPONENTS - SEARCH BAR
\*------------------------------------*/

.search-bar {
  position: relative;
  display: flex;
  @include space-x($space-2);
  padding: $space-6 0;
  color: var(--main-text-color-dark);
}

.search-bar__input {
  padding: $space-5 $space-6;
  width: 100%;
  color: currentColor;
  background-color: transparent;
  border: 1px solid var(--white);
  border-radius: var(--rounded);
  outline: none;
  appearance: none;
  display: block;

  &.has-suggestions {
    border-radius: var(--rounded) var(--rounded) 0 0;
  }

  &::placeholder {
    color: var(--white);
  }

  &:focus,
  &:valid {
    color: currentColor;
    background-color: var(--white);

    &::placeholder {
      color: currentColor;
    }
  }
}

.search-bar__button {
  display: none;
  padding: 18px;
  color: var(--accent-text-color-dark);
  background-color: var(--white);
  border: none;
  border-radius: var(--rounded-lg);
  cursor: pointer;

  @include sm {
    display: inline-flex;
  }

  &:disabled {
    color: var(--main-500);
    background-color: var(--main-bg-dark-hover);
    cursor: not-allowed;
  }
}

.search-bar__icon {
}

.search-box {
  width: 100%;
  position: relative;
}

.search-suggestions {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: var(--white);
  border: 1px solid var(--gray-100);
  border-radius: 0 0 var(--rounded) var(--rounded);
  z-index: 100;

  &__list {
    margin: 0;
    padding: $space-5 0;

    li {
      list-style: none;
      margin: 0;
      padding: $space-2-5 $space-6;
      cursor: pointer;

      &:hover {
        background-color: var(--accent-50);
      }
    }
  }
}
