/*------------------------------------*\
    # COMPONENTS - BUTTONS
\*------------------------------------*/

.btn {
  @include text-xl;
  @include font-weight("bold");
  @include transition(background-color);
  @include py($space-4);
  @include px($space-7);
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: $space-2;
  border-width: 0px;
  border-image: none;
  border-style: solid;
  line-height: $space-7;
  cursor: pointer;
  font-family: var(--font-sans-heading);
  min-height: $space-16;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    .btn__icon {
      transform: translateX($space-1);
    }
  }

  &:disabled:hover {
    transform: translateX(0);
  }
}

.btn__icon {
  @include transition;
}

.btn_primary {
  background-color: var(--accent-bg-dark);
  color: var(--text-white);
  border-radius: var(--rounded-lg);

  &:hover {
    color: var(--text-white);
    background-color: var(--accent-bg-dark-hover);
  }

  &:focus {
    outline: $space-px solid var(--accent-border-dark);
    outline-offset: $space-2;
  }

  &:disabled,
  &:disabled:hover {
    background-color: var(--accent-disabled-light);
  }
}

.btn_secondary {
  background-color: transparent;
  color: var(--accent-text-color-dark);
  border: $space-px solid var(--accent-border-light);
  border-radius: var(--rounded-lg);

  &:hover {
    color: var(--accent-text-color-dark-hover);
    border: $space-px solid var(--accent-border-light-hover);
  }

  &:focus {
    outline: $space-px solid var(--accent-border-dark);
    outline-offset: $space-2;
  }

  &:disabled,
  &:disabled:hover {
    border-color: var(--accent-disabled-light);
    color: var(--accent-disabled-light);
  }
}

.btn_tertiary {
  background-color: transparent;
  color: var(--accent-text-color-dark);

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }

  &:focus {
    border-radius: var(--rounded-lg);
    outline: $space-px solid var(--accent-border-dark);
    outline-offset: $space-2;
  }

  &:disabled,
  &:disabled:hover {
    color: var(--accent-disabled-light);
  }
}

.variant_dark {
  .btn_primary {
    color: var(--main-text-color-dark);
    background-color: var(--bg-white);

    &:hover {
      color: var(--main-text-color-dark);
      background-color: var(--bg-white-hover);
    }

    &:focus {
      outline: $space-px solid var(--bg-white);
    }

    &:disabled,
    &:disabled:hover {
      background-color: var(--main-disabled-dark);
    }
  }

  .btn_secondary {
    background-color: transparent;
    color: var(--bg-white);
    border: $space-px solid var(--main-border-light);

    &:hover {
      border-color: var(--main-border-light-hover);
    }

    &:focus {
      outline: $space-px solid var(--bg-white);
    }

    &:disabled,
    &:disabled:hover {
      border-color: var(--main-disabled-dark);
      color: var(--main-disabled-dark);
    }
  }

  .btn_tertiary {
    background-color: transparent;
    color: var(--text-white);

    &:hover {
      color: var(--text-white-hover);
    }

    &:focus {
      outline: $space-px solid var(--bg-white);
    }

    &:disabled,
    &:disabled:hover {
      color: var(--main-disabled-dark);
    }
  }
}
