/*------------------------------------*\
    # COMPONENTS - BREADCRUMBS
\*------------------------------------*/

.breadcrumbs {
  color: var(--accent-text-color-dark);
}

.breadcrumbs__link {
  color: var(--accent-text-color-dark);
  text-decoration: none;
  font-family: var(--font-sans);
  @include text-md;
  letter-spacing: 0.01em;
  @include font-weight("regular");
}

.breadcrumbs_mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include sm {
    display: none;
  }
}

.breadcrumbs_desktop {
  display: none;
  @include sm {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.breadcrumbs_desktop {
  .breadcrumbs__separator {
    @include mx($space-1);
  }
}

.breadcrumbs_desktop {
  .breadcrumbs__link:last-child {
    @include font-weight("semibold");
  }
}

.breadcrumbs__back-button {
  font-family: var(--font-sans);
  color: currentColor;
  @include text-md;
  letter-spacing: 0.01em;
  @include font-weight("regular");
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.hero-block_dark,
.hero-article-block_dark,
.hero-with-image-block_dark,
.hero-with-embed-block_dark,
.hero-with-carousel-block_dark,
.hero-with-quicklinks-block_dark,
.hero-with-large-card-slider-block_dark {
  .breadcrumbs {
    color: var(--text-white);
  }
}
