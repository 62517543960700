///*------------------------------------*\
//    # SETTINGS - GLOBAL
//\*------------------------------------*/

$max-width-xs: 20rem; // 320px
$max-width-sm: 24rem; // 384px
$max-width-md: 28rem; // 448px
$max-width-lg: 32rem; // 512px
$max-width-xl: 36rem; // 576px
$max-width-2xl: 42rem; // 672px
$max-width-3xl: 48rem; // 768px
$max-width-4xl: 56rem; // 896px
$max-width-5xl: 64rem; // 1024px
$max-width-6xl: 72rem; // 1152px
$max-width-7xl: 80rem; // 1280px
$max-width-full: 100%;
$max-width-min: min-content;
$max-width-max: max-content;
$max-width-fit: fit-content;
$max-width-prose: 65ch;
$max-width-screen-sm: 640px;
$max-width-screen-md: 768px;
$max-width-screen-lg: 1024px;
$max-width-screen-xl: 1280px;
$max-width-screen-2xl: 1536px;
