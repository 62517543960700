/*------------------------------------*\
    # COMPONENTS - SEARCH CONTAINER
\*------------------------------------*/

.search-container {
  position: relative;
  background-color: var(--main-bg-dark);
  height: 100vh;
  width: 100%;

  .search-page & {
    height: auto;
  }
}

.search-container__content {
  position: relative;
  z-index: 20;
  @include mx-auto;
  @include px($space-6);
  padding-top: 18.5vh;
  max-width: $max-width-md;

  @include sm {
    padding-top: 32.5vh;
  }

  @include md {
    max-width: $max-width-xl;
  }

  @include xl {
    max-width: $max-width-2xl;
  }

  .search-page & {
    padding-top: $space-6;
  }
}

.search-container__title {
  color: var(--white);
  @extend .heading-6;
}

.search-container .decoration-first {
  border: $space-px solid var(--main-500);
  top: 276px;
  width: 2279px;
  z-index: 10;

  @include calculateOffsetCenter(-1381px, 2279px, 375px);

  @include sm {
    top: -217px;
    width: 1615px;
    @include calculateOffsetCenter(-1009px, 1615px, $screen-sm);
  }

  @include md {
    top: -468px;
    @include calculateOffsetCenter(-914px, 1615px, $screen-md);
  }

  @include xl {
    top: -455px;
    @include calculateOffsetCenter(-464px, 1615px, $screen-lg);
  }
}

.search-container .decoration-second {
  border: $space-1 solid var(--white);
  top: 388px;
  width: 1311px;
  z-index: 10;

  @include calculateOffsetCenter(-662px, 1311px, 375px);

  @include sm {
    top: -1221px;
    width: 2806px;
    @include calculateOffsetCenter(-1985px, 2806px, $screen-sm);
  }

  @include md {
    top: -1472px;
    @include calculateOffsetCenter(-1858px, 2806px, $screen-md);
  }

  @include xl {
    top: -1459px;
    @include calculateOffsetCenter(-1440px, 2806px, $screen-lg);
  }
}
