/*---------------------------------------------*\
    # COMPONENTS - HERO WITH COVER IMAGE BLOCK
\*---------------------------------------------*/

.hero-with-cover-image-block {
  position: relative;
  overflow: hidden;

  @include sm {
    overflow: visible;
  }

  .cover-image {
    height: $space-72;

    @include sm {
      position: static;
      height: auto;
      padding-bottom: $space-32;
    }

    @include lg {
      min-height: 100vh;
    }

    .cover-image__image {
      height: inherit;
      min-height: auto;

      @include sm {
        height: 100%;
        min-height: inherit;
      }
    }

    .cover-image__content {
      @include sm {
        position: static;
      }
    }
  }
  .introtext {
    padding-top: $space-4;
  }

  .decoration-first {
    border: $space-0-5 solid var(--main-800);
    top: -1025px;
    z-index: 10;
    width: 1730px;
    @include calculateOffsetCenter(-913px, 1730px, 375px);

    @include sm {
      top: -1262px;
      width: 1730px;
      @include calculateOffsetCenter(-696px, 1730px, $screen-sm);
    }

    @include md {
      top: -1162px;
      width: 1730px;
      @include calculateOffsetCenter(-580px, 1730px, $screen-md);
    }

    @include lg {
      top: -1162px;
      width: 1730px;
      @include calculateOffsetCenter(-580px, 1730px, $screen-lg);
    }

    @include xl {
      top: -1020px;
      width: 1730px;
      @include calculateOffsetCenter(-314px, 1730px, $screen-xl);
    }

    @include twoXl {
      top: -792px;
      width: 1730px;
      @include calculateOffsetCenter(-40px, 1730px, $screen-2xl);
    }
  }

  .decoration-second {
    border: $space-1 solid var(--main-200);
    top: -2706px;
    z-index: 10;
    width: 3737px;
    @include calculateOffsetCenter(-2658px, 3737px, 375px);

    @include sm {
      top: -2943px;
      width: 3737px;
      @include calculateOffsetCenter(-2441px, 3737px, $screen-sm);
    }

    @include md {
      top: -2844px;
      width: 3737px;
      @include calculateOffsetCenter(-2324px, 3737px, $screen-md);
    }

    @include lg {
      top: -2844px;
      width: 3737px;
      @include calculateOffsetCenter(-2324px, 3737px, $screen-lg);
    }

    @include xl {
      top: -2702px;
      width: 3737px;
      @include calculateOffsetCenter(-2059px, 3737px, $screen-xl);
    }

    @include twoXl {
      top: -2474px;
      width: 3737px;
      @include calculateOffsetCenter(-1785px, 3737px, $screen-2xl);
    }
  }
}

.hero-with-cover-image-block_circles-none {
  &:before {
    border-radius: var(--rounded-none);
    bottom: $space-32;

    @include sm {
      bottom: $space-28;
    }

    @include xl {
      bottom: $space-24;
    }
  }
}

.hero-with-cover-image-block__visuals-container {
  @include sm {
    position: relative;
    overflow: hidden;
  }
}

.hero-with-cover-image-block__responsive-submenu {
  position: relative;
  z-index: 20;
  @include px($space-6);
  transform: translateY(-24px);
  display: flex;
  justify-content: center;

  @include sm {
    @include px($space-8);
    position: absolute;
    top: calc(100% - 80px);
    left: 50%;
    width: calc(100% - 64px);
    max-width: $max-width-4xl;
    transform: translateX(-50%) translateY(0);
  }

  @include lg {
    left: 50%;
    top: auto;
    bottom: $space-12;
    width: calc(100% - 64px);
    @include px(0);
  }

  .responsive-submenu {
    width: auto;
  }
}

.hero-with-cover-image-block__text {
  position: relative;
  z-index: 20;
  display: none;
  margin: 0 auto;
  color: var(--white);
  text-align: center;
  text-shadow: 0px 2px 4px var(--black);
  pointer-events: none;
  padding-top: $space-16;

  @include sm {
    display: block;
    padding-top: $space-8;
  }

  @include lg {
    padding-top: 35vh;
  }
}

.hero-with-cover-image-block__text-below-image {
  position: relative;
  z-index: 20;
  @include py($space-6);
  @include px($space-6);
  min-height: $space-80;
  color: var(--accent-text-color-dark);
  text-align: center;

  @include sm {
    display: none;
  }
}

.hero-with-cover-image-block__buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $space-6;

  .scroll-page-background-composition__scroll-down-button {
    cursor: initial;
  }
}
