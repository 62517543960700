/*------------------------------------*\
    # COMPONENTS - UPLOAD INPUT
\*------------------------------------*/

.upload-input {
}

.upload-input__file-list {
}

.file-list {
}

.file-list__file {
  &:not(:first-child) {
    margin-top: $space-1;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}
