/*------------------------------------*\
    # COMPONENTS - PUBLICATION BLOCK
\*------------------------------------*/

.publication-block {
  &__container {
    @include mx($space-6);

    @include sm {
      @include mx($space-8);
    }

    @include xl {
      max-width: $max-width-4xl;
      @include px($space-8);
      @include mx-auto;
    }
  }

  &__title {
    color: var(--main-text-color-dark);
  }

  &__subtitle {
    margin-top: $space-3;
  }
}

.variant_dark {
  .publication-block__title,
  .publication-block__date,
  .publication-block__subtitle {
    color: var(--white);
  }
}
