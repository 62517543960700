/*------------------------------------*\
    # COMPONENTS - FLIP CONTENT
\*------------------------------------*/

.flip-content {
  position: relative;
  cursor: pointer;
}

.flip-content__front-area {
}

.flip-content__back-area {
}
