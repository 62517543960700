/*------------------------------------*\
    # COMPONENTS - LANGUAGE SWITCHER
\*------------------------------------*/

.language-switcher {
  position: relative;
  z-index: 50;

  &__button {
    @extend .header__button-text;
    @include text-lg;
    @include py($space-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-2;
    cursor: pointer;
    color: var(--accent-text-color-dark);
  
    &:hover {
      color: var(--accent-text-color-dark-hover);
    }

    &-current-language {
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: -$space-1;
    border: $space-px solid var(--gray-200);
    border-radius: $space-1;
    flex-direction: column;
    z-index: 10;
    overflow: hidden;

    &-item {
      @extend .header__button-text;
      @include text-lg;
      @include px($space-6);
      @include py($space-2);
      display: block;
      background-color: var(--white);
      color: var(--accent-text-color-dark);
      border: none;
      cursor: pointer;

      &:hover {
        background-color: var(--accent-50);
      }
    }
  }

  &:hover &__dropdown,
  &:focus &__dropdown,
  &:active &__dropdown {
    display: flex;
  }
}
