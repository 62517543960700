/*------------------------------------*\
    # COMPONENTS - REEL
\*------------------------------------*/

.reel {
  overflow-x: auto;
  position: relative;
  display: flex;
  gap: $space-4;
}

.reel__item-wrapper {
  flex-shrink: 0;
}

.reel__navigation {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: $space-2;
  margin-top: $space-14;
}

.reel__navigation-button {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: $space-2;
  border-width: 0px;
  border-image: none;
  border-style: solid;
  line-height: 1;
  cursor: pointer;
  width: $space-16;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  background-color: var(--accent-bg-dark);
  color: var(--bg-white);
  @include transition;

  &:hover {
    background-color: var(--accent-bg-dark-hover);
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $gray-100;
    color: $gray-300;
  }
}

.variant_dark {
  .reel__navigation-button {
    color: var(--accent-bg-dark);
    background-color: var(--bg-white);

    &:hover {
      background-color: var(--bg-white-hover);
    }

    &:disabled {
      color: $gray-300;
      background-color: var(--main-900);
    }
  }
}

.reel__navigation-icon {
  height: $space-6;
  width: $space-6;
}

.reel__hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
