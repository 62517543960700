/*----------------------------*\
    # COMPONENTS - TABLE
\*----------------------------*/

.table-wrapper {
  overflow-x: auto;
  border-radius: var(--rounded-2xl);

  th {
    white-space: nowrap;
  }
}

.table,
.rich-text table {
  width: 100% !important;
  @include text-base;
  border-collapse: collapse;

  th,
  td {
    @include py($space-3);
    @include px($space-5);
    text-align: left;
    background: var(--main-100);
    color: var(--text);
  }

  thead td,
  th {
    @include text-2xl;
    @include font-weight("bold");
    border-bottom: $space-px solid var(--main-1000);
    background: var(--main-1000);
    font-family: var(--font-sans-heading);
    color: var(--white);
  }

  tfoot {
    @include font-weight("bold");

    td {
      border-top: $space-px solid var(--main-separator-dark);
    }
  }
}

.table_striped {
  thead td,
  th {
    background-color: var(--main-100) !important;
    color: var(--main-1000) !important;
  }

  tbody {
    tr:nth-of-type(odd) td {
      background-color: var(--white);
    }

    tr:nth-of-type(even) td {
      background-color: var(--main-100);
    }
  }
}

.table_bordered {
  thead td,
  th {
    background-color: var(--main-bg-dark);
    color: var(--white);
  }

  tbody {
    background-color: var(--main-100);
  }

  tr:not(:first-child) td {
    border-top: $space-px solid var(--main-separator-dark);
  }

  tfoot {
    td {
      border-bottom: none;
    }
  }
}

.table__cell_light {
  background-color: var(--main-100) !important;
}

th.table__cell_light {
  color: var(--main-text-color-dark) !important;
}

.table__cell_dark {
  color: var(--white) !important;
  background-color: var(--main-bg-dark) !important;
}

th.table__cell_dark {
  border-bottom: $space-px solid var(--white) !important;
}

tfoot {
  .table__cell_dark {
    border-top: $space-px solid var(--white) !important;
  }
}
