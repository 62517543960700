/*------------------------------------*\
    # COMPONENTS - SLIDE
\*------------------------------------*/

.slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.slide__transition {
  position: absolute;
  @include inset(0);
}

.slide-group {
  position: relative;
  overflow: hidden;
}
