/*------------------------------------*\
    # COMPONENTS - LINK LIST BLOCK
\*------------------------------------*/

.link-list-block {
  .link-list-block__title {
    color: var(--main-text-color-dark);
  }

  &__description {
    margin-top: $space-3;
  
    @include md {
      margin-top: $space-4;
    }
  
    @include lg {
      margin-top: $space-5;
    }
  }
}
