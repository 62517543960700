/*------------------------------------------*\
    # COMPONENTS - SCROLL CONTAINER
\*------------------------------------------*/

.scroll-container {
}

.scroll-container_stacked {
  position: absolute;
  @include inset(0);
}

.scroll-container__content {
  position: sticky;
  height: 100vh;
  @include inset(0);
  overflow: hidden;
  pointer-events: none;
}
