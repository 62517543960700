/*----------------------------------------------*\
    # COMPONENTS - PROJECT OVERVIEW BLOCK
\*----------------------------------------------*/

.project-overview-filters__heading {
  color: var(--main-1000);
}

.project-overview-filters {
  margin-top: $space-10;

  @include sm {
    display: flex;
    gap: $space-4;
  }
}

.project-overview-results__filter {
  width: 100%;

  @include sm {
    max-width: 33%;
  }
}

.project-overview-results {
  margin-top: $space-10;
  margin-bottom: $space-14;
}

.project-overview-results__list {
  @extend .container_normal;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: $space-6;
  grid-row-gap: $space-12;

  @include sm {
    grid-template-columns: repeat(2, 1fr);
  }

  @include md {
    grid-template-columns: repeat(3, 1fr);
  }
}

.project-overview-results__list-item-link {
  text-decoration: none;
}

.project-overview-results__list-item-image-wrapper {
  aspect-ratio: 16/9;
  max-width: 100%;
  transform: translateX(0px);
  overflow: hidden;
  border-radius: var(--rounded-2xl);
  background-color: var(--gray-600);
  position: relative;
}

.project-overview-results__list-item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-overview-results__list-item-text {
  padding: $space-4 $space-4 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.project-overview-results__list-item-date {
  @extend .subtext;
  @include font-weight("regular");
  margin-top: $space-2;

  @include md {
    margin-top: $space-3;
  }

  @include lg {
    margin-top: $space-4;
  }
}

.project-overview-results__load-more {
  @extend .container_normal;

  display: flex;
  justify-content: center;
  @include my($space-14);
}
