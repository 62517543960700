/*------------------------------------*\
    # COMPONENTS - RESPONSIVE SUBMENU
\*------------------------------------*/

.responsive-submenu {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--accent-bg-dark);
  border-radius: var(--rounded-lg);
  overflow-x: hidden;

  .submenu-collapsible {
    width: 100%;

    .submenu-collapsible__body {
      padding-bottom: 0;
    }

    @include xl {
      display: none;
    }
  }

  .submenu-dragslider {
    display: none;

    @include xl {
      display: inline-block;
    }
  }
}

.responsive-submenu__link {
  position: relative;
  display: flex;
  justify-content: space-between;
  @include py($space-7);
  color: var(--text-white);
  @include text-lg;
  line-height: 18px;
  text-decoration: none;
  border-bottom: $space-1 solid transparent;
  @include transition;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    color: var(--text-white-hover);

    .responsive-submenu__link-icon {
      transform: translateX($space-1);
    }
  }
}

.responsive-submenu__link-icon {
  height: $space-6;
  width: $space-6;

  @include xl {
    display: none;
  }
}

.responsive-submenu__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 0 $space-4;
}
