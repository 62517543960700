///*------------------------------------*\
//    # SETTINGS - FONTS
//\*------------------------------------*/

$font-sans: "Roboto", sans-serif;
$font-sans-headings: "BlenderPro", sans-serif;
$font-serif: "Times New Roman", sans-serif;
$font-code: "Roboto Mono", monospace;

$font-roboto: "Roboto", sans-serif;
$font-blender: "BlenderPro", sans-serif;
$font-campton-heading: "CamptonHeading", sans-serif;
$font-campton-body: "CamptonBody", sans-serif;

:root {
  --font-roboto: #{$font-roboto};
  --font-blender: #{$font-blender};
  --font-campton-heading: #{$font-campton-heading};
  --font-campton-body: #{$font-campton-body};
}
