/*------------------------------------*\
    # COMPONENTS - IMAGE BLOCK
\*------------------------------------*/

.image-block {
  .image-block__title {
    color: var(--main-text-color-dark);
  }

  &__subtitle {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }
    
    @include lg {
      margin-top: $space-5;
    }
  }
}

.image-block_full-width {
  padding: 0;

  .container_normal {
    max-width: none;
    padding: 0;
    margin: 0;
  }

  .image-block__image-wrapper {
    border-radius: var(--rounded-none);
    max-height: $space-96;

    @include md {
      max-height: 432px;
    }

    @include xl {
      max-height: 100vh;
    }
  }
}

.image-block__image-wrapper {
  width: 100%;
  aspect-ratio: $aspect-video;
  border-radius: var(--rounded-2xl);
  overflow: hidden;
  margin-top: $space-6;

  @include md {
    margin-top: $space-8;
  }

  @include lg {
    margin-top: $space-10;
  }
}

.image-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
