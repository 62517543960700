/*--------------------------------------------------------*\
    # COMPONENTS - SCROLL LINKED TEXT CRAWL COMPOSITION
\*--------------------------------------------------------*/

.scroll-linked-text-crawl-composition {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-linked-text-crawl-composition__staggered-line-container {
  position: relative;
  width: 100vw;
  max-height: $space-56;
  @include mx(auto);

  @include lg {
    max-height: $space-96;
    max-width: 50vw;
  }
}

.scroll-linked-text-crawl-composition__staggered-line-wrapper {
  position: absolute;
  z-index: 20;
  @include text-4xl;
  @include font-weight("bold");
  width: 100%;
}

.scroll-linked-text-crawl-composition__staggered-line {
  font-size: $space-10;
  line-height: $space-9;
  font-family: var(--font-sans-heading);
  text-align: center;
  @include px($space-6);
  padding-bottom: $space-2;

  // Necessary to work with the gradient fade in and out animations
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;

  @include md {
    font-size: 5.2vw;
    line-height: 4.69vw;
  }

  @include lg {
    font-size: 3.33vw;
    line-height: 2.88vw;
  }
}
