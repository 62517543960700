/*------------------------------------*\
    # OBJECTS - ROUNDED
\*------------------------------------*/

.rounded-none {
  border-radius: var(--rounded-none);
}
.rounded-sm {
  border-radius: var(--rounded-sm);
}
.rounded {
  border-radius: var(--rounded);
}
.rounded-md {
  border-radius: var(--rounded-md);
}
.rounded-lg {
  border-radius: var(--rounded-lg);
}
.rounded-xl {
  border-radius: var(--rounded-xl);
}
.rounded-2xl {
  border-radius: var(--rounded-2xl);
}
.rounded-3xl {
  border-radius: var(--rounded-3xl);
}
.rounded-full {
  border-radius: var(--rounded-full);
}
