/*------------------------------------*\
    # COMPONENTS - HOME PAGE
\*------------------------------------*/

.home-page {
  font-family: var(--font-sans-heading);

  @include sm {
    padding-left: $space-2;
  }
}

.home-page__text {
  @include mx(auto);
  @include px($space-6);
  text-align: center;
  color: var(--main-text-color-dark);
  @include font-weight("bold");
  font-size: 10.6vw;
  line-height: 9.6vw;
  letter-spacing: -1%;

  @include md {
    font-size: 6.25vw; // 48px, middle of mobile and desktop values
    line-height: 5.47vw; // 42px
  }

  @include lg {
    font-size: 3.33vw;
    line-height: 2.88vw;
    max-width: 952px;
  }
}

.home-page__text_tight {
  @include lg {
    max-width: 800px;
  }
}

.home-page__statement {
  font-size: 9.6vw;
  padding: 0 $space-10;
  text-align: center;
  color: var(--text-white);
  width: 100%;
  line-height: 1.14;
  margin: 0;
  font-weight: 700;

  @include md {
    font-size: 5.47vw;
    padding: 0 $space-12;
    max-width: 800px;
  }

  @include xl {
    font-size: 56px;
    padding: 0;
    max-width: 768px;
  }
}

@media (max-width: #{$screen-sm}) {
  .home-page__statement br {
    display: none;
  }
}

.home-page__statement_dark {
  color: var(--main-text-color-dark);
}

.home-page__rounded-image-wrapper {
  border-radius: var(--rounded-full);
  width: $space-80;
  aspect-ratio: $aspect-square;
  overflow: hidden;
}

.home-page__rounded-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.home-page__card-slider {
  color: var(--text-white);

  .move-content-composition__layer {
    display: block;
  }

  .home-page__card-slider-text {
    @extend .card-slider-block__text;
  }

  .home-page__card-slider-reel {
    @extend .card-slider-block__reel;
  }

  .home-page__card-slider-card {
    @extend .card-slider-block__card;
  }
}
