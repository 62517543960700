/*------------------------------------*\
    # COMPONENTS - CAROUSEL
\*------------------------------------*/

.carousel {
  position: relative;
  aspect-ratio: $aspect-video;
  margin-top: $space-6;

  @include md {
    margin-top: $space-8;
  }

  @include lg {
    margin-top: $space-10;
  }
}

.carousel_default {
  margin-bottom: $space-9;
}
.carousel_navigation-bottom {
  margin-bottom: $space-36;
}

.carousel__items-wrapper {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  border-radius: var(--rounded-2xl);
}

.carousel__items-motion-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.carousel__item {
  width: 100%;
  height: 100%;
}

.carousel__pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: $space-3;
  position: absolute;
  z-index: 10;
  bottom: 0;
  bottom: -$space-6;
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}

.carousel__pagination-button {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: $space-2;
  border-width: 0px;
  border-image: none;
  border-style: solid;
  line-height: 1;
  cursor: pointer;
  width: $space-2;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  background-color: var(--accent-200);
  @include transition;

  &:hover {
    background-color: var(--accent-bg-dark);
  }
}

.carousel__pagination-button_active {
  background-color: var(--accent-bg-dark);
}

.variant_dark {
  .carousel__pagination-button {
    background-color: var(--main-800);

    &:hover {
      background-color: var(--bg-white);
    }
  }
  .carousel__pagination-button_active {
    background-color: var(--bg-white);
  }
}

.carousel__navigation-bottom {
  position: relative;
}

.carousel__navigation-button {
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: $space-2;
  border-width: 0px;
  border-image: none;
  border-style: solid;
  line-height: 1;
  cursor: pointer;
  width: $space-16;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  background-color: var(--accent-bg-dark);
  color: var(--bg-white);
  @include transition;

  &:hover {
    background-color: var(--accent-bg-dark-hover);
  }
}

.carousel__navigation-button:disabled {
  cursor: not-allowed;
  background-color: $gray-100;
  color: $gray-300;

  &:hover {
    background-color: $gray-100;
  }
}

.carousel__button-previous_default {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translate(-$space-3-5, -50%);
}
.carousel__button-next_default {
  position: absolute;
  z-index: 1;
  right: 0;
  top: 50%;
  transform: translate($space-3-5, -50%);
}

.carousel__button-previous_bottom {
  position: absolute;
  z-index: 1;
  transform: translateY(100%);
  bottom: -$space-10;
  right: $space-18;
}
.carousel__button-next_bottom {
  position: absolute;
  z-index: 1;
  transform: translateY(100%);
  bottom: -$space-10;
  right: 0;
}
