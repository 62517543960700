/*------------------------------------------------*\
    # COMPONENTS - SEARCH MODAL BUTTON
\*------------------------------------------------*/

.search-modal-button {
  @extend .header__button;
}

.search-modal-button__text {
  @extend .header__button-text;
}

.search-modal-button__modal {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.search-modal-button__modal-close-button {
  position: absolute;
  top: 20px;
  right: 24px;
  z-index: 20;
  width: $space-6;
  height: $space-6;
  @include px(0);
  color: var(--white);
  background-color: transparent;
  border: none;
  appearance: none;

  @include sm {
    top: 32px;
    right: 32px;
  }

  @include lg {
    top: 40px;
    right: 64px;
  }
}
