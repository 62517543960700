
.contributor-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-top: 1rem;

  @media (min-width: 1280px) {
    flex-direction: row;
  }
}

.contributor-wrapper_column {
  flex: 1;
}

.contributors-list {
  list-style: none;

  li {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.no-contributor-link {
      line-height: 2rem;
      padding-left: 3rem;
    }
  }
}

.contributor-link {
  background-position: left center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  height: 2rem;
  display: inline-flex;
  padding-left: 3rem;
  align-items: center;

  &.icon-linkedin {
    background-image: url("../../assets/icons/icon-linkedin.svg");
  }

  &.icon-x {
    background-image: url("../../assets/icons/icon-x.svg");
  }
}
