/*------------------------------------*\
    # COMPONENTS - SOCIALS
\*------------------------------------*/

.socials {
  display: flex;
  gap: $space-2;
  width: $space-72;
  flex: none;
}

.socials__link {
  background-color: transparent;
  text-decoration: none;
  color: var(--accent-text-color-dark);
  cursor: pointer;
  @include transition;

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.socials__link-icon {
  width: $space-8;
  color: currentColor;
  aspect-ratio: 1/1;
}
