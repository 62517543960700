/*------------------------------------*\
    # COMPONENTS - FOOTER LINKS
\*------------------------------------*/

.footer-links {
  position: relative;
  color: var(--main-text-color-light);
  background-color: var(--main-bg-dark);
  overflow: hidden;
}

.footer-links__text {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $space-3;
  @include py($space-6);
  padding-right: 40%;
  border-top: $space-px solid var(--main-border-light);

  @include sm {
    flex-direction: row;
    padding-right: 0;
    gap: $space-6;

    .footer-menu__link-item:last-of-type {
      margin-right: auto;
    }
  }
}

.footer-links__date {
  position: absolute;
  bottom: $space-6;
  right: $space-6;
  font-family: var(--font-sans-heading);
  @include text-xl;
  line-height: 18px;
  text-align: right;
  flex: none;
  max-width: 40%;
  color: var(--white);

  @include sm {
    position: static;
    bottom: 0;
    right: 0;
    max-width: none;
  }
}
