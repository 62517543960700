/*------------------------------------*\
    # COMPONENTS - MULTI IMAGE BLOCK
\*------------------------------------*/

.multi-image-block {
  .heading-2 {
    color: var(--main-text-color-dark);
  }

  .decoration-first {
    width: 1311px;
    top: -$space-4;
    @include calculateOffsetCenter(-666px, 1311px, 320px);

    @include sm {
      top: -$space-52;
      width: 1603px;
      @include calculateOffsetCenter(-852px, 1603px, $screen-sm);
    }

    @include md {
      top: -$space-64;
      @include calculateOffsetCenter(-764px, 1603px, $screen-md);
    }

    @include xl {
      top: -$space-40;
      @include calculateOffsetCenter(-$space-12, 1603px, $screen-xl);
    }

    @include twoXl {
      top: -$space-28;
      @include calculateOffsetCenter($space-16, 1603px, $screen-2xl);
    }
  }

  .decoration-second {
    width: 2556px;
    top: -$space-60;
    @include calculateOffsetCenter(-1732px, 2556px, 320px);

    @include sm {
      top: -480px;
      width: 3125px;
      @include calculateOffsetCenter(-2156px, 3125px, $screen-sm);
    }

    @include md {
      top: -520px;
      @include calculateOffsetCenter(-2067px, 3125px, $screen-md);
    }

    @include xl {
      top: -1402px;
      @include calculateOffsetCenter(-$space-64, 3125px, $screen-xl);
    }

    @include twoXl {
      top: -1362px;
      @include calculateOffsetCenter(-$space-40, 3125px, $screen-2xl);
    }
  }

  &__subtitle {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }
    
    @include lg {
      margin-top: $space-5;
    }
  }
  
  .multi-image-block__grid {
    margin-top: $space-6;

    @include md {
      display: flex;
      gap: $space-6;
      margin-top: $space-8;

      > div {
        flex-basis: 0;
        aspect-ratio: var(--imageRatio);
        flex-grow: calc(var(--imageRatio));
      }
    }
    
    @include lg {
      margin-top: $space-10;
    }
  }

  .multi-image-block__image {
    border-radius: var(--rounded-2xl);
    max-width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .multi-image-block__text-wrapper {
    padding: $space-3;

    &.container_tightest {
      @include md {
        margin: 0;
      }
    }
  }
}
