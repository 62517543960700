/*---------------------------------------------*\
    # COMPONENTS - HERO WITH COVER VIDEO BLOCK
\*---------------------------------------------*/

.hero-with-cover-video-block {
  position: relative;
  overflow: hidden;

  @include sm {
    overflow: visible;
  }

  .decoration-first {
    border: $space-0-5 solid var(--white);
    top: -1025px;
    z-index: 10;
    width: 1730px;
    opacity: 0.6;
    @include calculateOffsetCenter(-913px, 1730px, 375px);

    @include sm {
      top: -1262px;
      width: 1730px;
      @include calculateOffsetCenter(-696px, 1730px, $screen-sm);
    }

    @include md {
      top: -1162px;
      width: 1730px;
      @include calculateOffsetCenter(-580px, 1730px, $screen-md);
    }

    @include lg {
      top: -1162px;
      width: 1730px;
      @include calculateOffsetCenter(-580px, 1730px, $screen-lg);
    }

    @include xl {
      top: -1020px;
      width: 1730px;
      @include calculateOffsetCenter(-314px, 1730px, $screen-xl);
    }

    @include twoXl {
      top: -792px;
      width: 1730px;
      @include calculateOffsetCenter(-40px, 1730px, $screen-2xl);
    }
  }

  .decoration-second {
    border: $space-1 solid var(--white);
    top: -2706px;
    z-index: 10;
    width: 3737px;
    opacity: 0.4;
    @include calculateOffsetCenter(-2658px, 3737px, 375px);

    @include sm {
      top: -2943px;
      width: 3737px;
      @include calculateOffsetCenter(-2441px, 3737px, $screen-sm);
    }

    @include md {
      top: -2844px;
      width: 3737px;
      @include calculateOffsetCenter(-2324px, 3737px, $screen-md);
    }

    @include lg {
      top: -2844px;
      width: 3737px;
      @include calculateOffsetCenter(-2324px, 3737px, $screen-lg);
    }

    @include xl {
      top: -2702px;
      width: 3737px;
      @include calculateOffsetCenter(-2059px, 3737px, $screen-xl);
    }

    @include twoXl {
      top: -2474px;
      width: 3737px;
      @include calculateOffsetCenter(-1785px, 3737px, $screen-2xl);
    }
  }

  &_circles-none {
    &:before {
      border-radius: 0;
      bottom: $space-32;
  
      @include sm {
        bottom: $space-28;
      }
  
      @include xl {
        bottom: $space-24;
      }
    }
  }

  &__visuals-container {
    @include sm {
      position: relative;
      overflow: hidden;
    }
  }

  &__text {
    position: relative;
    z-index: 20;
    margin: 0 auto;
    color: var(--white);
    text-align: center;
    text-shadow: 0px 2px 4px var(--black);
    pointer-events: none;
    padding-top: 30vh;

    @include lg {
      padding-top: 35vh;
    }
  }

  &__buttons-container {
    bottom: 2rem;
    position: absolute;
    width: 100%;
    z-index: 15;

    @include md {
      bottom: $space-20;
    }

    .container_normal {
      display: flex;
      flex-direction: column;
      gap: $space-3;
  
      @include lg {
        gap: $space-6;
      }
    }
  
    .hero-with-cover-video-block__scroll-down-button {
      cursor: pointer;
    }
  }

  &__top-buttons,
  &__bottom-buttons {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $space-3;
    justify-content: center;
    width: 100%;

    @include md {
      flex-direction: row;
      justify-content: space-between;
    }

    @include lg {
      gap: $space-6;
    }
  }

  &__button-wrapper {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: center;

    &:first-of-type {
      order: 1;

      @include md {
        justify-content: flex-start;
      }
    }

    &:last-of-type {
      order: 2;

      @include md {
        justify-content: flex-end;
      }
    }
    
    &:only-of-type {
      justify-content: center;
    }
  }

  &__pause-button,
  &__scroll-down-button {
    background-color: transparent;
    color: var(--text-white);
    border: $space-px solid var(--bg-white);
    border-radius: $rounded-lg;

    &:hover {
      color: var(--bg-white-hover);
      border: $space-px solid var(--bg-white-hover);
    }

    &:focus {
      outline: $space-px solid var(--bg-white);
      outline-offset: $space-2;
    }

    &:disabled,
    &:disabled:hover {
      border-color: var(--accent-disabled-light);
      color: var(--accent-disabled-light);
    }
  }
}

.cover-video {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.cover-video__video-wrapper {
  position: absolute;
  @include inset(0);
  z-index: 0;
  min-height: 100vh;
}

.cover-video__video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.cover-video__content {
  position: relative;
  z-index: 10;
}
