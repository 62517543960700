/*-----------------------------------------------------------*\
    # COMPONENTS - SCROLL LINKED BOUNCING IMAGE COMPOSITION
\*-----------------------------------------------------------*/

.scroll-linked-bouncing-image-composition {
}

.scroll-linked-bouncing-image-composition__container {
  position: relative;
}

.scroll-linked-bouncing-image-composition__image-wrapper {
  position: absolute;
  z-index: 20;
  overflow: hidden;
}

.scroll-linked-bouncing-image-composition__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: var(--rounded-full);
}
