/*------------------------------------*\
    # COMPONENTS - COLLAPSIBLE
\*------------------------------------*/

.collapsible {
}

.collapsible__header {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.collapsible__header-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.collapsible__header-content {
  flex: 1 1 0%;
}

.collapsible__body {
  display: block;
  @include py($space-1);
  margin-left: $space-6;
}

.collapsible__body.collapsible__body_closed {
  display: none;
}
