/*------------------------------------------*\
    # COMPONENTS - ACCORDION WITH SIDE IMAGE
\*------------------------------------------*/

.accordion-with-side-image-block {
  @include py(0);

  @include lg {
    @include py($space-15);
  }

  &__container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    @include lg {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  &__text-wrapper,
  &__image-wrapper {
    width: 100%;
  }

  &__image-wrapper {
    aspect-ratio: $aspect-video;
    overflow: hidden;
    order: -9999;

    @include lg {
      aspect-ratio: auto;
    }
  }

  &_image-left &__image-wrapper {
    @include lg {
      order: -9999;
      border-top-right-radius: var(--rounded-2xl);
      border-bottom-right-radius: var(--rounded-2xl);
    }
  }

  &_image-right &__image-wrapper {
    @include lg {
      order: 9999;
      border-top-left-radius: var(--rounded-2xl);
      border-bottom-left-radius: var(--rounded-2xl);
    }
  }

  &__text-wrapper {
    padding: $space-8;

    @include md {
      @include py($space-10);
      @include px($space-11);
    }

    @include lg {
      max-width: calc($max-width-7xl / 2);
      @include py($space-15);
    }
  }

  &_image-right &__text-wrapper {
    @include lg {
      justify-self: end;
      padding-left: $space-7;
      padding-right: $space-12;
    }
  }

  &_image-left &__text-wrapper {
    @include lg {
      justify-self: start;
      padding-left: $space-12;
      padding-right: $space-7;
    }
  }

  &__title{
    color: var(--main-text-color-dark);
  }

  &__subtitle {
    margin-top: $space-3;
    
    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: $space-5;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .btn-container {
    margin-top: $space-4;

    @include md {
      margin-top: $space-5;
    }

    @include lg {
      margin-top: $space-6;
    }
  }

  .accordion {
    margin-top: $space-8;
    
    @include md {
      margin-top: $space-10;
    }

    @include lg {
      margin-top: $space-12;
    }
  }
}
