/*------------------------------------*\
    # COMPONENTS - DECORATIONS
\*------------------------------------*/

.decoration-first {
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  position: absolute;
  z-index: -1;

  width: 1310px;
  top: 11px;
  @include calculateOffsetCenter(-477px, 877px, 320px);
  @include sm {
    top: -190px;
    width: 1469px;
    @include calculateOffsetCenter(-683px, 1469px, $screen-sm);
  }
  @include lg {
    width: 1470px;
    top: -80px;
    @include calculateOffsetCenter(-200px, 1470px, $screen-lg);
  }
}
.decoration-second {
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  position: absolute;
  z-index: -1;

  width: 2555px;
  top: -211px;
  @include calculateOffsetCenter(-874px, 899px, 320px);
  @include sm {
    top: -1337px;
    width: 2865px;
    @include calculateOffsetCenter(-1880px, 2865px, $screen-sm);
  }
  @include lg {
    width: 2865px;
    top: -1227px;
    @include calculateOffsetCenter(-400px, 2865px, $screen-lg);
  }
}
