/*------------------------------------*\
    # OBJECTS - LAYOUT
\*------------------------------------*/

/*
 * CONTAINERS (deals with layout of x-axis)
 */
.container_normal {// 1224px 
  @include px($space-6);// 24px
  @include mx-auto;

  @include lg {
    max-width: $max-width-7xl;// 1280px
    @include px($space-7);// 28px
  }
}

.container_tight {// 704px
  @include px($space-6); // 24px
  @include mx-auto;

  @include sm {
    @include px($space-8); // 32px
  }

  @include lg {
    max-width: $max-width-3xl;// 768px
  }
}

.container_tightest {// 808px
  @include px($space-8);// 32px
  @include mx-auto;

  @include sm {
    @include px($space-11);// 44px
  }

  @include lg {
    max-width: $max-width-4xl;// 896px
  }
}
