/*------------------------------------*\
    # COMPONENTS - MAIN MENU
\*------------------------------------*/

// TODO: Update styling when ThemeProvider will also hit Modal components

.main-menu {
}

.main-menu__modal-drawer {
  .modal-drawer__close-button {
    color: var(--white);
    padding: 0;

    .modal-drawer__close-icon {
      width: $space-6;
      height: $space-6;
    }
  }
}

.main-menu__modal-drawer_hide-close-button {
  .modal-drawer__close-button {
    display: none;

    @include xl {
      display: inline-block;
    }
  }
}

.main-menu__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding-top: $space-16;
  background-color: var(--accent-bg-dark);

  @include sm {
    padding-top: $space-20;
  }

  @include xl {
    padding-top: $space-24;
  }

  .main-menu__item,
  .language-switcher__button {
    @include px($space-6);
    @include sm {
      @include px($space-8);
    }

    @include xl {
      @include px($space-10);
    }
  }

  &:first-of-type {
    .main-menu__item {
      padding-left: $space-16;

      &:after {
        @include xl {
          width: calc(100% - 104px);
          transform: translateX(calc(-50% + 12px));
        }
      }
    }

    .main-menu__link-item-with-large-image {
      .main-menu__item-text-container {
        padding-left: $space-16;
      }
    }

    .main-menu__item:hover,
    .main-menu__link-group_active {
      background-color: var(--accent-900);
    }
  }

  &:nth-of-type(2) {
    background-color: var(--accent-900);

    .main-menu__item {
      &:after {
        background-color: var(--accent-800);
      }
    }

    .main-menu__item:hover,
    .main-menu__link-group_active {
      background-color: var(--accent-800);
    }
  }

  &:nth-of-type(3) {
    background-color: var(--accent-800);

    .main-menu__item {
      &:after {
        background-color: var(--accent-700);
      }
    }

    .main-menu__item:hover {
      background-color: var(--accent-900);
    }
  }
}

.main-menu__slide-back-button {
  position: absolute;
  top: $space-5;
  left: $space-6;
  display: inline-block;
  color: var(--white);
  background-color: transparent;
  border: none;
  cursor: pointer;

  @include sm {
    top: $space-8;
    left: $space-8;
  }

  @include xl {
    display: none;
  }
}

.main-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  gap: $space-4;
  @include py($space-5);
  width: 100%;
  color: var(--white);
  @include text-lg;
  line-height: 18px;
  font-family: var(--font-sans-heading);
  @include font-weight("bold");
  text-decoration: none;
  cursor: pointer;
  @include transition;

  &:hover {
    color: var(--text-white);

    .main-menu__item-icon {
      transform: translateX($space-1);
    }
  }

  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 50%;
    height: $space-px;
    width: calc(100% - 48px);
    background-color: var(--accent-900);
    transform: translateX(-50%);

    @include sm {
      width: calc(100% - 64px);
    }
    @include xl {
      width: calc(100% - 80px);
    }
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  @include sm {
    @include text-xl;
    line-height: $space-5;
  }

  .main-menu__item-icon {
    flex-shrink: 0;
  }
}

.main-menu__link-item {
  @extend .main-menu__item;
  @include py($space-6);
}

.main-menu__item-icon {
  @include transition;
}

.main-menu__link-group {
  @extend .main-menu__item;
  justify-content: space-between;
}

.main-menu__link-item-with-large-image {
  @extend .main-menu__item;
  position: relative;
  padding: 0 !important;
  margin-left: 0;

  &:first-child {
    @include xl {
      margin-top: -$space-24;
    }
  }

  .main-menu__item-image-wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: $aspect-video;
    overflow: hidden;
  }

  .main-menu__item-image {
    position: absolute;
    @include inset(0);
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .main-menu__item-text-container {
    position: absolute;
    @include inset(0);
    z-index: 10;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: $space-4;
    padding: $space-6;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 50%,
      #000 100%
    );

    @include xl {
      @include px($space-10);
    }
  }

  .main-menu__item-text {
    @include my(0);
    @include text-2xl;
    line-height: $space-6;
    letter-spacing: -0.24px;
    text-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    @include sm {
      @include text-3xl;
      letter-spacing: -0.28px;
    }

    @include xl {
      line-height: $space-7 !important;
    }
  }

  &:hover {
    .main-menu__item-text,
    .main-menu__item-icon {
      color: var(--text-white-hover);
    }
  }
}

.main-menu__link-item-with-small-image {
  @extend .main-menu__item;

  .main-menu__item-image-wrapper {
    width: $space-16;
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-md);
    overflow: hidden;
  }

  .main-menu__item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .main-menu__item-text-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $space-4;
    width: 100%;
  }
}

.main-menu__language-drawer-button {
  @extend .main-menu__item;
  display: flex;
  justify-content: space-between;
  background-color: transparent;
  border: none;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 50%;
    height: $space-px;
    width: calc(100% - 48px);
    background-color: var(--accent-900);
    transform: translateX(-50%);

    @include sm {
      width: calc(100% - 64px);
    }
    @include xl {
      width: calc(100% - 80px);
    }
  }
}

.main-menu__language-drawer-button-container {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.main-menu__language-drawer-button_active {
  background-color: var(--accent-900);
}

.main-menu__slide {
  .language-switcher__button {
    @extend .main-menu__item;
  }
}

.main-menu__proximity {
  width: $space-6;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: none;

  @include sm {
    width: $space-8;
    display: block;
  }

  @include xl {
    width: $space-10;
  }
}
