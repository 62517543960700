/*------------------------------------*\
    # COMPONENTS - STATIC FILTER
\*------------------------------------*/

.static-filter {
  display: flex;
  align-items: center;
  margin-bottom: $space-4;

  &__label {
    margin-right: $space-4;
  }

  &__option {
    margin-right: $space-8;
  }
}
