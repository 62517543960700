/*------------------------------------*\
    # COMPONENTS - COVER IMAGE
\*------------------------------------*/

.cover-image {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.cover-image__image-wrapper {
  position: absolute;
  @include inset(0);
  z-index: 0;
  min-height: 100vh;
}

.cover-image__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.cover-image__content {
  position: relative;
  z-index: 10;
}
