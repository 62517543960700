/*------------------------------------*\
    # COMPONENTS - FORM GROUP
\*------------------------------------*/

.form-group {
  position: relative;
  color: var(--gray-800);
  z-index: 10;
}

.form-group_error {
  color: var(--red-1000);

  .input {
    color: var(--red-1000);
    border-color: var(--red-1000);
  }

  .select {
    padding-right: $space-32;
    background-position: right $space-16 center;
  }
}

.form-group__input {
  position: relative;
  display: block;
  z-index: 10;
  padding-top: $space-1-5;

  & > .input:disabled {
    & + .form-group__label {
      display: none;
    }
  }
}

.form-group__checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox {
    display: none;
  }

  .checkbox-icon {
    color: var(--accent-1000);
  }
}

.form-group__label {
  position: absolute;
  z-index: 20;
  top: 0;
  left: $space-2;
  padding: $space-2;
  padding-top: 0;
  @include text-md;
  line-height: $space-2-5;
  @include font-weight("regular");
  background-color: var(--bg-white);

  .form-group__checkbox & {
    position: relative;
    padding: 0;
    padding-left: $space-2;
  }
}

.form-group__description {
  margin-top: $space-1;
  color: var(--gray-800);
}

.form-group__error {
  position: relative;
  z-index: 0;
  top: -$space-3;
  padding: $space-8 $space-6 $space-5 $space-6;
  color: var(--red-1000);
  background-color: rgba(237, 0, 0, 0.05);
  width: 100%;
  border-radius: 0 0 var(--rounded) var(--rounded);

  .form-group_checkbox & {
    top: 0;
  }
}

.form-group__error-icon {
  position: absolute;
  top: calc(
    $space-1-5 + $space-5
  ); // Padding-top .form-group__input + padding-top .input
  right: $space-6;
}
