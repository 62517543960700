/*------------------------------------*\
    # COMPONENTS - CAROUSEL CARD ITEM
\*------------------------------------*/

.carousel-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  position: relative;

  &:hover {
    .carousel-card__icon {
      transform: translate($space-1);
    }

    .carousel-card__link {
      background-color: var(--accent-bg-dark-hover);
    }
  }
}

.carousel-card__header {
  flex: 1;
  min-height: 1px;
  position: absolute;
  width: 100%;
  height: 100%;
}

.carousel-card__header-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel-card__body {
  flex-shrink: none;
  position: relative;
  padding-top: $space-6;
  padding-bottom: $space-4;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  display: flex;
  flex-direction: column;
  @include px($space-10);
  justify-content: center;
  align-items: center;
  text-align: center;

  @include md {
    min-height: $space-72;
  }
}

.carousel-card__content-container {
  display: contents;
}

.carousel-card__logo {
  display: none;
}

.carousel-card__title {
  @extend .heading-2;
  margin: 0;
  color: var(--text-white);
  line-height: $space-6;
  @include transition;
}

.carousel-card__subtitle {
  flex: 1 1 0%;
  @include font-weight("bold");
}

.carousel-card__link {
  margin-top: $space-4;
  display: flex;
  gap: $space-2;
  @include text-md;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: $space-2;
  line-height: 1;
  @include py($space-5);
  @include px($space-7);
  background-color: var(--accent-bg-dark);
  color: var(--text-white);
  border-radius: var(--rounded-lg);
  font-family: $font-sans-headings;
  @include text-xl;
  @include font-weight("bold");
  @include transition;
}

.carousel-card__icon {
  flex: none;
  color: var(--white);
  justify-self: flex-end;
  @include transition;
}
