/*------------------------------------*\
    # COMPONENTS - LEAD PARAGRAPH BLOCK
\*------------------------------------*/

.lead-paragraph-block {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @include py($space-10);

  @include sm {
    @include py($space-14);
  }
}

.lead-paragraph-block_center {
  .lead-paragraph-block__container {
    text-align: center;

    .btn-container {
      justify-content: center;
    }
  }
}
