/*------------------------------------*\
    # COMPONENTS - PRE HEADER MENU
\*------------------------------------*/

.pre-header-menu {
  display: flex;
  flex-direction: row;
  gap: $space-2-5;

  &__link-item {
    @include py($space-2);
    position: relative;
    text-decoration: none;

    &.active::after {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: $space-0-5;
      background-color: var(--accent-1000);
      display: block;
      content: '';
    }

    &-text {
      @extend .header__button-text;
      @include text-lg;
      display: block;
    }
  }
}
