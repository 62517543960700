/*--------------------------------------------------*\
    # COMPONENTS - SCROLL PAGE CAROUSEL COMPOSITION
\*--------------------------------------------------*/

.scroll-page-carousel-composition {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: $space-28;

  .heading-2 {
    color: var(--text-white);
  }

  .container_normal {
    width: 100%;
  }

  .carousel {
    aspect-ratio: auto;
    height: calc(
      100vh - 305px
    ); // The height is decreased to make space for the title to be included above the carousel
    pointer-events: auto;
    max-height: $space-96;

    @include sm {
      max-height: 690px;
      height: calc(100vh - 386px);
    }
  }
}

.scroll-page-carousel-composition__layer {
  max-height: 100vh;
}

.scroll-page-carousel-composition__title {
}

.scroll-page-carousel-composition__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-4;

  > * {
    aspect-ratio: $aspect-video;
  }
}
