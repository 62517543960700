/*------------------------------------*\
    # COMPONENTS - VARIANTS
\*------------------------------------*/

.variant_white {
  color: var(--text);
  background: var(--bg-white);

  .decoration-first,
  .decoration-second {
    display: none;
  }
}

.variant_light {
  color: var(--text);
  background: var(--main-bg-light);
}

.variant_dark {
  color: var(--text-white);
  background: var(--main-bg-dark);
}
