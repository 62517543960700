/*------------------------------------*\
    # COMPONENTS - FOOTER MENU
\*------------------------------------*/

.footer-menu {
  padding: $space-9 0 $space-8 0;
  color: var(--white);
  background-color: var(--main-bg-dark);
  overflow: hidden;

  .container_normal {
    display: flex;
    flex-direction: column;
    gap: $space-8;

    @include lg {
      flex-direction: row;
      gap: $space-6;
    }
  }

  @include sm {
    padding-bottom: $space-12;
  }

  @include lg {
    padding-top: $space-12;
  }
}

.footer-menu__logo {
  width: $space-40;
  height: $space-14;
  object-fit: contain;
}

.footer-menu__columns {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  column-gap: $space-3;
  row-gap: $space-10;
  flex: 1 1 0%;

  @include sm {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include md {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.footer-menu__column {
  display: flex;
  flex-direction: column;

  @include sm {
    width: 100%;
  }
}

.footer-menu__column-text {
  display: flex;
  flex-direction: column;
  gap: $space-2;
  margin-top: $space-4;
}

.footer-menu__link-item {
  font-family: var(--font-sans-heading);
  @include font-weight("medium");
  @include text-xl;
  line-height: 18px !important;
  color: var(--text-white);
  text-decoration: none;
  cursor: pointer;
  overflow-wrap: break-word;
  @include transition;

  &:hover {
    color: var(--text-white);
    text-decoration: underline;
  }
}

.footer-menu__logo-and-socials {
  display: flex;
  flex-direction: column;
  gap: $space-3;

  .socials__link {
    color: var(--text-white);

    &:hover {
      color: var(--text-white-hover);
    }
  }

  @include lg {
    gap: $space-4;
  }
}
