/*------------------------------------*\
    # COMPONENTS - DRAG SLIDER
\*------------------------------------*/

.drag-slider {
  cursor: pointer;

  &:active {
    cursor: grabbing;
  }
}

.drag-slider__buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: $space-4;
  margin-top: $space-8;

  @include md {
    justify-content: end;
    margin-top: $space-12;
  }
}

.drag-slider__button-icon {
  height: $space-6;
  width: $space-6;

  @include md {
    height: $space-10;
    width: $space-10;
  }
}

.drag-slider__button-icon_disabled {
  opacity: 0.2;
}

.drag-slider__example-slider {
  display: flex;
  flex-direction: row;
  width: max-content;
  gap: 40px;
}
