/*---------------------------------------------------*\
    # COMPONENTS - SCROLL APPEAR FADE IN COMPOSITION
\*---------------------------------------------------*/

.scroll-appear-fade-in-composition {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
