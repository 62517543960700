/*------------------------------------*\
    # COMPONENTS - RICH TEXT
\*------------------------------------*/

.rich-text {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $space-8;
    margin-bottom: $space-4;
  }

  h1 {
    @extend .heading-1;
  }
  h2 {
    @extend .heading-2;
  }
  h3 {
    @extend .heading-3;
  }
  h4 {
    @extend .heading-4;
  }
  h5 {
    @extend .heading-5;
  }
  h6 {
    @extend .heading-6;
  }

  p {
    @include my($space-4);
    text-decoration: none !important;

    span {
      text-decoration: none !important;
    }
  }

  ul,
  ol {
    @include my(0);
    padding-left: 0.9125em;
    text-align: left;
  }

  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child,
  p:first-child {
    margin-top: 0;
  }

  h1:last-child,
  h2:last-child,
  h3:last-child,
  h4:last-child,
  h5:last-child,
  h6:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}

.variant_dark,
.hero-with-carousel-block_dark,
.hero-with-image-block_dark,
.hero-with-embed-block_dark,
.hero-with-quicklinks-block_dark,
.hero-article-block_dark,
.hero-with-large-card-slider-block_dark,
.hero-block_dark {
  .rich-text {
    a {
      color: var(--text-white);

      &:hover {
        color: var(--text-white-hover);
      }
    }
  }
}

a {
  @include font-weight("bold");
  color: var(--accent-text-color-dark);
  cursor: pointer;

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.rich-text__title {
  @extend .heading-2;
}

.rich-text__intro {
  @include text-2xl;
  @include font-weight("medium");
  font-family: var(--font-sans-heading);
}

.variant_white,
.variant_light {
  .rich-text__title,
  .rich-text__intro {
    color: var(--main-text-color-dark);
  }
}

.variant_dark {
  .rich-text__title,
  .rich-text__intro {
    color: var(--text-white);
  }
}
