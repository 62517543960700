/*------------------------------------*\
    # COMPONENTS - HERO ARTICLE
\*------------------------------------*/
@use "sass:math";

.hero-article-block {
  overflow: hidden;
  position: relative;
  padding-bottom: $space-5;
  @include md {
    padding-bottom: $space-7;
  }
  @include xl {
    padding-bottom: $space-10;
    min-height: 796px;
  }
  color: var(--main-text-color-dark);

  .decoration-first {
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);
    z-index: 1;

    border: $space-0-5 solid var(--main-200);
    top: -1655px;
    width: 1880px;

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
    
    @include calculateOffsetCenter(-800px, 1880px, 320px);
    @include md {
      top: -1322px;
      width: 1880px;
      @include calculateOffsetCenter(-627px, 1880px, $screen-md);
    }
    @include xl {
      top: -1240px;
      @include calculateOffsetCenter(-390px, 1880px, $screen-xl);
    }
  }

  .decoration-second {
    display: none;
  }

  .breadcrumbs_desktop {
    @include px(0);
  }
}

.hero-article-block_light {
  background: none;
}

.hero-article-block_dark {
  background: none;

  .heading-1_serif,
  .heading-4,
  .hashtag,
  .hashtag:hover,
  a,
  a:hover {
    color: var(--text-white);
  }

  .decoration-first {
    border: $space-0-5 solid var(--main-800);
  }

  &:before {
    background-color: var(--main-bg-dark);
  }
}

.hero-article-block:before {
  content: "";
  position: absolute;
  z-index: -3;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);

  width: 1327px;
  top: -1065px;
  @include calculateOffsetCenter(-550px, 1327px, 320px);
  @include md {
    width: 2260px;
    top: -1675px;
    @include calculateOffsetCenter(-902px, 2260px, $screen-md);
  }
  @include xl {
    width: 2104px;
    top: -1450px;
    @include calculateOffsetCenter(-600px, 2104px, $screen-xl);
  }
}

.hero-article-block_light:before {
  background-color: var(--main-bg-light);
}

.hero-article-block_circles-none {
  min-height: initial;

  &:before {
    display: block;
    border-radius: var(--rounded-none);
    width: 100vw;
    top: 0;
    left: 0;
    position: absolute;
    height: 100%;
    transform: translateX(0);
  }

  .hero-article-block__image-wrapper {
    mask: none;
    left: 0;
    width: 100vw;
    transform: translateX(0);
    aspect-ratio: 21/9;

    @include lg {
      max-width: $screen-xl;
      margin: 0 auto;
      padding: 0 $space-7;
      border-radius: 0 0px var(--rounded-2xl) var(--rounded-2xl);
    }
  }
}

.hero-article-block__title {
  margin-top: $space-6;
  margin-bottom: $space-4;

  @include sm {
    margin-top: $space-8;
  }
  @include xl {
    margin-bottom: $space-6;
  }
}

.hero-article-block__image-wrapper {
  overflow: hidden;
  -webkit-mask-image: url("../../assets/image-masks/clipped-circle-mobile.svg");
  mask-image: url("../../assets/image-masks/clipped-circle-mobile.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  aspect-ratio: 2.7;
  margin-bottom: $space-5;
  width: 768px;
  left: 50%;
  transform: translateX(-50%);
  position: relative;

  @include md {
    margin-bottom: $space-8;
    -webkit-mask-image: url("../../assets/image-masks/clipped-circle-tablet.svg");
    mask-image: url("../../assets/image-masks/clipped-circle-tablet.svg");
    aspect-ratio: math.div(2.22, 1);
    width: 1280px;
    @include calculateOffsetCenter(-47px, 855px, $screen-md);
  }

  @include xl {
    margin-bottom: $space-14;
    -webkit-mask-image: url("../../assets/image-masks/clipped-circle-desktop.svg");
    mask-image: url("../../assets/image-masks/clipped-circle-desktop.svg");
    aspect-ratio: math.div(3.26, 1);
    top: 0px;
    width: 2104px;
    @include calculateOffsetCenter(50px, 855px, $screen-md);
  }
}

.hero-article-block__image {
  height: 100%;
  width: 100%;
  object-fit: cover;

  @include lg {
    border-radius: 0 0px var(--rounded-2xl) var(--rounded-2xl);
  }
}

.hero-article-block__container {
  margin-top: $space-4;
  padding-bottom: $space-10;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: $space-10;
  row-gap: $space-6;
}
.hero-article-block__hashtag-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: $space-3;
  row-gap: $space-2;
}

.hero-article-block__share-buttons-and-date-container {
  display: flex;
  flex-wrap: wrap;
  gap: $space-4;
}

.hero-article-block__share-buttons {
  color: var(--accent-text-color-dark);
}

.hero-article-block__location-and-date {
  color: var(--main-600);
}

.hero-article-block__date {
  display: inline-block;
}
