/*------------------------------------*\
    # COMPONENTS - PRE HEADER
\*------------------------------------*/

.pre-header {
  position: relative;
  background-color: var(--accent-100);
  @include px($space-4);

  @include md {
    @include px($space-8);
  }

  @include lg {
    @include px($space-16);
  }

  .container_normal {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
}
