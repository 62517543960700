///*------------------------------------*\
//    # TOOLS - SPACE
//\*------------------------------------*/

// Uses the same logic as https://tailwindcss.com/docs/space
// Used to assign the space between flex items

// Assigns a margin-left to a child following another child
@mixin space-x($margin) {
  & > * + * {
    margin-left: $margin;
  }
}

// Assigns a margin-top to a child following another child
@mixin space-y($margin) {
  & > * + * {
    margin-top: $margin;
  }
}
