/*------------------------------------*\
    # COMPONENTS - FILE DROPZONE
\*------------------------------------*/

.file-dropzone {
  height: 5rem;
  max-width: 100%;
  position: relative;
}

.file-dropzone__input {
  display: none;
}

.file-dropzone__area {
  display: flex;
  padding: 0 $space-4;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  border-width: 2px;
  border-style: dashed;
  border-color: var(--main-border-dark);
  background-color: var(--main-bg-light);
}

.file-dropzone__area:hover {
  background-color: var(--white);
}

.file-dropzone__indicator {
  position: absolute;
  @include inset($space-2);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-300);
}
