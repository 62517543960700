/*------------------------------------*\
    # OBJECTS - ASPECT RATIOS
\*------------------------------------*/

.aspect-video {
  aspect-ratio: $aspect-video;
  object-fit: cover;
}
.aspect-regular {
  aspect-ratio: $aspect-regular;
  object-fit: cover;
}
.aspect-square {
  aspect-ratio: $aspect-square;
  object-fit: cover;
}
