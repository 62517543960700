/*---------------------------------------------*\
    # COMPONENTS - HERO WITH OVERVIEW BLOCK
\*---------------------------------------------*/

.hero-with-overview-block {
  position: relative;
  @include py($space-8);
  overflow: hidden;

  &__title {
    color: var(--main-text-color-dark);
    line-height: $space-14;
  }

  .select {
    height: max-content;

    @include sm {
      max-width: $space-64;
    }
  }

  @include lg {
    @include py($space-14);
  }

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    aspect-ratio: 1/1;
    border-radius: var(--rounded-full);
    background-color: var(--main-bg-light);

    $backgroundWidth: 3116px;
    width: $backgroundWidth;
    bottom: $space-3;
    @include calculateOffsetCenter(-974px, $backgroundWidth, 320px);

    @include sm {
      bottom: $space-8;
      @include calculateOffsetCenter(-974px, $backgroundWidth, $screen-sm);
    }

    @include md {
      bottom: $space-28;
      @include calculateOffsetCenter(-974px, $backgroundWidth, $screen-md);
    }

    @include xl {
      bottom: $space-44;
      @include calculateOffsetCenter(-974px, $backgroundWidth, $screen-xl);
    }

    @include twoXl {
      bottom: $space-28;
      @include calculateOffsetCenter(-1028px, $backgroundWidth, $screen-2xl);
    }
  }

  .decoration-first {
    position: absolute;
    z-index: -1;
    aspect-ratio: 1/1;
    border-radius: var(--rounded-full);
    border: $space-0-5 solid var(--main-100);

    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }

    width: 970px;
    top: $space-56;
    @include calculateOffsetCenter($space-10, 970px, 320px);

    @include sm {
      width: 1908px;
      top: -$space-64;
      @include calculateOffsetCenter(-$space-10, 1908px, $screen-sm);
    }

    @include md {
      top: -$space-40;
      @include calculateOffsetCenter(-$space-40, 1908px, $screen-md);
    }

    @include xl {
      top: -1219px;
      @include calculateOffsetCenter($space-7, 1908px, $screen-xl);
    }

    @include twoXl {
      @include calculateOffsetCenter($space-32, 1908px, $screen-2xl);
    }
  }

  .decoration-second {
    position: absolute;
    z-index: -1;
    aspect-ratio: 1/1;
    border-radius: var(--rounded-full);
    border: $space-0-5 solid var(--main-400);

    width: 970px;
    top: $space-40;
    @include calculateOffsetCenter($space-2, 970px, 320px);

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }

    @include sm {
      width: 1908px;
      top: -$space-64;
      @include calculateOffsetCenter(-$space-28, 1908px, $screen-sm);
    }

    @include md {
      top: -$space-40;
      @include calculateOffsetCenter(-$space-24, 1908px, $screen-md);
    }

    @include xl {
      top: -1219px;
      @include calculateOffsetCenter(-$space-10, 1908px, $screen-xl);
    }

    @include twoXl {
      @include calculateOffsetCenter($space-14, 1908px, $screen-2xl);
    }
  }
}

.hero-with-overview-block_dark {
  .heading-1 {
    color: var(--text-white);
  }

  .select {
    border-color: var(--main-500);
  }

  &:before {
    background-color: var(--main-bg-dark);
  }
}

.hero-with-overview-block_circles-none {
  &:before {
    border-radius: var(--rounded-none);
    bottom: $space-32;

    @include sm {
      bottom: $space-28;
    }

    @include lg {
      bottom: $space-24;
    }
  }
}

.hero-with-overview-block__container {
  display: flex;
  flex-direction: column;
  gap: $space-8;
  margin: 0 auto;

  @include md {
    gap: $space-10;
  }
}

.hero-with-overview-block__text-and-select {
  display: flex;
  flex-direction: column;
  gap: $space-4;
  width: 100%;

  @include sm {
    flex-direction: row;
    justify-content: space-between;
  }
}

.hero-with-overview-block__grid {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: $space-4;
  margin: 0 auto;
  @include px($space-4);

  @include sm {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    > *:first-child .image-card {
      grid-column: 1 / span 2;
      min-height: 348px;
    }
    > *:not(:first-child) .image-card {
      .image-card__header {
        @include sm {
          max-height: $space-28;
        }

        @include md {
          max-height: $space-36;
        }

        @include lg {
          max-height: $space-44;
        }
      }
    }
  }

  @include md {
    @include px($space-6);
    gap: $space-5;
  }
  
  @include lg {
    max-width: $max-width-4xl;
    @include px($space-3);
  }
}
