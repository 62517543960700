/*------------------------------------*\
    # COMPONENTS - SUBMENU COLLAPSIBLE
\*------------------------------------*/

.submenu-collapsible {
  font-family: var(--font-sans-heading);
  @include font-weight("bold");
  background-color: var(--accent-bg-dark);

  .collapsible__header-icon {
    display: none;
  }
}

.submenu-collapsible__header {
  color: var(--text-white);
  padding: $space-3;
  @include text-lg;
  line-height: 18px;
  @include transition;

  .collapsible__header-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-2;
  }

  &:hover {
    color: var(--text-white-hover);
  }
}

.submenu-collapsible__body {
  display: flex;
  flex-direction: column;
  padding: $space-8;
  padding-top: $space-5;
  margin-left: 0px;
}
