/*------------------------------------*\
    # COMPONENTS - HERO LINK CONTAINER
\*------------------------------------*/

.hero-link-container {
  display: flex;
  gap: $space-7;

  .hero-link__item__icon {
    display: none;
  }
}
