/*------------------------------------*\
    # COMPONENTS - REVIEW SLIDER
\*------------------------------------*/

.review-slider-block {
  &.page-block-wrapper_light .quote-block,
  &.page-block-wrapper_dark .quote-block {
    background: var(--white);
  }

  &.page-block-wrapper_white .quote-block {
    background: var(--main-bg-light);
  }

  &__container {
    @extend .container_tightest;
    @include px($space-4);
  
    @include sm {
      @include px($space-6);
    }
  
    @include lg {
      @include px($space-3);
    }
  }

  &__title {
    margin-bottom: $space-10;
    color: var(--main-text-color-dark);
    @include px($space-4);

    @include md {
      @include px($space-5);
    }

    @include lg {
      @include px($space-8);
    }
  }

  &__reel {
    gap: 0;
    align-items: stretch;

    & > div {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
      display: flex;
      align-items: stretch;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
  }

  .quote-block {
    width: 100%;
    border-radius: var(--rounded-2xl);
    @include py($space-10);
    @include px($space-4);

    @include md {
      @include px($space-5);
    }

    @include lg {
      @include px($space-8);
    }

    .container_tightest {
      height: 100%;
      max-width: none;
      margin: 0;
      padding: 0;
    }

    .decoration-first,
    .decoration-second,
    &__decoration,
    &__decoration-image {
      display: none;
    }

    .quote {
      margin: 0;
      padding: 0;
      min-height: initial;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__text {
        @extend .bodytext;
        color: var(--text);
        margin-bottom: $space-2;
      }

      &__author {
        margin-top: 0;
        margin-bottom: $space-1;
      }

      &__author,
      &__subtitle {
        color: var(--main-text-color-dark) !important;
      }
    }
  }

  .reel__navigation {
    margin-top: $space-6;
  }
}
