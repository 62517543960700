///*------------------------------------*\
//    # SETTINGS - ROUNDED
//\*------------------------------------*/

$rounded-none: 0;
$rounded-sm: 0.125rem; // 2px - not used anywhere currently
$rounded: 0.25rem; // 4px
$rounded-md: 0.375rem; // 6px
$rounded-lg: 0.5rem; // 8px
$rounded-xl: 0.75rem; // 12px
$rounded-2xl: 1rem; // 16px
$rounded-3xl: 1.5rem; // 24px
$rounded-full: 9999px;

.rounded_normal {
  --rounded-none: #{$rounded-none};
  --rounded-sm: #{$rounded-sm};
  --rounded: #{$rounded};
  --rounded-md: #{$rounded-md};
  --rounded-lg: #{$rounded-lg};
  --rounded-xl: #{$rounded-xl};
  --rounded-2xl: #{$rounded-2xl};
  --rounded-3xl: #{$rounded-3xl};
  --rounded-full: #{$rounded-full};
}

.rounded_none {
  --rounded-none: #{$rounded-none};
  --rounded-sm: #{$rounded-none};
  --rounded: #{$rounded-none};
  --rounded-md: #{$rounded-none};
  --rounded-lg: #{$rounded-none};
  --rounded-xl: #{$rounded-none};
  --rounded-2xl: #{$rounded-none};
  --rounded-3xl: #{$rounded-none};
  --rounded-full: #{$rounded-full};
}
