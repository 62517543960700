/*------------------------------------*\
    # COMPONENTS - CAROUSEL BLOCK
\*------------------------------------*/
.carousel-block {
  .heading-2 {
    color: var(--main-text-color-dark);
  }
}

.carousel-block__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-4;

  > * {
    aspect-ratio: $aspect-video;
  }
}
