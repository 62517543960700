/*------------------------------------*\
    # OBJECTS - ORDER
\*------------------------------------*/

.order-first {
  order: -1;
}

.sm\: {
  @include sm {
    &order-first {
      order: -1;
    }
  }
}

.md\: {
  @include md {
    &order-first {
      order: -1;
    }
  }
}

.lg\: {
  @include lg {
    &order-first {
      order: -1;
    }
  }
}

.xl\: {
  @include xl {
    &order-first {
      order: -1;
    }
  }
}

.order-last {
  order: 9999;
}

.sm\: {
  @include sm {
    &order-last {
      order: 9999;
    }
  }
}

.md\: {
  @include md {
    &order-last {
      order: 9999;
    }
  }
}

.lg\: {
  @include lg {
    &order-last {
      order: 9999;
    }
  }
}

.xl\: {
  @include xl {
    &order-last {
      order: 9999;
    }
  }
}
