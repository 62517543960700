/*------------------------------------*\
    # COMPONENTS - QUOTE
\*------------------------------------*/
@use "sass:math";

$quote-decoration-height: 305px;
$quote-decoration-aspect-ratio: math.div(392, 305);

.quote-block {
  .container_tightest {
    position: relative;
  }
  .quote {
    @include lg {
      padding-bottom: $space-6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: $quote-decoration-height;
      margin-left: 208px;
    }
  }
}

.variant_white,
.variant_light {
  .quote__text {
    color: var(--main-text-color-dark);
  }
  .quote__author {
    color: var(--text);
  }
  .quote-block__decoration {
    color: var(--main-100);
  }
}

.variant_dark {
  .quote-block__decoration {
    color: var(--main-900);
  }
}

.quote-block__decoration {
  height: $space-20;
  width: auto;
  margin-bottom: $space-6;

  @include lg {
    margin-bottom: 0;
    height: $quote-decoration-height;
    position: absolute;
    z-index: -1;
    bottom: 0;
  }
}

.quote-block__decoration-image {
  object-fit: cover;
  -webkit-mask-image: url("../../assets/image-masks/quote.svg");
  mask-image: url("../../assets/image-masks/quote.svg");
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;

  @extend .quote-block__decoration;
  aspect-ratio: $quote-decoration-aspect-ratio;
}
