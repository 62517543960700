/*------------------------------------*\
    # OBJECTS - SPACERS
\*------------------------------------*/
/*
 * MARGIN TOP
 */

.mt-1 {
  margin-top: $space-1;
}
.mt-2 {
  margin-top: $space-2;
}
.mt-3 {
  margin-top: $space-3;
}
.mt-4 {
  margin-top: $space-4;
}
.mt-5 {
  margin-top: $space-5;
}
.mt-6 {
  margin-top: $space-6;
}
.mt-7 {
  margin-top: $space-7;
}
.mt-8 {
  margin-top: $space-8;
}
.mt-9 {
  margin-top: $space-9;
}
.mt-10 {
  margin-top: $space-10;
}
.mt-12 {
  margin-top: $space-12;
}
.mt-14 {
  margin-top: $space-14;
}
.mt-16 {
  margin-top: $space-16;
}
.mt-18 {
  margin-top: $space-18;
}
.mt-20 {
  margin-top: $space-20;
}
.mt-22 {
  margin-top: $space-22;
}
.mt-24 {
  margin-top: $space-24;
}

.sm\: {
  @include sm {
    &mt-1 {
      margin-top: $space-1;
    }
    &mt-2 {
      margin-top: $space-2;
    }
    &mt-3 {
      margin-top: $space-3;
    }
    &mt-4 {
      margin-top: $space-4;
    }
    &mt-5 {
      margin-top: $space-5;
    }
    &mt-6 {
      margin-top: $space-6;
    }
    &mt-7 {
      margin-top: $space-7;
    }
    &mt-8 {
      margin-top: $space-8;
    }
    &mt-9 {
      margin-top: $space-9;
    }
    &mt-10 {
      margin-top: $space-10;
    }
    &mt-12 {
      margin-top: $space-12;
    }
    &mt-14 {
      margin-top: $space-14;
    }
    &mt-16 {
      margin-top: $space-16;
    }
    &mt-18 {
      margin-top: $space-18;
    }
    &mt-20 {
      margin-top: $space-20;
    }
  }
}
.xl\: {
  @include xl {
    &mt-1 {
      margin-top: $space-1;
    }
    &mt-2 {
      margin-top: $space-2;
    }
    &mt-3 {
      margin-top: $space-3;
    }
    &mt-4 {
      margin-top: $space-4;
    }
    &mt-5 {
      margin-top: $space-5;
    }
    &mt-6 {
      margin-top: $space-6;
    }
    &mt-7 {
      margin-top: $space-7;
    }
    &mt-8 {
      margin-top: $space-8;
    }
    &mt-9 {
      margin-top: $space-9;
    }
    &mt-10 {
      margin-top: $space-10;
    }
    &mt-12 {
      margin-top: $space-12;
    }
    &mt-14 {
      margin-top: $space-14;
    }
    &mt-16 {
      margin-top: $space-16;
    }
    &mt-18 {
      margin-top: $space-18;
    }
    &mt-20 {
      margin-top: $space-20;
    }
    &mt-22 {
      margin-top: $space-22;
    }
    &mt-24 {
      margin-top: $space-24;
    }
  }
}

/*
 * MARGIN BOTTOM
 */

.mb-1 {
  margin-bottom: $space-1;
}
.mb-2 {
  margin-bottom: $space-2;
}
.mb-3 {
  margin-bottom: $space-3;
}
.mb-4 {
  margin-bottom: $space-4;
}
.mb-5 {
  margin-bottom: $space-5;
}
.mb-6 {
  margin-bottom: $space-6;
}
.mb-7 {
  margin-bottom: $space-7;
}
.mb-8 {
  margin-bottom: $space-8;
}
.mb-9 {
  margin-bottom: $space-9;
}
.mb-10 {
  margin-bottom: $space-10;
}
.mb-12 {
  margin-bottom: $space-12;
}
.mb-14 {
  margin-bottom: $space-14;
}
.mb-16 {
  margin-bottom: $space-16;
}
.mb-18 {
  margin-bottom: $space-18;
}
.mb-20 {
  margin-bottom: $space-20;
}
.mb-22 {
  margin-bottom: $space-22;
}

.sm\: {
  @include sm {
    &mb-1 {
      margin-bottom: $space-1;
    }
    &mb-2 {
      margin-bottom: $space-2;
    }
    &mb-3 {
      margin-bottom: $space-3;
    }
    &mb-4 {
      margin-bottom: $space-4;
    }
    &mb-5 {
      margin-bottom: $space-5;
    }
    &mb-6 {
      margin-bottom: $space-6;
    }
    &mb-7 {
      margin-bottom: $space-7;
    }
    &mb-8 {
      margin-bottom: $space-8;
    }
    &mb-9 {
      margin-bottom: $space-9;
    }
    &mb-10 {
      margin-bottom: $space-10;
    }
    &mb-12 {
      margin-bottom: $space-12;
    }
    &mb-14 {
      margin-bottom: $space-14;
    }
    &mb-16 {
      margin-bottom: $space-16;
    }
    &mb-18 {
      margin-bottom: $space-18;
    }
    &mb-20 {
      margin-bottom: $space-20;
    }
  }
}
.xl\: {
  @include xl {
    &mb-1 {
      margin-bottom: $space-1;
    }
    &mb-2 {
      margin-bottom: $space-2;
    }
    &mb-3 {
      margin-bottom: $space-3;
    }
    &mb-4 {
      margin-bottom: $space-4;
    }
    &mb-5 {
      margin-bottom: $space-5;
    }
    &mb-6 {
      margin-bottom: $space-6;
    }
    &mb-7 {
      margin-bottom: $space-7;
    }
    &mb-8 {
      margin-bottom: $space-8;
    }
    &mb-9 {
      margin-bottom: $space-9;
    }
    &mb-10 {
      margin-bottom: $space-10;
    }
    &mb-12 {
      margin-bottom: $space-12;
    }
    &mb-14 {
      margin-bottom: $space-14;
    }
    &mb-16 {
      margin-bottom: $space-16;
    }
    &mb-18 {
      margin-bottom: $space-18;
    }
    &mb-20 {
      margin-bottom: $space-20;
    }
    &mb-22 {
      margin-bottom: $space-22;
    }
  }
}
