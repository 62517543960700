/*------------------------------------*\
    # OBJECTS - Example
\*------------------------------------*/

.example-container {
  display: flex;
  align-items: center;
  justify-content: center;
  @include text-sm;
  background-color: #f1f1f1;
  border: 2px dashed #61dafb;
}

.example-button {
  all: unset;
  @include py($space-1);
  @include px($space-3);
  font-weight: 600;
  border-radius: 8px;
  border: 2px hotpink solid;
  color: hotpink;
}
