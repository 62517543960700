/*------------------------------------*\
    # COMPONENTS - SUB MENU
\*------------------------------------*/

.sub-menu {
  display: flex;
  @include py($space-3);
  @include px($space-6);
  width: 100%;
  background-color: var(--accent-bg-dark);

  @include xl {
    @include py(0);
    justify-content: center;
  }
}

.sub-menu__links {
  display: none;

  @include xl {
    display: block;
  }
}

.sub-menu__link-item {
  @include py($space-6);
  color: var(--accent-text-color-light);
  @include text-lg;
  line-height: 18px;
  text-decoration: none;
  cursor: pointer;
}

.sub-menu__link-item_active {
  color: var(--white);
  padding-bottom: 22px;
  border-bottom: $space-0-5 solid var(--white);
}

.sub-menu__button {
  display: inline-flex;
  align-items: center;
  gap: $space-2;
  padding: 0;
  line-height: 18px;
  color: var(--white);
  background-color: transparent;
  border: none;
  cursor: pointer;

  @include xl {
    display: none;
  }
}

.sub-menu__button-icon {
}
