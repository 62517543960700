/*-----------------------------------------------------------*\
    # COMPONENTS - SCROLL LINKED OVERLAY IMAGES COMPOSITION
\*-----------------------------------------------------------*/

.scroll-linked-overlay-images-composition {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-linked-overlay-images-composition__text {
  z-index: 10;
  @include inset(0);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.scroll-linked-overlay-images-composition__title {
  @include md {
    padding-top: $space-3;
  }
}

.scroll-linked-overlay-images-composition__images {
  position: absolute;
  width: 100%;
  height: 100vh;
}

.scroll-linked-overlay-images-composition__image-container {
  position: absolute;
  z-index: -1;
  pointer-events: auto;
}

.scroll-linked-overlay-images-composition__image-wrapper {
  position: absolute;
  z-index: -1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  border-radius: var(--rounded-full);
  transform-origin: center;
  transition-property: outline-color, scale;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  &:hover {
    scale: 1.05;
    outline-width: 1px;
    outline-style: solid;
    outline-color: var(--accent-border-dark);
  }
}

.scroll-linked-overlay-images-composition__image,
.scroll-linked-overlay-images-composition__image-wrapper .sc-image-wrapper {
  width: 70.7%; // sin(atan(1/1)) to make a square fit in a circle
  height: 70.7%;
  object-fit: contain;
}
