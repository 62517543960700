///*------------------------------------*\
//    # SETTINGS - SPACERS
//\*------------------------------------*/

$space-px: 1px;
$space-0-5: 0.125rem; // 2px
$space-1: 0.25rem; // 4px
$space-1-5: 0.375rem; // 6px
$space-2: 0.5rem; // 8px
$space-2-5: 0.625rem; // 10px
$space-3: 0.75rem; // 12px
$space-3-5: 0.875rem; // 14px
$space-4: 1rem; // 16px
$space-5: 1.25rem; // 20px
$space-5-5: 1.375rem; // 22px
$space-6: 1.5rem; // 24px
$space-7: 1.75rem; // 28px
$space-8: 2rem; // 32px
$space-9: 2.25rem; // 36px
$space-10: 2.5rem; // 40px
$space-11: 2.75rem; // 44px
$space-12: 3rem; // 48px
$space-14: 3.5rem; // 56px
$space-15: 3.75rem; // 60px
$space-16: 4rem; // 64px
$space-17: 4.25rem; // 68px
$space-18: 4.5rem; // 72px
$space-20: 5rem; // 80px
$space-22: 5.5rem; // 88px
$space-24: 6rem; // 96px
$space-26: 6.5rem; // 104px
$space-28: 7rem; // 112px
$space-30: 7.5rem; // 120px
$space-32: 8rem; // 128px
$space-34: 8.5rem; // 136px
$space-36: 9rem; // 144px
$space-38: 9.5rem; // 152px
$space-40: 10rem; // 160px
$space-42: 10.5rem; // 168px
$space-44: 11rem; // 176px
$space-48: 12rem; // 192px
$space-49: 12.5rem; // 200px
$space-52: 13rem; // 208px
$space-56: 14rem; // 224px
$space-60: 15rem; // 240px
$space-64: 16rem; // 256px
$space-68: 17rem; // 272px
$space-72: 18rem; // 288px
$space-80: 20rem; // 320px
$space-96: 24rem; // 384px
$space-98: 24.5rem; // 392px
