/*------------------------------------*\
    # COMPONENTS - IMAGE CARD
\*------------------------------------*/

.image-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  container-type: inline-size;
  position: relative;

  &:hover {
    .image-card__icon {
      transform: translate($space-1);
    }
  }
}

.image-card__header {
  flex: 1;
  min-height: 1px;
}

.image-card__header-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.image-card__body {
  flex-shrink: none;
  position: relative;
  padding-top: $space-6;
  padding-bottom: $space-4;
  @include px($space-4);
}

.image-card__logo {
  position: absolute;
  overflow: hidden;
  top: $space-9;
  right: $space-6;
  transform: translateY(-100%);
  height: $space-22;
  width: $space-22;
  padding: $space-2;
  border-radius: var(--rounded-xl);
  background-color: var(--bg-white);
  object-fit: contain;
}

.image-card__title {
  @extend .heading-3;
  margin: 0;
  color: var(--text-white);
  line-height: $space-6;
  @include transition;
}

.variant_dark .image-card .image-card__title {
  color: var(--accent-text-color-dark) !important;
}

.image-card__subtitle {
  flex: 1 1 0%;
  @include font-weight("regular");
}

.image-card__link {
  margin-top: $space-4;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: $space-2;
  color: var(--text-white);
  @include text-md;
  line-height: $space-6;
}

.image-card__icon {
  flex: none;
  color: var(--white);
  justify-self: flex-end;
  @include transition;
}

.variant_dark {
  .image-card__title,
  .image-card__icon {
    color: var(--accent-text-color-dark);
  }
  .image-card__link {
    color: var(--text);

    > .image-card__icon {
      color: var(--text);
    }
  }

  .image-card {
    &:hover {
      .image-card__title,
      .image-card__icon {
        color: var(--accent-text-color-dark-hover);
      }
    }
  }
}

@mixin image-card__header-default-style {
  position: absolute;
  width: 100%;
  height: 100%;
}

@mixin image-card__body-default-style {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
  min-height: $space-72;
  display: flex;
  flex-direction: column;
  @include px($space-10);
}

@container (min-width: #{$max-width-xl}) {//576px
  .image-card_default {
    .image-card__header {
      @include image-card__header-default-style;
    }

    .image-card__body {
      @include image-card__body-default-style;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .image-card__content-container {
      display: contents;
    }

    .image-card__link {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      column-gap: $space-2;
      line-height: 1;
      @include py($space-5);
      @include px($space-7);
      background-color: var(--accent-bg-dark);
      color: var(--text-white);
      border-radius: var(--rounded-lg);
      font-family: var(--font-sans-heading);
      @include text-xl;
      @include font-weight("bold");
      @include transition;
    }

    .image-card__subtitle {
      @include font-weight("bold");
    }
    .image-card__logo {
      display: none;
    }

    &:hover {
      .image-card__link {
        background-color: var(--accent-bg-dark-hover);
      }
    }
  }
}

.image-card_body-position-bottom-right {
  .image-card__header-image {
    max-height: $space-36;

    @include sm {
      max-height: $space-64;
    }
    @include md {
      max-height: $space-80;
    }
  }

  .image-card__body {
    padding: $space-4;
    padding-top: $space-6;

    @include sm {
      padding: $space-5;
      padding-top: $space-8;
    }
  }
}

@container (min-width: #{$max-width-3xl}) {//768px
  .image-card_body-position-bottom-right {
    .image-card__header {
      @include image-card__header-default-style;
    }

    .image-card__header-image {
      max-height: none;
    }

    .image-card__body {
      @include image-card__body-default-style;
      justify-content: flex-end;
      align-items: flex-end;
      padding-top: $space-24;
    }

    .image-card__title {
      line-height: $space-7 !important;
    }

    .image-card__content-container {
      background-color: var(--accent-bg-dark);
      margin-bottom: -$space-8;
      margin-right: -$space-10;
      border-top-left-radius: var(--rounded-2xl);
      padding-top: $space-10;
      padding-bottom: $space-8;
      @include px($space-8);
      max-width: 340px;
      width: 100%;
      @include transition;
    }

    .image-card__logo {
      display: none;
    }

    &:hover {
      .image-card__content-container {
        background-color: var(--accent-bg-dark-hover);
      }
    }
  }

  .variant_dark {
    .image-card_body-position-bottom-right {
      .image-card__content-container {
        background-color: var(--bg-white);
      }

      .image-card__title {
        color: var(--accentTextDark);
      }

      .image-card__link {
        color: var(--text) !important;
      }
    }
  }
}
