/*-------------------------*\
    # COMPONENTS - SELECT
\*-------------------------*/

.select {
  @extend .input;
  padding: $space-5 $space-12 $space-5 $space-6;
  line-height: $space-6;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9.63647L12 16.6365L5 9.63647' stroke='%23787A00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-position: right $space-6 center;
  background-repeat: no-repeat;
  background-size: $space-6 $space-6;
  appearance: none;

  &:disabled {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 9L12 16L5 9' stroke='%23B3B3B3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.select__option {
  color: var(--gray-800);
}
