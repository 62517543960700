/*----------------------------------------------------*\
    # COMPONENTS - SCROLL PAGE BACKGROUND COMPOSITION
\*----------------------------------------------------*/

.scroll-page-background-composition {
  position: relative;
  z-index: 0;
  width: 100%;
}

.scroll-page-background-composition__background-container {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.scroll-page-background-composition__video-container {
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
}

.scroll-page-background-composition__hero-decorations {
  position: absolute;
  width: 100%;
  height: 100%;

  .scroll-page-background-composition__hero-decorations-container {
    position: absolute;
    left: 50%;
    top: 50%;

    .scroll-page-background-composition__decoration-first {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-65%, -70%);
      border-radius: var(--rounded-full);
      width: 2370px;
      aspect-ratio: $aspect-square;
      border: $space-0-5 solid var(--bg-white);
      opacity: 0.8;
    }

    .scroll-page-background-composition__decoration-second {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-58%, -90.5%);
      border-radius: var(--rounded-full);
      width: 4880px;
      aspect-ratio: $aspect-square;
      border: $space-1 solid var(--bg-white);
      opacity: 0.2;
    }

    .scroll-page-background-composition__decoration-first,
    .scroll-page-background-composition__decoration-second {
      pointer-events: none;
    }
  }
}

.scroll-page-background-composition__first-decorations {
  position: absolute;
  z-index: 0;
  @include inset(0);
  height: 100vh;
  overflow: hidden;

  .scroll-page-background-composition__first-decorations-container {
    position: absolute;
    left: 50%;
    top: 50%;

    .scroll-page-background-composition__decoration-first {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--rounded-full);
      width: 97vmax;
      aspect-ratio: $aspect-square;
      border: $space-0-5 solid var(--bg-white);
      opacity: 0.8;
    }

    .scroll-page-background-composition__decoration-second {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: var(--rounded-full);
      width: 152vmax;
      aspect-ratio: $aspect-square;
      border: $space-1 solid var(--bg-white);
      opacity: 0.2;
    }

    .scroll-page-background-composition__decoration-first,
    .scroll-page-background-composition__decoration-second {
      pointer-events: none;
    }
  }
}

.scroll-page-background-composition__second-decorations {
  position: absolute;
  z-index: 10;
  @include inset(0);
  height: 100vh;
  overflow: hidden;

  .scroll-page-background-composition__second-decorations-container {
    position: absolute;
    left: 50%;
    top: 50%;

    .scroll-page-background-composition__decoration-first {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-40%, -50%);
      border-radius: var(--rounded-full);
      aspect-ratio: $aspect-square;
      border: $space-0-5 solid var(--main-bg-dark);
      opacity: 0.8;
    }

    .scroll-page-background-composition__decoration-second {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-55%, -50%);
      border-radius: var(--rounded-full);
      aspect-ratio: $aspect-square;
      border: $space-1 solid var(--main-bg-dark);
      opacity: 0.2;
    }

    .scroll-page-background-composition__second-decoration-first,
    .scroll-page-background-composition__second-decoration-second {
      pointer-events: none;
    }
  }
}

.scroll-page-background-composition__third-decorations {
  position: absolute;
  z-index: 11;
  @include inset(0);
  height: 100vh;
  overflow: hidden;

  .scroll-page-background-composition__third-decorations-container {
    position: absolute;
    left: 50%;
    top: 50%;

    .scroll-page-background-composition__decoration-first,
    .scroll-page-background-composition__decoration-second,
    .scroll-page-background-composition__decoration-third {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: var(--rounded-full);
      aspect-ratio: $aspect-square;
      pointer-events: none;
    }

    .scroll-page-background-composition__decoration-first {
      transform: translate(-40%, -50%);
      border: $space-0-5 solid var(--bg-white);
      opacity: 0.8;
    }

    .scroll-page-background-composition__decoration-second {
      transform: translate(-50%, -50%);
      border: $space-1 solid var(--bg-white);
      opacity: 0.2;
    }

    .scroll-page-background-composition__decoration-third {
      transform: translate(-70%, -50%);
      border: $space-0-5 solid var(--bg-white);
      opacity: 0.8;
    }
  }
}

.scroll-page-background-composition__video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.scroll-page-background-composition__buttons-container {
  position: absolute;
  bottom: $space-8;
  padding-right: $space-8;
  padding-left: $space-8;
  width: 100%;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $space-6;

  @include md {
    flex-direction: row;
    justify-content: space-between;
    bottom: $space-20;
    padding-right: $space-20;
    padding-left: $space-20;
  }

  .scroll-page-background-composition__button-wrapper {
    flex: 1;
    width: 100%;

    &:first-of-type {
      order: 1;
    }

    &:nth-of-type(2) {
      display: flex;
      justify-content: center;
      order: 3;

      @include md {
        order: 2;
      }
    }

    &:last-of-type {
      display: flex;
      order: 2;

      @include md {
        order: 3;
        justify-content: flex-end;
      }
    }
  }
  .scroll-page-background-composition__button {
    order: 1;
  }
}

.scroll-page-background-composition__pause-button,
.scroll-page-background-composition__scroll-down-button,
.scroll-page-background-composition__video-button-link {
  @extend .btn;
  pointer-events: auto;
}

.scroll-page-background-composition__pause-button,
.scroll-page-background-composition__scroll-down-button {
  background-color: transparent;
  color: var(--text-white);
  border: $space-px solid var(--bg-white);
  border-radius: var(--rounded-lg);

  &:hover {
    color: var(--bg-white-hover);
    border: $space-px solid var(--bg-white-hover);
  }

  &:focus {
    outline: $space-px solid var(--bg-white);
    outline-offset: $space-2;
  }

  &:disabled,
  &:disabled:hover {
    border-color: var(--accent-disabled-light);
    color: var(--accent-disabled-light);
  }
}

.scroll-page-background-composition__pause-button {
  width: 100%;

  @include md {
    display: none;
  }
}

.scroll-page-background-composition__pause-button_md {
  display: none;

  @include md {
    width: auto;
    display: flex;
  }
}

.scroll-page-background-composition__scroll-down-button {
  border-radius: var(--rounded);
  padding: 0;
}

.scroll-page-background-composition__scroll-down-button-icon {
  display: block;
}

.scroll-page-background-composition__video-button {
  display: block;
  width: 100%;

  @include md {
    display: none;
    width: auto;
  }
}

.scroll-page-background-composition__video-button_md {
  display: none;

  @include md {
    display: block;
  }
}

.scroll-page-background-composition__video-button-link {
  // color: var(--text-white);
  @extend .btn_primary;
}

.scroll-page-background-composition__background {
  position: absolute;
  bottom: 0;
  left: 50%;
  min-height: 166vh;
  width: 3777px;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full) var(--rounded-full) 0 0;

  @include md {
    min-height: 192vh;
  }
}

.scroll-page-background-composition__light-background {
  position: absolute;
  z-index: 10;
  left: 50%;
  translate: -50%, -50%;
  width: 3777px;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
}

.scroll-page-background-composition__background {
  background-color: var(--main-bg-dark);
  overflow: hidden;
}

.scroll-page-background-composition__light-background {
  background-color: var(--main-bg-light);
}
