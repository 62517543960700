/*------------------------------------*\
    # COMPONENTS - TABS
\*------------------------------------*/

.tabs-block {
  @include py($space-8);

  @include md {
    @include py($space-10);
  }

  @include lg {
    @include py($space-15);
  }

  &__title{
    color: var(--main-text-color-dark);
    margin-bottom: $space-3;
    
    @include md {
      margin-bottom: $space-4;
    }

    @include lg {
      margin-bottom: $space-5;
    }
  }

  &__subtitle {
    margin-bottom: $space-4;
    
    @include md {
      margin-bottom: $space-5;
    }

    @include lg {
      margin-bottom: $space-6;
    }
  }
}
