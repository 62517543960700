/*---------------------------------*\
    # COMPONENTS - CONTACT CARDS
\*---------------------------------*/

.contact-cards {
  display: flex;
  flex-direction: column;
  gap: $space-4;
  margin-top: $space-6;

  @include sm {
    gap: $space-5;
  }

  @include md {
    margin-top: $space-8;
  }

  @include lg {
    margin-top: $space-10;
    gap: $space-6;
  }
}

.contact-cards_two-columns {
  @include lg {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: $space-6;

    .contact-cards__item {
      .media-object {
        @include sm {
          flex-direction: column;
        }
      }

      .contact-cards__buttons {
        @include lg {
          flex-direction: column;
        }
      }

      .contact-cards__image-wrapper {
        @include sm {
          max-width: 100%;
        }

        @include md {
          max-width: 100%;
        }
      }

      .contact-cards__text {
        padding-top: 0;
      }
    }
  }
}

.contact-cards__item {
  padding: $space-4;
  background-color: var(--main-bg-light);

  @include md {
    padding: $space-4;
  }

  @include lg {
    padding: $space-8;
  }

  .media-object {
    flex-direction: column;
    align-items: stretch;
    gap: $space-5;

    @include sm {
      flex-direction: row;
      gap: $space-6;
    }

    @include lg {
      gap: $space-12;
    }
  }

  // Overwrite btn styling to not change variant with BlockVariant
  .btn_primary {
    background-color: var(--accent-bg-dark);
    color: var(--text-white);
    @include transition;

    &:hover {
      background-color: var(--accent-bg-dark-hover);
    }
  }

  .btn_secondary {
    background-color: transparent;
    color: var(--accent-text-color-dark);
    border: $space-px solid var(--accent-border-light);
    @include transition;

    &:hover {
      color: var(--accent-text-color-dark-hover);
      border-color: var(--accent-border-light-hover);
    }
  }
}

.contact-cards__image-wrapper {
  position: relative;
  aspect-ratio: $aspect-square;

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: none;
    width: 100%;
    height: $space-20;
    background-image: linear-gradient(
      to bottom,
      rgba(217, 217, 217, 0) 0,
      rgba(79, 79, 79, 0.51) 50%,
      rgba(0, 0, 0, 0.8) 100%
    );
    border-radius: 0 0 var(--rounded-lg) var(--rounded-lg);
  }

  @include sm {
    max-width: $space-34;
  }

  @include md {
    max-width: $space-49;
  }

  @include lg {
    &:after {
      display: block;
    }
  }
}

.contact-cards__image {
  width: 100%;
  height: 100%;
  border-radius: var(--rounded-lg);
  object-fit: cover;
}

.contact-cards__socials-container {
  position: absolute;
  bottom: $space-3;
  left: $space-3;
  z-index: 10;
  display: flex;
  gap: $space-3;

  @include sm {
    position: relative;
    bottom: initial;
    left: initial;
    justify-content: center;
  }

  @include lg {
    position: absolute;
    bottom: $space-3;
    left: $space-3;
    transform: translateX(0);
  }
}

.contact-cards__social-link {
  padding: $space-2;
  // Link was showing some extra space out of itself. With height and line-height like this, this issue has been resolved
  line-height: 0;
  height: 100%;
  @include transition;

  &:hover {
    color: var(--text-white-hover);
  }
}

.contact-cards__text {
  @include sm {
    padding-top: $space-6;
    @include px($space-5);
  }

  @include lg {
    padding-top: $space-5-5;
    @include px($space-6);
  }
}

.contact-cards__item-title {
  color: var(--main-text-color-dark);
  margin: 0;
  margin-bottom: $space-2;

  @include sm {
    margin-bottom: $space-3;
  }

  @include md {
    margin-bottom: $space-4;
  }
}

.contact-cards__item-subtitle {
  margin-top: $space-3;
}

.contact-cards__buttons {
  display: flex;
  flex-direction: column;
  gap: $space-2;
  margin-top: $space-6;

  @include sm {
    flex-direction: row;
  }
}

.contact-cards__social-link {
  color: var(--white);
  cursor: pointer;

  @include sm {
    color: var(--accent-text-color-dark);
  }

  @include lg {
    color: var(--white);
  }
}

.contact-cards__phone-number {
  display: inline-flex;
  gap: $space-1;
}

.contact-cards__social-icon,
.contact-cards__button-icon {
  height: $space-6;
  width: $space-6;
}
