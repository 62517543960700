/*----------------------------------------------*\
    # COMPONENTS - PARAGRAPH WITH IMAGE BLOCK
\*----------------------------------------------*/

.paragraph-with-image-block {
  @extend .page-block-wrapper;
  @include py(0);
}

.paragraph-with-image-block__container-default {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @include lg {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @include py($space-15);
  }

  .paragraph-with-image-block_image-right & {
    .paragraph-with-image-block__image-wrapper {
      @include lg {
        border-top-left-radius: var(--rounded-2xl);
        border-bottom-left-radius: var(--rounded-2xl);
        order: 9999;
      }
    }

    .paragraph-with-image-block__text {
      @include lg {
        justify-self: end;
        padding-left: $space-7;
        padding-right: $space-12;
      }
    }
  }

  .paragraph-with-image-block_image-left & {
    .paragraph-with-image-block__image-wrapper {
      @include lg {
        border-top-right-radius: var(--rounded-2xl);
        border-bottom-right-radius: var(--rounded-2xl);
      }
    }

    .paragraph-with-image-block__text {
      @include lg {
        justify-self: start;
        padding-left: $space-12;
        padding-right: $space-7;
      }
    }
  }
  
  .paragraph-with-image-block__text {
    @include lg {
      max-width: calc($max-width-7xl / 2);
    }
  }
}

.paragraph-with-image-block__container-inline {
  .paragraph-with-image-block__image-wrapper {
    max-width: 100%;
    min-height: initial;
    margin-bottom: $space-8;
    border-radius: var(--rounded-2xl);

    @include sm {
      margin-bottom: $space-6;
    }

    @include md {
      max-width: 50%;
      margin-bottom: $space-12;
    }

    .paragraph-with-image-block_image-right & {
      @include md {
        float: right;
        margin-left: $space-6;
      }
    }

    .paragraph-with-image-block_image-left & {
      @include md {
        float: left;
        margin-right: $space-6;
      }
    }
  }

  .rich-text__title,
  .rich-text__intro {
    @include my($space-3);

    @include sm {
      @include my($space-4);
    }

    @include lg {
      @include my($space-5);
    }
  }
}

.paragraph-with-image-block__text {
  position: relative;
  z-index: 10;
  padding: $space-8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: $space-8;


  @include md {
    @include px($space-11);
    @include py($space-10);
    gap: $space-10;
  }

  @include lg {
    @include py($space-15);
    padding-left: $space-26;
    padding-right: $space-12;
    width: 100%;
  }
}

.paragraph-with-image-block__text-intro {
  display: flex;
  flex-direction: column;
  @include space-y($space-3);

  @include sm {
    @include space-y($space-4);
  }

  @include lg {
    @include space-y($space-5);
  }
}

.paragraph-with-image-block__image-wrapper {
  width: 100vw;
  aspect-ratio: $aspect-video;
  overflow: hidden;
  order: -9999;

  @include lg {
    aspect-ratio: auto;
    width: 100%;
    min-height: $screen-sm;
  }
}

.paragraph-with-image-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
