/*------------------------------------*\
    # COMPONENTS - HERO LINK ITEM
\*------------------------------------*/

.hero-link__item {
  display: flex;
  align-items: center;
  gap: $space-4;
  color: currentColor;
  text-decoration: inherit;
}

.hero-link__item__text {
  flex-grow: 1;
}

.hero-link__item__icon {
  flex-shrink: 0;
  width: $space-4;
}
