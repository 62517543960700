///*------------------------------------*\
//    # SETTINGS - COLORS
//\*------------------------------------*/

@use 'sass:color';

$black: #000000;
$white: #ffffff;

$blue-1000: #2c729c;
$blue-900: mix($blue-1000, $white, 90%);
$blue-800: mix($blue-1000, $white, 80%);
$blue-700: mix($blue-1000, $white, 70%);
$blue-600: mix($blue-1000, $white, 60%);
$blue-500: mix($blue-1000, $white, 50%);
$blue-400: mix($blue-1000, $white, 40%);
$blue-300: mix($blue-1000, $white, 30%);
$blue-200: mix($blue-1000, $white, 20%);
$blue-100: mix($blue-1000, $white, 10%);
$blue-50: mix($blue-1000, $white, 5%);

$purple-1000: #3f4075;
$purple-900: mix($purple-1000, $white, 90%);
$purple-800: mix($purple-1000, $white, 80%);
$purple-700: mix($purple-1000, $white, 70%);
$purple-600: mix($purple-1000, $white, 60%);
$purple-500: mix($purple-1000, $white, 50%);
$purple-400: mix($purple-1000, $white, 40%);
$purple-300: mix($purple-1000, $white, 30%);
$purple-200: mix($purple-1000, $white, 20%);
$purple-100: mix($purple-1000, $white, 10%);
$purple-50: mix($purple-1000, $white, 5%);

$fuchsia-1000: #ba4576;
$fuchsia-900: mix($fuchsia-1000, $white, 90%);
$fuchsia-800: mix($fuchsia-1000, $white, 80%);
$fuchsia-700: mix($fuchsia-1000, $white, 70%);
$fuchsia-600: mix($fuchsia-1000, $white, 60%);
$fuchsia-500: mix($fuchsia-1000, $white, 50%);
$fuchsia-400: mix($fuchsia-1000, $white, 40%);
$fuchsia-300: mix($fuchsia-1000, $white, 30%);
$fuchsia-200: mix($fuchsia-1000, $white, 20%);
$fuchsia-100: mix($fuchsia-1000, $white, 10%);
$fuchsia-50: mix($fuchsia-1000, $white, 5%);

$light-green-1000: #787a00;
$light-green-900: mix($light-green-1000, $white, 90%);
$light-green-800: mix($light-green-1000, $white, 80%);
$light-green-700: mix($light-green-1000, $white, 70%);
$light-green-600: mix($light-green-1000, $white, 60%);
$light-green-500: mix($light-green-1000, $white, 50%);
$light-green-400: mix($light-green-1000, $white, 40%);
$light-green-300: mix($light-green-1000, $white, 30%);
$light-green-200: mix($light-green-1000, $white, 20%);
$light-green-100: mix($light-green-1000, $white, 10%);
$light-green-50: mix($light-green-1000, $white, 5%);

$gray-50: #f2f2f2;
$gray-100: #e6e6e6;
$gray-200: #cccccc;
$gray-300: #b3b3b3;
$gray-400: #999999;
$gray-500: #808080;
$gray-600: #666666;
$gray-700: #4d4d4d;
$gray-800: #333333;
$gray-900: #313131;

$light-blue-1000: #00c2df;

$orange-1000: #e57b03;
$yellow-1000: #e6ac00;

$gold-1000: #996917;
$gold-900: mix($gold-1000, $white, 90%);
$gold-800: mix($gold-1000, $white, 80%);
$gold-700: mix($gold-1000, $white, 70%);
$gold-600: mix($gold-1000, $white, 60%);
$gold-500: mix($gold-1000, $white, 50%);
$gold-400: mix($gold-1000, $white, 40%);
$gold-300: mix($gold-1000, $white, 30%);
$gold-200: mix($gold-1000, $white, 20%);
$gold-100: mix($gold-1000, $white, 10%);
$gold-50: mix($gold-1000, $white, 5%);

$green-1000: #3a693b;
$green-900: mix($green-1000, $white, 90%);
$green-800: mix($green-1000, $white, 80%);
$green-700: mix($green-1000, $white, 70%);
$green-600: mix($green-1000, $white, 60%);
$green-500: mix($green-1000, $white, 50%);
$green-400: mix($green-1000, $white, 40%);
$green-300: mix($green-1000, $white, 30%);
$green-200: mix($green-1000, $white, 20%);
$green-100: mix($green-1000, $white, 10%);
$green-50: mix($green-1000, $white, 5%);

$red-1000: #cc0000;

// hagelunie purple
$chambray-1000: #343991;
$chambray-900: mix($chambray-1000, $white, 90%);
$chambray-800: mix($chambray-1000, $white, 80%);
$chambray-700: mix($chambray-1000, $white, 70%);
$chambray-600: mix($chambray-1000, $white, 60%);
$chambray-500: mix($chambray-1000, $white, 50%);
$chambray-400: mix($chambray-1000, $white, 40%);
$chambray-300: mix($chambray-1000, $white, 30%);
$chambray-200: mix($chambray-1000, $white, 20%);
$chambray-100: mix($chambray-1000, $white, 10%);
$chambray-50: mix($chambray-1000, $white, 5%);

// hagelunie green
$verdun-green-1000: #4e7a00;
$verdun-green-900: mix($verdun-green-1000, $white, 90%);
$verdun-green-800: mix($verdun-green-1000, $white, 80%);
$verdun-green-700: mix($verdun-green-1000, $white, 70%);
$verdun-green-600: mix($verdun-green-1000, $white, 60%);
$verdun-green-500: mix($verdun-green-1000, $white, 50%);
$verdun-green-400: mix($verdun-green-1000, $white, 40%);
$verdun-green-300: mix($verdun-green-1000, $white, 30%);
$verdun-green-200: mix($verdun-green-1000, $white, 20%);
$verdun-green-100: mix($verdun-green-1000, $white, 10%);
$verdun-green-50: mix($verdun-green-1000, $white, 5%);

:root {
  --purple-50: #{$purple-50};
  --purple-100: #{$purple-100};
  --purple-200: #{$purple-200};
  --purple-300: #{$purple-300};
  --purple-400: #{$purple-400};
  --purple-500: #{$purple-500};
  --purple-600: #{$purple-600};
  --purple-700: #{$purple-700};
  --purple-800: #{$purple-800};
  --purple-900: #{$purple-900};
  --purple-1000: #{$purple-1000};

  --blue-50: #{$blue-50};
  --blue-100: #{$blue-100};
  --blue-200: #{$blue-200};
  --blue-300: #{$blue-300};
  --blue-400: #{$blue-400};
  --blue-500: #{$blue-500};
  --blue-600: #{$blue-600};
  --blue-700: #{$blue-700};
  --blue-800: #{$blue-800};
  --blue-900: #{$blue-900};
  --blue-1000: #{$blue-1000};

  --fuchsia-50: #{$fuchsia-50};
  --fuchsia-100: #{$fuchsia-100};
  --fuchsia-200: #{$fuchsia-200};
  --fuchsia-300: #{$fuchsia-300};
  --fuchsia-400: #{$fuchsia-400};
  --fuchsia-500: #{$fuchsia-500};
  --fuchsia-600: #{$fuchsia-600};
  --fuchsia-700: #{$fuchsia-700};
  --fuchsia-800: #{$fuchsia-800};
  --fuchsia-900: #{$fuchsia-900};
  --fuchsia-1000: #{$fuchsia-1000};

  --light-green-50: #{$light-green-50};
  --light-green-100: #{$light-green-100};
  --light-green-200: #{$light-green-200};
  --light-green-300: #{$light-green-300};
  --light-green-400: #{$light-green-400};
  --light-green-500: #{$light-green-500};
  --light-green-600: #{$light-green-600};
  --light-green-700: #{$light-green-700};
  --light-green-800: #{$light-green-800};
  --light-green-900: #{$light-green-900};
  --light-green-1000: #{$light-green-1000};

  --gray-50: #{$gray-50};
  --gray-100: #{$gray-100};
  --gray-200: #{$gray-200};
  --gray-300: #{$gray-300};
  --gray-400: #{$gray-400};
  --gray-500: #{$gray-500};
  --gray-600: #{$gray-600};
  --gray-700: #{$gray-700};
  --gray-800: #{$gray-800};
  --gray-900: #{$gray-900};

  --black: #{$black};
  --white: #{$white};

  --light-blue-1000: #{$light-blue-1000};

  --orange-1000: #{$orange-1000};
  --yellow-1000: #{$yellow-1000};

  --gold-50: #{$gold-50};
  --gold-100: #{$gold-100};
  --gold-200: #{$gold-200};
  --gold-300: #{$gold-300};
  --gold-400: #{$gold-400};
  --gold-500: #{$gold-500};
  --gold-600: #{$gold-600};
  --gold-700: #{$gold-700};
  --gold-800: #{$gold-800};
  --gold-900: #{$gold-900};
  --gold-1000: #{$gold-1000};

  --green-1000: #{$green-1000};
  --green-50: #{$green-50};
  --green-100: #{$green-100};
  --green-200: #{$green-200};
  --green-300: #{$green-300};
  --green-400: #{$green-400};
  --green-500: #{$green-500};
  --green-600: #{$green-600};
  --green-700: #{$green-700};
  --green-800: #{$green-800};
  --green-900: #{$green-900};
  
  --red-1000: #{$red-1000};

  --chambray-1000: #{$chambray-1000};
  --chambray-50: #{$chambray-50};
  --chambray-100: #{$chambray-100};
  --chambray-200: #{$chambray-200};
  --chambray-300: #{$chambray-300};
  --chambray-400: #{$chambray-400};
  --chambray-500: #{$chambray-500};
  --chambray-600: #{$chambray-600};
  --chambray-700: #{$chambray-700};
  --chambray-800: #{$chambray-800};
  --chambray-900: #{$chambray-900};

  --verdun-green-1000: #{$verdun-green-1000};
  --verdun-green-50: #{$verdun-green-50};
  --verdun-green-100: #{$verdun-green-100};
  --verdun-green-200: #{$verdun-green-200};
  --verdun-green-300: #{$verdun-green-300};
  --verdun-green-400: #{$verdun-green-400};
  --verdun-green-500: #{$verdun-green-500};
  --verdun-green-600: #{$verdun-green-600};
  --verdun-green-700: #{$verdun-green-700};
  --verdun-green-800: #{$verdun-green-800};
  --verdun-green-900: #{$verdun-green-900};
}
