/*------------------------------------*\
    # COMPONENTS - MEDIA OBJECT
\*------------------------------------*/

.media-object {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-3;
}

.media-object__media {
  flex: none;
}

.media-object__body {
  flex-grow: 1;
}
