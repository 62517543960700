/*------------------------------------*\
    # COMPONENTS - SEARCH RESULTS
\*------------------------------------*/

.search-results {
  @include py($space-5);

  &__list {
    @extend .container_tight;

    &-item {
      @include px($space-8);
      @include py($space-10);
    
      margin-bottom: $space-6;
      background-color: var(--accent-50);
      border-radius: var(--rounded-xl);

      &-title {
        @extend .heading-4;

        text-decoration: none;
        word-wrap: break-word;
      }

      &-excerpt {
        margin: $space-5 0;
      }

      &-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          color: var(--accent-text-color-dark);

          svg {
            margin-right: $space-2;
          }
        }

        a {
          text-decoration: none;
        }
      }
    }
  }

  &__filters {
    margin: $space-5 0;
  }

  &__query-summary {
    margin: $space-5 0;
  }
}
