/*------------------------------------*\
    # COMPONENTS - HERO WITH CAROUSEL
\*------------------------------------*/

.hero-with-carousel-block {
  overflow: hidden;
  position: relative;
  padding-bottom: $space-5;

  @include sm {
    padding-bottom: $space-7;
  }
  @include xl {
    padding-bottom: $space-10;
  }

  .decoration-first {
    aspect-ratio: 1/1;
    border-radius: var(--rounded-full);

    width: 877px;
    top: -34px;
    @include calculateOffsetCenter(-477px, 877px, 320px);

    @include sm {
      top: -478px;
      width: 1054px;
      @include calculateOffsetCenter(-255px, 1054px, $screen-sm);
    }

    @include md {
      top: -478px;
      width: 1054px;
      @include calculateOffsetCenter(-240px, 1054px, $screen-md);
    }

    @include xl {
      width: 1305px;
      top: -488px;
      @include calculateOffsetCenter(-172px, 1305px, $screen-xl);
    }

    @include twoXl {
      width: 1569px;
      top: -825px;
      @include calculateOffsetCenter(-244px, 1569px, $screen-2xl);
    }
  }
  .decoration-second {
    aspect-ratio: 1/1;
    border-radius: var(--rounded-full);

    width: 899px;
    top: -76px;
    @include calculateOffsetCenter(-465px, 899px, 320px);

    @include sm {
      width: 1012px;
      top: -478px;
      @include calculateOffsetCenter(-140px, 1012px, $screen-sm);
    }

    @include md {
      top: -478px;
      @include calculateOffsetCenter(-125px, 1013px, $screen-md);
    }

    @include xl {
      width: 1255px;
      top: -488px;
      @include calculateOffsetCenter(-29px, 1255px, $screen-xl);
    }

    @include twoXl {
      width: 1508px;
      top: -825px;
      @include calculateOffsetCenter(-72px, 1508px, $screen-2xl);
    }
  }

  .container_tightest {
    @include md {
      max-width: $max-width-4xl;
    }
  }

  .introtext {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }

    @include lg {
      margin-top: $space-5;
    }
  }

  .carousel {
    aspect-ratio: $aspect-wide;
  }
}

.hero-with-carousel-block_light {
  background: none;
  color: var(--main-text-color-dark);

  .decoration-first {
    border: $space-0-5 solid var(--main-200);

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
  }

  .decoration-second {
    border: $space-0-5 solid var(--main-400);

    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }
  }
}

.hero-with-carousel-block_dark {
  background: none;
  color: var(--text-white);

  .decoration-first {
    border: $space-0-5 solid var(--main-800);

    .theme_fuchsia_gold & {
      border-color: var(--main-200);
    }
  }

  .decoration-second {
    border: $space-0-5 solid var(--main-200);

    .theme_fuchsia_gold & {
      border-color: var(--main-800);
    }
  }

  .breadcrumbs__link,
  .breadcrumbs__back-button {
    color: var(--white);
  }
}

.hero-with-carousel-block:before {
  content: "";
  position: absolute;
  z-index: -1;

  width: 869px;
  bottom: 191px;
  @include calculateOffsetCenter(-328px, 869px, 320px);

  @include sm {
    width: 1365px;
    bottom: 138px;
    @include calculateOffsetCenter(-446px, 1365px, $screen-sm);
  }

  @include md {
    width: 1365px;
    bottom: 149px;
    @include calculateOffsetCenter(-431px, 1365px, $screen-md);
  }

  @include xl {
    width: 1576px;
    bottom: 413px;
    @include calculateOffsetCenter(-135px, 1576px, $screen-xl);
  }

  @include twoXl {
    width: calc(
      100vw + 128px + 87px
    ); // Entire screen width and padding left and right
    left: -128px;
    transform: translateX(0);
  }

  aspect-ratio: 1/1;
  border-radius: var(--rounded-full);
}

.hero-with-carousel-block_light:before {
  background-color: var(--main-bg-light);
}
.hero-with-carousel-block_dark:before {
  background-color: var(--main-bg-dark);
}

.hero-with-carousel-block_circles-none {
  &:before {
    border-radius: var(--rounded-none);
    bottom: 454px;
    transform: translateX(0);
    left: 0;
  }
}

.hero-with-carousel-block__carousel-container {
  border-radius: var(--rounded-xl);
  width: 100%;
}

.hero-with-carousel-block__text-container {
  margin-top: $space-6;

  @include md {
    margin-top: $space-8;
  }

  @include lg {
    margin-top: $space-10;
  }
}

.hero-with-carousel-block__carousel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: $space-80;
  aspect-ratio: unset;
  @include md {
    height: auto;
    aspect-ratio: $aspect-video;
  }
}

.hero-with-carousel-block__breadcrumbs {
  padding-top: $space-12;
}

.hero-with-carousel-block__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $space-4;
}
