/*------------------------------------*\
    # COMPONENTS - MODAL
\*------------------------------------*/

.modal {
  position: fixed;
  @include inset(0);
  z-index: 40;
  display: flex;
  padding: $space-6;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal_unstyled {
  padding: 0;
}

.modal__overlay {
  position: fixed;
  z-index: 50;
  @include inset(0);
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__content {
  z-index: 50;
  width: 100%;
  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}

.modal__card {
  padding: $space-8;
  width: 100%;
  background-color: var(--bg-white);
  max-width: $max-width-lg;
  @include mx(auto);
}
