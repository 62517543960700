/*------------------------------------*\
    # COMPONENTS - HASHTAG
\*------------------------------------*/

.hashtag {
  text-decoration: none;
  @extend %button-navigation;
  font-weight: 700 !important;
  color: var(--accent-text-color-dark);

  &:hover {
    color: var(--accent-text-color-dark-hover);
  }
}

.variant_dark {
  .hashtag {
    color: var(--text-white);

    &:hover {
      color: var(--text-white-hover);
    }
  }
}
