/*------------------------------------*\
    # COMPONENTS - TYPOGRAHPY
\*------------------------------------*/

.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
  margin: 0;
  letter-spacing: -0.01em;
  font-family: var(--font-sans-heading);
  @include font-weight("bold");
}

.heading-4,
.heading-5,
.heading-6 {
  font-family: var(--font-sans);
}

.heading-1_serif,
.heading-2_serif,
.heading-3_serif {
  margin: 0;
  letter-spacing: 0.01em;
  font-family: $font-serif;
  @include font-weight("regular");
}

.heading-1 {
  @include text-5xl;
  @include md {
    @include text-5-5xl;
  }
  @include lg {
    @include text-6-5xl;
  }
}

.heading-1_serif {
  @include text-4-75xl;
}

.heading-2 {
  @include text-3-5xl;
}

.heading-2_serif {
  @include text-4-5xl;
  line-height: $space-9;
}

.heading-3 {
  @include text-2xl;
}

.heading-3_serif {
  @include text-4xl;
}

.heading-4 {
  @include text-lg;
  line-height: $space-5-5;
  @include font-weight("semibold");
}

.heading-5 {
  @include text-base;
  line-height: $space-5;
  letter-spacing: 0.02em;
  @include font-weight("bold");
}

.heading-6 {
  @include text-base;
  line-height: $space-5;
  letter-spacing: 0;
  @include font-weight("medium");
}

.introtext {
  @include text-2xl;
  @include font-weight("medium");
  font-family: var(--font-sans-heading);
  line-height: $space-7;
}

.subtext {
  @include text-md;
  letter-spacing: 0.01em;
  font-family: var(--font-sans);
}

%button-navigation {
  @include my(0);
  @include font-weight("medium");
  @include text-xl;
  line-height: $space-5;
  font-family: var(--font-sans-heading);

  @include sm {
    @include text-2xl;
    line-height: $space-6;
  }
}

.bodytext {
  font-family: var(--font-sans);
  @include text-base;
  @include font-weight("regular");
  letter-spacing: 1%;
}
