/*------------------------------------*\
    # COMPONENTS - SUBMENU DRAGSLIDER
\*------------------------------------*/

.submenu-dragslider {
  font-family: var(--font-sans-heading);
  @include font-weight("bold");
  background-color: var(--accent-bg-dark);
  overflow-x: hidden;
}

.submenu-dragslider__slider {
  display: flex;
  flex-direction: row;
  width: max-content;
  @include mx(auto);
  gap: $space-7;

  .responsive-submenu__link {
    @include py($space-5);
    color: var(--accent-text-color-light);
    @include transition;

    &:hover {
      color: var(--text-white);
    }

    @include xl {
      @include py($space-6);
    }
  }

  .responsive-submenu__link_active {
    color: var(--text-white);
    padding-bottom: 22px;
    border-bottom: $space-0-5 solid var(--white);
  }

  @include sm {
    display: flex;
    @include px($space-12);
  }
}
