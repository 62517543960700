/*------------------------------------*\
    # GENERICS - FONTS
\*------------------------------------*/

.theme {
  --bg-white: var(--white);
  --bg-white-hover: rgba(255, 255, 255, 0.8);
  --text: var(--gray-800);
  --text-hover: var(--gray-600);
  --text-white: var(--white);
  --text-white-hover: rgba(255, 255, 255, 0.8);
  --text-light: var(--gray-600);
  --text-light-hover: var(--gray-400);
  --separator-light: var(--gray-100);
  --disabled: var(--gray-100);

  // Main

  --main-bg-light: var(--main-50);
  --main-bg-light-hover: var(--main-200);
  --main-bg-dark: var(--main-1000);
  --main-bg-dark-hover: var(--main-800);
  --main-text-color-dark: var(--main-1000);
  --main-text-color-dark-hover: var(--main-800);
  --main-text-color-light: var(--main-400);
  --main-text-color-light-hover: var(--main-200);
  --main-border-dark: var(--main-1000);
  --main-border-dark-hover: var(--main-800);
  --main-border-light: var(--main-500);
  --main-border-light-hover: var(--main-300);
  --main-separator-dark: var(--main-900);
  --main-disabled-light: var(--main-300);
  --main-disabled-dark: var(--main-700);

  // Accent

  --accent-bg-light: var(--accent-50);
  --accent-bg-light-hover: var(--accent-200);
  --accent-bg-dark: var(--accent-1000);
  --accent-bg-dark-hover: var(--accent-800);
  --accent-text-color-light: var(--accent-400);
  --accent-text-color-light-hover: var(--accent-200);
  --accent-text-color-dark: var(--accent-1000);
  --accent-text-color-dark-hover: var(--accent-800);
  --accent-border-light: var(--accent-500);
  --accent-border-light-hover: var(--accent-300);
  --accent-border-dark: var(--accent-1000);
  --accent-border-dark-hover: var(--accent-800);
  --accent-separator-dark: var(--accent-900);
  --accent-disabled-light: var(--accent-300);
  --accent-disabled-dark: var(--accent-700);

  // Rounded corners
  @extend .rounded_normal;

  // Fonts
  --font-sans: var(--font-roboto);
  --font-sans-heading: var(--font-blender);
}

.theme_blue {
  /* Blue theme with light green accent */
  --main-50: var(--blue-50);
  --main-100: var(--blue-100);
  --main-200: var(--blue-200);
  --main-300: var(--blue-300);
  --main-400: var(--blue-400);
  --main-500: var(--blue-500);
  --main-600: var(--blue-600);
  --main-700: var(--blue-700);
  --main-800: var(--blue-800);
  --main-900: var(--blue-900);
  --main-1000: var(--blue-1000);

  --accent-50: var(--light-green-50);
  --accent-100: var(--light-green-100);
  --accent-200: var(--light-green-200);
  --accent-300: var(--light-green-300);
  --accent-400: var(--light-green-400);
  --accent-500: var(--light-green-500);
  --accent-600: var(--light-green-600);
  --accent-700: var(--light-green-700);
  --accent-800: var(--light-green-800);
  --accent-900: var(--light-green-900);
  --accent-1000: var(--light-green-1000);
}

.theme_purple {
  /* Purple theme with light green accent */
  --main-50: var(--purple-50);
  --main-100: var(--purple-100);
  --main-200: var(--purple-200);
  --main-300: var(--purple-300);
  --main-400: var(--purple-400);
  --main-500: var(--purple-500);
  --main-600: var(--purple-600);
  --main-700: var(--purple-700);
  --main-800: var(--purple-800);
  --main-900: var(--purple-900);
  --main-900: var(--purple-900);
  --main-1000: var(--purple-1000);

  --accent-50: var(--light-green-50);
  --accent-100: var(--light-green-100);
  --accent-200: var(--light-green-200);
  --accent-300: var(--light-green-300);
  --accent-400: var(--light-green-400);
  --accent-500: var(--light-green-500);
  --accent-600: var(--light-green-600);
  --accent-700: var(--light-green-700);
  --accent-800: var(--light-green-800);
  --accent-900: var(--light-green-900);
  --accent-1000: var(--light-green-1000);
}

.theme_fuchsia {
  /* Fuchsia theme with light green accent */
  --main-50: var(--fuchsia-50);
  --main-100: var(--fuchsia-100);
  --main-200: var(--fuchsia-200);
  --main-300: var(--fuchsia-300);
  --main-400: var(--fuchsia-400);
  --main-500: var(--fuchsia-500);
  --main-600: var(--fuchsia-600);
  --main-700: var(--fuchsia-700);
  --main-800: var(--fuchsia-800);
  --main-900: var(--fuchsia-900);
  --main-1000: var(--fuchsia-1000);

  --accent-50: var(--light-green-50);
  --accent-100: var(--light-green-100);
  --accent-200: var(--light-green-200);
  --accent-300: var(--light-green-300);
  --accent-400: var(--light-green-400);
  --accent-500: var(--light-green-500);
  --accent-600: var(--light-green-600);
  --accent-700: var(--light-green-700);
  --accent-800: var(--light-green-800);
  --accent-900: var(--light-green-900);
  --accent-1000: var(--light-green-1000);
}

.theme_light-green {
  /* Light green theme with purple accent */
  --main-50: var(--light-green-50);
  --main-100: var(--light-green-100);
  --main-200: var(--light-green-200);
  --main-300: var(--light-green-300);
  --main-400: var(--light-green-400);
  --main-500: var(--light-green-500);
  --main-600: var(--light-green-600);
  --main-700: var(--light-green-700);
  --main-800: var(--light-green-800);
  --main-900: var(--light-green-900);
  --main-1000: var(--light-green-1000);

  --accent-50: var(--purple-50);
  --accent-100: var(--purple-100);
  --accent-200: var(--purple-200);
  --accent-300: var(--purple-300);
  --accent-400: var(--purple-400);
  --accent-500: var(--purple-500);
  --accent-600: var(--purple-600);
  --accent-700: var(--purple-700);
  --accent-800: var(--purple-800);
  --accent-900: var(--purple-900);
  --accent-1000: var(--purple-1000);
}

.theme_fuchsia_gold {
  /* Fuchsia & Gold three color theme */
  --main-50: var(--fuchsia-50);
  --main-100: var(--fuchsia-100);
  --main-200: var(--fuchsia-200);
  --main-300: var(--fuchsia-300);
  --main-400: var(--fuchsia-400);
  --main-500: var(--fuchsia-500);
  --main-600: var(--fuchsia-600);
  --main-700: var(--fuchsia-700);
  --main-800: var(--fuchsia-800);
  --main-900: var(--fuchsia-900);
  --main-1000: var(--fuchsia-1000);

  --accent-50: var(--gold-50);
  --accent-100: var(--gold-100);
  --accent-200: var(--gold-200);
  --accent-300: var(--gold-300);
  --accent-400: var(--gold-400);
  --accent-500: var(--gold-500);
  --accent-600: var(--gold-600);
  --accent-700: var(--gold-700);
  --accent-800: var(--gold-800);
  --accent-900: var(--gold-900);
  --accent-1000: var(--gold-1000);
}

.theme_blue_gold {
  /* Blue theme with gold accent */
  --main-50: var(--blue-50);
  --main-100: var(--blue-100);
  --main-200: var(--blue-200);
  --main-300: var(--blue-300);
  --main-400: var(--blue-400);
  --main-500: var(--blue-500);
  --main-600: var(--blue-600);
  --main-700: var(--blue-700);
  --main-800: var(--blue-800);
  --main-900: var(--blue-900);
  --main-1000: var(--blue-1000);

  --accent-50: var(--gold-50);
  --accent-100: var(--gold-100);
  --accent-200: var(--gold-200);
  --accent-300: var(--gold-300);
  --accent-400: var(--gold-400);
  --accent-500: var(--gold-500);
  --accent-600: var(--gold-600);
  --accent-700: var(--gold-700);
  --accent-800: var(--gold-800);
  --accent-900: var(--gold-900);
  --accent-1000: var(--gold-1000);
}

.theme_purple_gold {
  /* Purple theme with gold accent */
  --main-50: var(--purple-50);
  --main-100: var(--purple-100);
  --main-200: var(--purple-200);
  --main-300: var(--purple-300);
  --main-400: var(--purple-400);
  --main-500: var(--purple-500);
  --main-600: var(--purple-600);
  --main-700: var(--purple-700);
  --main-800: var(--purple-800);
  --main-900: var(--purple-900);
  --main-900: var(--purple-900);
  --main-1000: var(--purple-1000);

  --accent-50: var(--gold-50);
  --accent-100: var(--gold-100);
  --accent-200: var(--gold-200);
  --accent-300: var(--gold-300);
  --accent-400: var(--gold-400);
  --accent-500: var(--gold-500);
  --accent-600: var(--gold-600);
  --accent-700: var(--gold-700);
  --accent-800: var(--gold-800);
  --accent-900: var(--gold-900);
  --accent-1000: var(--gold-1000);
}

.theme_gold_dark-green {
  /* Gold theme with dark green accent */
  --main-50: var(--gold-50);
  --main-100: var(--gold-100);
  --main-200: var(--gold-200);
  --main-300: var(--gold-300);
  --main-400: var(--gold-400);
  --main-500: var(--gold-500);
  --main-600: var(--gold-600);
  --main-700: var(--gold-700);
  --main-800: var(--gold-800);
  --main-900: var(--gold-900);
  --main-1000: var(--gold-1000);

  --accent-50: var(--green-50);
  --accent-100: var(--green-100);
  --accent-200: var(--green-200);
  --accent-300: var(--green-300);
  --accent-400: var(--green-400);
  --accent-500: var(--green-500);
  --accent-600: var(--green-600);
  --accent-700: var(--green-700);
  --accent-800: var(--green-800);
  --accent-900: var(--green-900);
  --accent-1000: var(--green-1000);
}

.theme_blue_dark-green {
  /* Blue theme with dark green accent */
  --main-50: var(--blue-50);
  --main-100: var(--blue-100);
  --main-200: var(--blue-200);
  --main-300: var(--blue-300);
  --main-400: var(--blue-400);
  --main-500: var(--blue-500);
  --main-600: var(--blue-600);
  --main-700: var(--blue-700);
  --main-800: var(--blue-800);
  --main-900: var(--blue-900);
  --main-1000: var(--blue-1000);

  --accent-50: var(--green-50);
  --accent-100: var(--green-100);
  --accent-200: var(--green-200);
  --accent-300: var(--green-300);
  --accent-400: var(--green-400);
  --accent-500: var(--green-500);
  --accent-600: var(--green-600);
  --accent-700: var(--green-700);
  --accent-800: var(--green-800);
  --accent-900: var(--green-900);
  --accent-1000: var(--green-1000);
}

.theme_chambray {
  /* Hagelunie purple theme */
  --main-50: var(--chambray-50);
  --main-100: var(--chambray-100);
  --main-200: var(--chambray-200);
  --main-300: var(--chambray-300);
  --main-400: var(--chambray-400);
  --main-500: var(--chambray-500);
  --main-600: var(--chambray-600);
  --main-700: var(--chambray-700);
  --main-800: var(--chambray-800);
  --main-900: var(--chambray-900);
  --main-1000: var(--chambray-1000);

  --accent-50: var(--chambray-50);
  --accent-100: var(--chambray-100);
  --accent-200: var(--chambray-200);
  --accent-300: var(--chambray-300);
  --accent-400: var(--chambray-400);
  --accent-500: var(--chambray-500);
  --accent-600: var(--chambray-600);
  --accent-700: var(--chambray-700);
  --accent-800: var(--chambray-800);
  --accent-900: var(--chambray-900);
  --accent-1000: var(--chambray-1000);

  @extend .rounded_none;

  // Fonts
  --font-sans: var(--font-campton-body);
  --font-sans-heading: var(--font-campton-heading);
}

.theme_chambray_verdun-green {
  /* Hagelunie purple theme with green accent */
  --main-50: var(--chambray-50);
  --main-100: var(--chambray-100);
  --main-200: var(--chambray-200);
  --main-300: var(--chambray-300);
  --main-400: var(--chambray-400);
  --main-500: var(--chambray-500);
  --main-600: var(--chambray-600);
  --main-700: var(--chambray-700);
  --main-800: var(--chambray-800);
  --main-900: var(--chambray-900);
  --main-1000: var(--chambray-1000);

  --accent-50: var(--verdun-green-50);
  --accent-100: var(--verdun-green-100);
  --accent-200: var(--verdun-green-200);
  --accent-300: var(--verdun-green-300);
  --accent-400: var(--verdun-green-400);
  --accent-500: var(--verdun-green-500);
  --accent-600: var(--verdun-green-600);
  --accent-700: var(--verdun-green-700);
  --accent-800: var(--verdun-green-800);
  --accent-900: var(--verdun-green-900);
  --accent-1000: var(--verdun-green-1000);

  @extend .rounded_none;
  
  // Fonts
  --font-sans: var(--font-campton-body);
  --font-sans-heading: var(--font-campton-heading);
}
