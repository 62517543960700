/*---------------------------------------*\
    # COMPONENTS - CALL TO ACTION BLOCK
\*---------------------------------------*/

.call-to-action-block {
  .decoration-first {
    width: 1245px;
    top: -$space-1-5;
    @include calculateOffsetCenter(-576px, 1245px, 320px);

    @include sm {
      top: -$space-14;
      @include calculateOffsetCenter(-598px, 1245px, $screen-sm);
    }

    @include md {
      top: -$space-10;
      @include calculateOffsetCenter(-520px, 1245px, $screen-md);
    }

    @include xl {
      top: -536px;
      @include calculateOffsetCenter(77px, 1245px, $screen-xl);
    }

    @include twoXl {
      @include calculateOffsetCenter(147px, 1245px, $screen-2xl);
    }
  }

  .decoration-second {
    width: 1430px;
    top: -$space-14;
    @include calculateOffsetCenter(-731px, 1430px, 320px);

    @include sm {
      top: -$space-56;
      @include calculateOffsetCenter(-772px, 1430px, $screen-sm);
    }

    @include md {
      top: -$space-64;
      @include calculateOffsetCenter(-684px, 1430px, $screen-md);
    }

    @include xl {
      top: -697px;
      @include calculateOffsetCenter(-$space-5, 1430px, $screen-xl);
    }

    @include twoXl {
      @include calculateOffsetCenter(52px, 1430px, $screen-2xl);
    }
  }
}
.call-to-action-block_circles-none {
  .call-to-action-block__image-round-decoration-background,
  .call-to-action-block__image-round-decoration-foreground {
    display: none;
  }
}

.call-to-action-block__container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include lg {
    flex-direction: row;
  }

  &.image-round {
    gap: $space-10;

    @include md {
      gap: $space-12;
    }

    @include lg {
      gap: $space-14;
    }
  }

  &.image-square {
    gap: $space-6;

    @include md {
      gap: $space-8;
    }

    @include lg {
      gap: $space-10;
    }
  }
}

.call-to-action-block__image-rectangular-wrapper {
  overflow: hidden;
  aspect-ratio: $aspect-regular;
  width: 100%;
  @include sm {
    max-width: 340px;
  }
  @include lg {
    width: 288px;
  }
  border-radius: var(--rounded-lg);
}

.call-to-action-block__text {
  @include space-y($space-5);
  width: 100%;

  @include md {
    @include space-y($space-6);
  }

  @include lg {
    @include space-y($space-8);
  }
}

.call-to-action-block__text-container {
  @include space-y($space-3);

  @include md {
    @include space-y($space-4);
  }

  @include sm {
    @include space-y($space-5);
  }
}

.call-to-action-block__image-container {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
}

.call-to-action-block__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin imageDimensions {
  width: 100%;
  @include sm {
    max-width: 308px;
  }
  @include lg {
    width: 253px;
  }
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
}

$horizontal-offset: 18px;
$vertical-offset: 18px;

.call-to-action-block__image-round-wrapper {
  overflow: hidden;
  @include imageDimensions;
  flex-shrink: none;
}
.call-to-action-block__image-round-decoration-background {
  @include imageDimensions;
  position: absolute;
  top: $vertical-offset;
  right: -$horizontal-offset;
}
.call-to-action-block__image-round-decoration-foreground {
  @include imageDimensions;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.call-to-action-block__image-round-decoration-foreground::after {
  content: "";
  width: 100%;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);
  position: absolute;
  top: $vertical-offset;
  right: -$horizontal-offset;
}
.variant_light,
.variant_white {
  .call-to-action-block__image-round-decoration-background {
    border: $space-px solid var(--main-border-dark);
  }
  .call-to-action-block__image-round-decoration-foreground::after {
    border: $space-px solid var(--white);
  }
  .call-to-action-block__title {
    color: var(--main-1000);
  }
}
.variant_dark {
  .call-to-action-block__image-round-decoration-background {
    border: $space-px solid var(--main-100);
  }
  .call-to-action-block__image-round-decoration-foreground::after {
    border: $space-px solid var(--main-100);
  }
  .call-to-action-block__title {
    color: var(--white);
  }
}

.lg\:order-last {
  .call-to-action-block__image-round-decoration-background,
  .call-to-action-block__image-round-decoration-foreground::after {
    @include lg {
      left: $horizontal-offset;
    }
  }
}
