/*
  Prevents Experience Editor flickering when trying to add new components on the bottom of the page.
*/
body.pagemode-editing {
  overflow-x: hidden;
}

/*
  Hides Sitecore Experience Editor markup, if you run the app in connected mode while the Sitecore cookies are set to edit mode.
*/
.scChromeData, .scpm { 
  display: none !important; 
}

/*
  Add border around placeholders and editable fields
*/
.scEnabledChrome {
  border: 2px solid #e7e7e7;
  padding: 1px;
}

/*
  Placeholder styling fixes for flex related content
*/
.quicklinks .scEmptyPlaceholder {
  width: 100%;
}

.carousel-block__sitecore-ee-wrapper .scEmptyPlaceholder {
  width: 100%;
}

.btn-container .sc-jss-empty-placeholder,
.btn-container .scEmptyPlaceholder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

/*
  Fix cards EnabledChrome position in Experience Editor
*/
.card-slider-block__sitecore-ee-wrapper .contents {
  display: block;
}
