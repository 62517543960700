/*------------------------------------*\
    # COMPONENTS - PAGE LAYOUT
\*------------------------------------*/

.page-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.page-layout__header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 30;
  flex: none;
}

.page-layout__header_fixed {
  position: fixed;
  width: 100%;
}

.page-layout__main {
  flex-grow: 1;
}

.page-layout__footer {
  flex: none;
  border-top: $space-0-5 solid var(--main-800);
}
