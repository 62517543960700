/*------------------------------------*\
    # UTILITIES - ANIMATIONS
\*------------------------------------*/
/*
 * SPIN
 */

.animation-spin {
  transform-origin: center;
  animation: spin-container 2s linear infinite;

  circle {
    stroke-linecap: round;
    animation: spin-inner 1.5s ease-in-out infinite
  }
}

@keyframes spin-container {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin-inner {
  0% {
    stroke-dasharray: 0 150;
    stroke-dashoffset: 0;
  }

  47.5% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -16;
  }

  95%, 100% {
    stroke-dasharray: 42 150;
    stroke-dashoffset: -59;
  }
}
