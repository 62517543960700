///*------------------------------------*\
//    # SETTINGS - ASPECT RATIOS
//\*------------------------------------*/

@use "sass:math";

$aspect-wide: math.div(21, 9);
$aspect-video: math.div(16, 9);
$aspect-regular: math.div(4, 3);
$aspect-square: math.div(1, 1);
