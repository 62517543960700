/*------------------------------------*\
    # COMPONENTS - VIDEO PLAYER
\*------------------------------------*/

.video-player {
  overflow: hidden;

  &:hover {
    .video-player__button {
      color: var(--accent-bg-dark-hover);
    }
  }
}

.video-player__button {
  padding: $space-5;
  border-radius: var(--rounded-full);
  background-color: var(--bg-white);
  color: var(--accent-bg-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition;
}
