/*-----------------------------------------*\
    # COMPONENTS - LARGE CARD SLIDER BLOCK
\*-----------------------------------------*/

// Links to max-width that is used in the components container class
$max-width-reel: $max-width-7xl;

.large-card-slider-block {
}

.variant_dark.large-card-slider-block {
  @container (min-width: #{$max-width-xl}) {
    .image-card__title,
    .image-card__icon {
      color: var(--text-white);
    }

    .image-card {
      &:hover {
        .image-card__title,
        .image-card__icon {
          color: var(--text-white);
        }
      }
    }
  }
}

.large-card-slider-block__title {
  color: var(--textColor);
}

.large-card-slider-block__text {
  margin-bottom: $space-8;
  @include sm {
    margin-bottom: $space-10;
  }

  @include xl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $space-14;
  }
}

.large-card-slider-block__reel {
  @include mx(-$space-6);
  @include px($space-6);

  @include sm {
    @include mx(-$space-8);
    @include px($space-8);
  }

  @include xl {
    @include mx(calc(-1 * (100vw - $max-width-reel + $space-16) / 2));
    @include px(calc((100vw - $max-width-reel + $space-16) / 2));
  }
}

.large-card-slider-block__card {
  width: calc(100vw - $space-12);
  aspect-ratio: 1/1;

  @include sm {
    width: calc(100vw - $space-16);
    aspect-ratio: 16/9;
  }

  @include xl {
    width: calc(100vw - (100vw - $max-width-reel + $space-16));
  }
}

.large-card-slider-block__sitecore-ee-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $space-4;
}
