/*------------------------------------*\
    # COMPONENTS - HERO WITH EMBED
\*------------------------------------*/

.hero-with-embed-block {
  overflow: hidden;
  position: relative;
  padding-bottom: $space-5;

  @include md {
    padding-bottom: $space-7;
  }
  @include xl {
    padding-bottom: $space-10;
  }

  .container_tight {
    @include md {
      max-width: $max-width-2xl;
      @include px($space-8);
      @include mx-auto;
    }

    @include xl {
      max-width: $max-width-3xl;
    }
  }

  .decoration-first {
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);

    position: absolute;
    z-index: -1;

    width: 899px;
    top: -76px;
    @include calculateOffsetCenter(-465px, 899px, 320px);

    @include sm {
      width: 1013px;
      top: -478px;
      @include calculateOffsetCenter(-139px, 1013px, $screen-sm);
    }

    @include md {
      @include calculateOffsetCenter(-124px, 1013px, $screen-md);
    }

    @include xl {
      width: 1305px;
      top: -488px;
      @include calculateOffsetCenter(-172px, 1305px, $screen-xl);
    }

    @include twoXl {
      width: 1569px;
      top: -825px;
      @include calculateOffsetCenter(-244px, 1569px, $screen-2xl);
    }
  }

  .decoration-second {
    aspect-ratio: $aspect-square;
    border-radius: var(--rounded-full);

    position: absolute;
    z-index: -1;

    width: 877px;
    top: -34px;
    @include calculateOffsetCenter(-477px, 877px, 320px);

    @include sm {
      width: 1054px;
      top: -478px;
      @include calculateOffsetCenter(-255px, 1054px, $screen-sm);
    }

    @include md {
      @include calculateOffsetCenter(-240px, 1054px, $screen-md);
    }

    @include xl {
      width: 1255px;
      top: -488px;
      @include calculateOffsetCenter(-29px, 1255px, $screen-xl);
    }

    @include twoXl {
      width: 1508px;
      top: -825px;
      @include calculateOffsetCenter(-72px, 1508px, $screen-2xl);
    }
  }
}

.hero-with-embed-block_light {
  color: var(--main-text-color-dark);
  .decoration-first {
    border: $space-0-5 solid var(--main-200);
  }
  .decoration-second {
    border: $space-0-5 solid var(--main-400);
  }
}

.hero-with-embed-block_dark {
  color: var(--text-white);
  .decoration-first {
    border: $space-0-5 solid var(--main-800);
  }
  .decoration-second {
    border: $space-0-5 solid var(--main-200);
  }
  .breadcrumbs__link,
  .breadcrumbs__back-button {
    color: var(--white);
  }
}

.hero-with-embed-block:before {
  content: "";
  position: absolute;
  z-index: -1;
  aspect-ratio: $aspect-square;
  border-radius: var(--rounded-full);

  width: 869px;
  bottom: $space-9;
  @include calculateOffsetCenter(-328px, 869px, 320px);

  @include sm {
    width: 1365px;
    bottom: $space-12;
    @include calculateOffsetCenter(-446px, 1365px, $screen-sm);
  }

  @include md {
    width: 1365px;
    bottom: $space-16;
    @include calculateOffsetCenter(-431px, 1365px, $screen-md);
  }

  @include xl {
    width: 1576px;
    bottom: $space-80;
    @include calculateOffsetCenter(-135px, 1576px, $screen-xl);
  }

  @include twoXl {
    width: calc(100% + 128px + 87px);
    left: -128px;
    transform: translateX(0);
  }
}

.hero-with-embed-block_light:before {
  background-color: var(--main-bg-light);
}
.hero-with-embed-block_dark:before {
  background-color: var(--main-bg-dark);
}
  
.hero-with-embed-block_circles-none {
  &:before {
    border-radius: var(--rounded-none);
    bottom: $space-28;
    transform: translateX(0);
    left: 0;

    @include sm {
      bottom: $space-40;
    }

    @include md {
      bottom: $space-56;
    }

    @include xl {
      bottom: $space-80;
    }

    @include twoXl {
      bottom: $space-96;
    }
  }
}
  
.hero-with-embed-block__breadcrumbs {
  padding-top: $space-12;
  padding-left: $space-6;

  @include sm {
    padding-left: $space-8;
  }

  @include xl {
    padding-left: $space-16;
  }
}
  