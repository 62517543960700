/*------------------------------------*\
    # COMPONENTS - QUICKLINKS
\*------------------------------------*/

.quicklinks {
  border: 1px solid var(--separator-light);
  background-color: var(--bg-white);
  color: var(--text);
  border-radius: var(--rounded-xl);
  @include py($space-2);
  @include px($space-6);
  margin-top: $space-4;

  @include sm {
    @include py($space-7);
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  @include md {
    margin-top: $space-5;
  }

  @include lg {
    margin-top: $space-6;
  }

  & > * {
    border-top: 1px solid var(--separator-light);

    &:first-child {
      border-top: none;
      border-left: none;
    }

    @include sm {
      border-top: none;
      border-left: 1px solid var(--separator-light);
    }
  }

  &.items-per-row-4 > * {
    @include sm {
      width: 50%;
      &:nth-child(2n + 1) {
        border-left: none;
      }
    }

    @include lg {
      width: 25%;
      &:nth-child(2n + 1) {
        border-left: 1px solid var(--separator-light);
      }
      &:nth-child(4n + 1) {
        border-left: none;
      }
    }
  }

  &.items-per-row-6 > * {
    @include sm {
      width: 33%;
      &:nth-child(3n + 1) {
        border-left: none;
      }
    }

    @include lg {
      width: 16.5%;
      &:nth-child(3n + 1) {
        border-left: 1px solid var(--separator-light);
      }
      &:nth-child(6n + 1) {
        border-left: none;
      }
    }
  }
}

.quicklinks__item {
  flex: 1 1 auto;
  display: flex;
  align-items: flex-start;

  @include sm {
    @include px($space-4);
    @include py(0);
    height: $space-40;
  }
}

.quicklinks__item__link {
  display: flex;
  align-items: center;
  gap: $space-2;
  color: inherit;
  text-decoration: none;
  width: 100%;

  @include sm {
    flex-direction: column;
    max-width: $max-width-md;
    @include mx(auto);
  }
}
.quicklinks__item__image-wrapper {
  aspect-ratio: $aspect-square;

  &.size-icon {
    width: $space-16;

    @include sm {
      width: $space-18;
    }
  }

  &.size-logo {
    width: $space-24;

    @include sm {
      width: $space-26;
    }
  }
}
.quicklinks__item__image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.quicklinks__item__text {
  flex: 1;
  margin-right: $space-3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  @include text-lg;
  @include font-weight(medium);
  line-height: 1;
  font-family: var(--font-sans-heading);

  @include sm {
    @include text-xl;
    max-width: $space-38;
    margin-top: -$space-2;
    margin-right: 0;
    text-align: center;
  }
}
