/*------------------------------------*\
    # COMPONENTS - SEARCH PAGINATION
\*------------------------------------*/

.search-pagination {
  &__wrapper {
    @extend .container_tight;
  }

  &__list {
    @extend .btn-container;
    @include py($space-3);
    @include px($space-5);
    justify-content: center;
    
    &-item {
      @extend .btn;

      &.first,
      &.last {
        @extend .btn_primary;
      }

      &.num {
        @extend .btn_secondary;
      }
    }
  }
}
