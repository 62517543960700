/*------------------------------------*\
    # OBJECTS - POSITION
\*------------------------------------*/

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}
