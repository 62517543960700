/*------------------------------------*\
    # COMPONENTS - VIDEO
\*------------------------------------*/

.video-block {
  .heading-2 {
    color: var(--main-text-color-dark);
  }

  &__subtitle {
    margin-top: $space-3;

    @include md {
      margin-top: $space-4;
    }
    
    @include lg {
      margin-top: $space-5;
    }
  }
}

.video-block__video-wrapper {
  margin-top: $space-6;

  @include md {
    margin-top: $space-8;
  }

  @include lg {
    margin-top: $space-10;
  }
}
